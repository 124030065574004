import React, { useState } from 'react';
import '../css/MilestonesTimeline.css';


const AuData = [
    {
        date: "October 10, 1959",
        description: "Pandit Jawaharlal Nehru laid foundation stone for the College, the first in the chain of NITs (formerly RECs) in the country."
    },
    {
        date: "November 10, 1959",
        description: "Registration of the Society of Regional Engineering College, Warangal."
    },
    {
        date: "September, 2002",
        description: "Institute renamed as National Institute of Technology, Warangal and offered the status of Deemed University."
    },
    {
        date: "March 1, 2004",
        description: "Ist Convocation of NIT Warangal. Prof. S.K. Joshi, Chairman, Board of Governors, IIT Roorkee and Former Director General, CSIR delivers the First Convocation address."
    },
    {
        date: "Sep 10, 2018",
        description: "Diamond Jubilee Celebrations ( Conferences And Other Events )."
    },
    // Add your other milestones here
];



const milestonesData = [
    {
        date: "October 10, 1959",
        description: "Pandit Jawaharlal Nehru laid foundation stone for the College, the first in the chain of NITs (formerly RECs) in the country."
    },

    {
        date: "November 10, 1959",
        description: "Registration of the Society of Regional Engineering College, Warangal."
    },
    {
        date: "August, 1964",
        description: "Commencement of UNESCO assistance project."
    },

    {
        date: "October, 1972",
        description: "Change of affiliation of the College from Osmania University to Jawaharlal Nehru Technological University. Academic autonomy conferred on the College."
    },

    {
        date: "August 19, 1976",
        description: "Change of affiliation of the College from JNTU to Kakatiya University."
    },

    {
        date: "August, 1981",
        description: "Admission for B.Tech. through Common Entrance Examination for students from Andhra Pradesh."
    },


    {
        date: "June, 1987",
        description: "	Establishment of Entrepreneurship Development Cell."
    },


    {
        date: "January, 1990",
        description: "The College is selected for assistance under UK-INDIA Project."
    },

    {
        date: "April, 1994",
        description: "Commencement of UK - INDIA RECs Project."
    },

    {
        date: "January, 1995",
        description: "Campus LAN installed. E-Mail facility provided by dial up connectivity through Central University, Hyderabad."
    },

    {
        date: "October, 1996",
        description: "Commissioning of a dedicated Internet connectivity through VSAT dish. Browsing and E-mail facility provided."
    },

    {
        date: "November, 1996",
        description: "The AICTE recommends to the M.H.R.D and U.G.C the award of the Deemed to be University Status to REC Warangal."
    },
    {
        date: "July 20, 2001",
        description: "AICTE grants the status of QIP (Quality Improvement Programme) Centre to the following departments:(i)Ph.D. Programme – Departments of Mechanical Engineering, Chemical Engineering and Mathematics(ii)M.Tech. Programme – Departments of Electrical Engineering and the Department of Electronics and Communication Engineering.  "
    },


    {
        date: "September, 2001",
        description: "Inauguration of MSIT course in A.P. by the Hon’ble Chief Minister of Andhra Pradesh at Hyderabad with a Learning Centre at Rec, Warangal."
    },

    {
        date: "July, 2002",
        description: "Board of Governors resolved to approve the new Memorandum Of Association (MOA) for conversion of Regional Engineering College, Warangal as National Institute of Technology (NIT), Warangal."
    },

    {
        date: "September, 2002",
        description: "Institute renamed as National Institute of Technology, Warangal and offered the status of Deemed University."
    },

    {
        date: "July, 2003",
        description: "Commencement of admission of students for B.Tech through AIEEE stream."
    },

    {
        date: "August 4, 2003",
        description: "Prof. Deba Kumar Tripathy takes charge as the first Director of NIT Warangal."
    },

    {
        date: "August, 2003",
        description: "Change over to IIT Pattern in Curriculum: Revision of syllabi and rules of regulations for the UG and PG courses and introduction of semester system for B.Tech and MBA at the first year level. Change over of evaluation pattern to Credits and GPA system."
    },

    {
        date: "June, 2004",
        description: "The Institute receives an assistance of Rs. 11.41 crores from the world bank under TEQIP (Technical Education Quality Improvement Programme)."
    },

    {
        date: "March 1, 2004",
        description: "Ist Convocation of NIT Warangal. Prof. S.K. Joshi, Chairman, Board of Governors, IIT Roorkee and Former Director General, CSIR delivers the First Convocation address."
    },


    {
        date: "January 22, 2005",
        description: "2nd Convocation of NIT Warangal. Dr. Anil Kakodkar, Chairman, Atomic Energy Commission and Secretary, Department of Atomic Energy, Government of India delivers the Second Convocation address."
    },


    {
        date: "December 7, 2006",
        description: "NIT, Warangal entered into MoU with National Academy of Construction (NAC), Hyderabad with regard to implementation of academic continuing education and training programmes in construction engineering of mutual interest and benefit."
    },


    {
        date: "January 3, 2007",
        description: "Inauguration of Centre for Rural Development building by Prof. Y.V.Rao, Director, NITW"
    },


    {
        date: "January 15, 2007",
        description: "International Kalam Guest House was inaugurated by Prof. K.Koteswara Rao, former Principal, RECW"
    },

    {
        date: "January 18, 2007",
        description: "Foundation stone laid for Centre for Automation & Instrumentation by Prof. R.A. Yadav, Vice-Chairman, AICTE, New Delhi"
    },


    {
        date: "August 24, 2007",
        description: "Inauguration to initiate concrete activity for the construction of Mega Hostel."
    },

    {
        date: "January 25, 2008",
        description: "Dr. Abdul Kalam, Former President visits Campus during TECHNOZION Honourable Minister Purandeswari, MHRD inaugurates New Computer Centre and lays foundation stone for ultra Mega Hostel."
    },


    {
        date: "August 18, 2008",
        description: "Halls of Residence 1600 student’s capacity (Ultra-Mega Hostel) Bhoomipuja."
    },

    {
        date: "October 2, 2008",
        description: "Inauguration of Renovated Administrative Block with the State of the Art facilities and MIS."
    },

    {
        date: "December 12 - 14, 2008",
        description: "Inauguration of Renovated Administrative Block with the State of the Art facilities and MIS."
    },


    {
        date: "January 21, 2009",
        description: "Bhoomi Puja:- 1) Girls Hostel (350 capacity) – by Shri Ashok Thakur, Addl. Secretary, MHRD, New Delhi 2) Campus Landscaping."
    },


    {
        date: "March 3, 2009",
        description: "The MHRD, New Delhi constituted CCB-2009 with Director, NIT, Warangal as its Chairman and NIT, Warangal as its Headquarters."
    },

    {
        date: "June 13, 2009",
        description: "NIT, Warangal has been selected as a “Leading Technology Adopter of the year 2009” (It is an award for excellence in Technology Adopter in the Education Sector)."
    },


    {
        date: "June 22, 2009",
        description: "Inauguration of INSPIRE programme by Dr. N.R. Shetty, Former Vice-Chancellor, Bangalore University."
    },


    {
        date: "December 28, 2009",
        description: "Inauguration of Dr. V.A. Sastry Innovation and Development Centre at the Institute."
    },


    {
        date: "April 11, 2010",
        description: "Dr. S.S. Gokhale, Director, VNIT, Nagpur has taken over charge as Director (Addl. Charge) of the Institute."
    },


    {
        date: "May 27, 2011",
        description: "Prof. T. Srinivasa Rao, assumed charge as the Director of the Institute. It is a happy and momentous coincidence that he is an alumnus of this Institute."
    },



    {
        date: "October 26, 2012",
        description: "Dr. Krishna M. Ella takes charge as the Chairman, Board of Governors, NITW."
    },

    {
        date: "October 23 , 2017",
        description: "Prof. N.V. Ramana Rao has taken over the charge of director NIT warangal."
    },



    {
        date: "Sep 10, 2018",
        description: "Diamond Jubilee Celebrations."
    },

    // {
    //     date: "October 28, 2022",
    //     description: "Prof. N.V. Ramana Rao, has assumed the temporary charge of the post of Director of NIT, Warangal"
    // },

    {
        date: "April 27, 2023",
        description: "Prof. Bidyadhar Subudhi from IIT Goa has taken over charge of the post of Director, NIT Warangal on 27.04.2023. (F.N.)"
    },


    // Add your other milestones here

];



const FormerChairpersonsRECData = [
    {
        Name: "Prof. D. S. Reddi",
        tenure: "1959 - 1963"
    },

    {
        Name: "Sri L. N. Gupta, I.A.S.",
        tenure: "1964 - 1965"
    },

    {
        Name: "Prof. D. S. Reddi",
        tenure: "1965 - 1979"
    },

    {
        Name: "Sri P. V. Narasimha Rao",
        tenure: "1971"
    },

    {
        Name: "Sri A. Madan Mohan",
        tenure: "1972"
    },


    {
        Name: "Sri H. C. Sarin, I.C.S.",
        tenure: "1973"
    },


    {
        Name: "Sri M. V. Krishna Rao",
        tenure: "1974"
    },


    {
        Name: "Sri B. Srirama Murthy",
        tenure: "1975 - 1977"
    },


    {
        Name: "Sri T. Hayagrivachary",
        tenure: "1978 - 1982"
    },


    {
        Name: "Sri N. Chandrababu Naidu",
        tenure: "1982"
    },


    {
        Name: "Sri P. Ananda Gajapathi Raju",
        tenure: "1983 - 1984"
    },


    {
        Name: "Sri A. Prabhakara Rao",
        tenure: "1985 "
    },

    {
        Name: "Sri Ch. Ayyanna Patrudu",
        tenure: "1985 - 1987"
    },


    {
        Name: "Sri D. Satyanarayana",
        tenure: "1987 - 1989"
    },


    {
        Name: "Sri K. Eswar Kumar",
        tenure: "1990"
    },


    {
        Name: "Sri N. Janardhan Reddy",
        tenure: "1991"
    },


    {
        Name: "Sri G. Gurunadha Rao",
        tenure: "1991 - 1992"
    },


    {
        Name: "Sri M. K. Baig",
        tenure: "1993 - 1994"
    },

    {
        Name: "Sri Devineni Rajashekhar",
        tenure: "1995"
    },

    {
        Name: "Sri Lalit Mathur, I.A.S.",
        tenure: "1996"
    },


    {
        Name: "Dr. P. V. Rathnaiah",
        tenure: "1997 - 1999"
    },


    {
        Name: "Sri A. Rajendra Prasad",
        tenure: "2000 - 2001"
    },


    {
        Name: "Sri M. Damodar Reddy",
        tenure: "2002 - 2003"
    },





    // Add your other milestones here
];


const FormerChairpersonsNITWData = [
    {
        Name: "Dr. Hari Narain",
        tenure: "2004 - 2008"
    },

    {
        Name: "Prof. B. L. Deekshatulu",
        tenure: "2010 - 2012"
    },

    {
        Name: "Dr. Krishna M Ella",
        tenure: "2013 - 2015"
    },

    {
        Name: "Prof. N. V. Ramana Rao",
        tenure: "2017 - 2023"
    },

    // Add your other milestones here
];



const FormerPrincipalsRECData = [
    {
        Name: "Prof. M. Muthu Krishnudu (Special Officer)",
        tenure: "14.05.1959 (I)  - 31.08.1960"
    },

    {
        Name: "Prof. P. Govinda Krishnaiah",
        tenure: "01.09.1960  - 10.04.1963 (FN)"
    },



    {
        Name: "Prof. A. Palayya (I/c.)",
        tenure: "10.04.1963 (AN)  - 24.09.1964"
    },

    {
        Name: "Prof. V. V. L. Rao ",
        tenure: "24.09.1964 (AN) - 24.09.1966 "
    },

    {
        Name: "Prof. N. Sidheswar (I/c.)",
        tenure: "24.09.1966 - 06.02.1967 (FN) "
    },


    {
        Name: "Gr. Capt. M. J.  Kirpalani",
        tenure: "06.02.1967 (AN) - 15.08.1973"
    },


    {
        Name: "Prof. S. K. Lakshmana Rao (I/c.)",
        tenure: "16.08.1973 - 21.10.1973"
    },


    {
        Name: "Prof. K. Koteswara Rao",
        tenure: "22.10.1973 - 31.10.1989"
    },


    {
        Name: "Prof. N. Rama Rao",
        tenure: "01.11.1989 - 01.08.1990 "
    },

    {
        Name: "Prof. P. G. Sastry (I/c.)",
        tenure: "02.08.1990 - 05.01.1992"
    },


    {
        Name: "Prof. K. Seetharamulu",
        tenure: "06.01.1992 - 25.11.1993"
    },


    {
        Name: "Sri K. Pradeepchandra, IAS (I/c.)",
        tenure: "26.11.1993 - 28.05.1994 (FN)"
    },


    {
        Name: "Prof. R. L. Murty (I/c.)",
        tenure: "28.05.1994 (AN) - 21.06.1994"
    },


    {
        Name: "Prof. K. Kishan Rao",
        tenure: "03.09.1998 - 23.03.2002 (FN)"
    },


    {
        Name: "Prof. V. Vasudeva Rao (I/c.)",
        tenure: "23.03.2002 (AN) - 09.09.2002"
    },




    // Add your other milestones here
];


const FormerDirectorsNITWData = [
    {
        Name: "Prof. V. Vasudeva Rao (I/c.)",
        tenure: "10.09.2002 - 03.08.2003"
    },


    {
        Name: "Prof. D. K. Tripathy",
        tenure: "04.08.2003 - 27.03.2005"
    },

    {
        Name: "Prof. V. Rama Rao (I/c.)",
        tenure: "28.03.2005 - 20.11.2005"
    },

    {
        Name: "Prof. Y. Venkateswara Rao",
        tenure: "21.11.2005 - 07.04.2010"
    },


    {
        Name: "Prof. S. S. Gokhale (I/c.)",
        tenure: "08.04.2010 - 18.08.2010"
    },

    {
        Name: "Prof. Sunil Kumar Sarangi (I/c.)",
        tenure: "19.08.2010 - 02.11.2010"
    },

    {
        Name: "	Prof. V. Rama Rao (I/c.)",
        tenure: "02.11.2010 - 28.02.2011"
    },

    {
        Name: "Prof. K. Srimannarayana (I/c.)",
        tenure: "28.02.2011 (AN) - 27.05.2011"
    },


    {
        Name: "Prof. T. Srinivasa Rao",
        tenure: "27.05.2011 - 30.06.2016"
    },

    {
        Name: "Prof. R. V. Chalam (I/c.)	",
        tenure: "01.07.2016	 -  26.08.2016 "
    },

    {
        Name: "Prof. G. R. C. REDDY (I/c.)",
        tenure: "27.08.2016 - 22.10.2017 "
    },


    {
        Name: "Prof. N. V. Ramana Rao",
        tenure: "23.10.2017 - 26.04.2023"
    },




    // Add your other milestones here
];









const AboutusPage = () => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <>

            {/* <section><img className='img-fluid' alt='' src='images/nit-banner.jpg' /></section> */}<br/>
            {/* <center><h2  style={{ fontWeight: "600", color: "#b80924" ,textDecoration: "underline" }} className='mb-3' >About Us</h2></center> */}



            <ul className="nav nav-tabs justify-content-center aboutus-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link  about-tab active" id="tab-0" data-bs-toggle="tab" href="#tabpanel-0" role="tab" aria-controls="tabpanel-0" aria-selected="true">About Us</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-1" data-bs-toggle="tab" href="#tabpanel-1" role="tab" aria-controls="tabpanel-1" aria-selected="false">Milestone</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-2" data-bs-toggle="tab" href="#tabpanel-2" role="tab" aria-controls="tabpanel-2" aria-selected="false">Former Chairpersons</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-3" data-bs-toggle="tab" href="#tabpanel-3" role="tab" aria-controls="tabpanel-3" aria-selected="false">Former Principals and Directors</a>
                </li>
            </ul>

            <div className="container">
                <div className="tab-content pt-5 pb-5" id="tab-content">
                    <div className="tab-pane active" id="tabpanel-0" role="tabpanel" aria-labelledby="tab-0">

                        {/* ABOUT US START*/}

                        <div className='row'>

                            {/* <header>
                                <h2 class=" mb-0" style={{ color: "#2b4675" }}>About Us</h2>
                            </header> */}



                            <div className="milestones-list">
                                {AuData.map((milestone, index) => (
                                    <div
                                        className={`milestone-box ${index === hoveredIndex ? 'hovered' : ''}`}
                                        key={index}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        {index !== AuData.length - 1 && (
                                            <div className="arrow"></div>
                                        )}
                                        <div className="milestone-content">
                                            <div className="milestone-date">{milestone.date}</div>
                                            <div className="milestone-description">{milestone.description}</div>
                                        </div>
                                        {index !== AuData.length - 1 && (
                                            <div className="connector"></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>



                        <div className='row'>

                            <div className='col-md-8'>
                                <p><b>National Institute of Technology Warangal</b>, formerly known as Regional Engineering College, was established in 1959. Pandit Jawaharlal Nehru laid the foundation stone for this institute on October 10, 1959, <b>the first in the chain of 31 NITs (formerly known as RECs) in the country</b>.</p>

                                <p>
                                    The Institute is well known for its <b>dedicated faculty</b>, <b>staff</b> and the <b>state-of-the art infrastructure conducive</b> to a healthy academic environment.The Institute is constantly striving to achieve higher levels of technical excellence.  Evolving a socially relevant and yet internationally acceptable curriculum, implementing innovative and effective teaching methodologies and focusing on the wholesome development of the students are our concerns.  Thanks to UNESCO and UK assistance in the past, many developmental activities were undertaken. The World Bank Assistance under <b>Technical Education Quality Improvement Programme (TEQIP)</b> had been a timely help in the overall development of the Institute.</p>



                                <p>The Institute currently has <b>thirteen academic departments</b> and a few advanced research centres in various disciplines of engineering, pure sciences and management, with nearly 100 laboratories organized in a unique pattern of functioning, Central Library with state of the art facilities, Auditorium, Student Activity Centre, Mega Computer Centre (currently renamed as Centre for Digital Infrastructure and Services (CDIS)), Indoor Games Complex, big stadium, Seminar Halls with required infrastructure, Dispensary with state of art of facilities, etc. Faculty of repute, brilliant student

                                    community, excellent technical and supporting staff and an effective administration have all contributed to the pre-eminent status of NIT Warangal.</p>


                                <p>
                                    The Institute offers Ten undergraduate programmes (B.Tech.) in engineering, Twenty nine post graduate programmes (M.Tech., M.Sc., MCA and MBA) in engineering, sciences and management and research programmes in engineering, sciences, humanities, physical education and management. The institute is well-known for its Research and Development, Industrial consultancy, Continuing education and Training programmes for teachers and industrial personnel.

                                </p>



                                <ul class="visbullets">
                                    <li>NIT Warangal is a residential institute with nearly 6000+ ( UG & PG ) students apart from nearly 1000+ Ph.D., ( Full Time and Part time) Scholars.</li>
                                    <li>It has 16 residential blocks including one mega & one ultra-mega hostel for boys, two hostels for girls and one hostel for foreign students.</li>
                                    <li>It has five messes for boys, one mess exclusively for girls and one mega mess with present state of art facilities for boys, foreign students and girls.</li>
                                    <li>It has nearly 320 teaching staff and 360 administrative and supporting staff apart from outsource personnel.</li>
                                    <li>It is a self contained residential campus located in a beautiful wooded land of about 248 acres. It is located near to Kazipet at a distance of 3 Kms from Kazipet Railway Station and 12 Kms from Warangal Railway Station. It is located at a distance of 140 Kms from Hyderabad. Warangal enjoys a unique position in the cultural and historical map of the state. The exquisite and intricate architecture in Ramappa, Thousand Pillar Temple, Badrakali Temple and the Warangal Fort have been centres of attraction. Lovely gardens, wild life sanctuaries, lakes and rocks in the vicinity are other places of interest for students. The place enjoys a good climate throughout the year except for the patches of hot summer during May-June.</li>
                                </ul>


                                <p> It is a residential campus with all the basic facilities for both the students and staff to live on the campus. The following statistical figures might give an idea about the buildings in the campus. The plinth area occupied by the different buildings on the campus are: Administrative Building (3,331 Sqm), Academic Buildings (34,448 sqm), Library Building (3,610 sqm),  Students Hostels (32,642 sqm), Sports Pavilion and Gallery (784 Sqm), Director's Bungalow, Staff Quarters and Guest House (31,598 sqm) The road network in the campus runs to a length of 12 kms. Work is in progress in creating more space for the varied purposes referred above and also for the extension of network of roads.
                                </p>

                                <p>The placement service at the institute is one of the best of its kind in the country. The alumni of the institute hold responsible and enviable positions all over the world and are in constant touch with the institute. Every new entrant into the portals of this institution is poised for partaking a rich heritage and tradition that is unique to NITW. According to NIRF RANK 2023, NITW clinches the 21st rank among the Engineering Institutes in the country.</p>





                            </div>
                            <div className='col-md-4'>
                                <img src="images/nitw1.jpg" alt="" className='img-fuild mb-2' />


                                <img src="images/nitw2.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw3.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw4.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw5.jpg" alt="" className='img-fuild mb-2' />
                            </div>






                        </div>
                        {/* ABOUT US END*/}

                    </div>
                    <div className="tab-pane" id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1">



                        {/* MILESTONE START*/}


                        <div className='row'>

                            {/* <header>
                                <h2 class=" mb-0" style={{ color: "#2b4675" }}>Milestone</h2>
                            </header> */}


                            <div className="milestones-list">
                                {milestonesData.map((milestone, index) => (
                                    <div
                                        className={`milestone-box ${index === hoveredIndex ? 'hovered' : ''}`}
                                        key={index}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        {index !== milestonesData.length - 1 && (
                                            <div className="arrow"></div>
                                        )}
                                        <div className="milestone-content">
                                            <div className="milestone-date">{milestone.date}</div>
                                            <div className="milestone-description">{milestone.description}</div>
                                        </div>
                                        {index !== milestonesData.length - 1 && (
                                            <div className="connector"></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* MILESTONE END*/}




                    </div>
                    <div className="tab-pane" id="tabpanel-2" role="tabpanel" aria-labelledby="tab-2">



                        {/* Former Chairman START*/}
                        <div className='row'>

{/* 
                            <header>
                                <h2 class=" mb-0" style={{ color: "#2b4675" }}>Former Chairman</h2>
                            </header> */}




                            <div className='col-md-12'>


                                <div className='formerChairpersons mb-5'>
                                    <h4 style={{ fontWeight: "600", color: "#f19800 " }} className='mb-3'>Former Chairpersons of Regional Engineering College, Warangal</h4>

                                    <div className="table-responsive">
                                        <table className='table '>
                                            <thead style={{ backgroundColor: "#2b4675", color: "#fff" }}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Tenure</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {FormerChairpersonsRECData.map((chairperson, index) => (
                                                    <tr key={index}>
                                                        <td>{chairperson.Name}</td>
                                                        <td>{chairperson.tenure}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className='formerChairpersons'>
                                    <h4 style={{ fontWeight: "600", color: "#f19800" }} className='mb-3'>Former Chairpersons of National Institute of Technology, Warangal</h4>

                                    <div className="table-responsive">
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#2b4675", color: "#fff" }}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Tenure</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {FormerChairpersonsNITWData.map((chairperson, index) => (
                                                    <tr key={index}>
                                                        <td>{chairperson.Name}</td>
                                                        <td>{chairperson.tenure}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>






                            </div>
                            {/* <div className='col-md-4'>


                                <img src="images/nitw6.jpg" alt="" className='img-fuild mb-2' />


                                <img src="images/nitw7.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw8.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw9.jpg" alt="" className='img-fuild mb-2' />






                            </div> */}
                        </div>
                        {/* Former Chairman END*/}




                    </div>
                    <div className="tab-pane" id="tabpanel-3" role="tabpanel" aria-labelledby="tab-2">


                        {/* Former Chairman START*/}
                        <div className='row'>


                            {/* <header>
                                <h2 class=" mb-0" style={{ color: "#2b4675" }}>Former Principals and Directors</h2>
                            </header> */}


                            <div className='col-md-12'>

                                <h4 style={{ fontWeight: "600", color: "#f19800" }} className='mb-3'>Former Principals  Regional Engineering College, Warangal</h4>


                                <div className='formerChairpersons mb-5'>
                                    <div className="table-responsive">
                                        <table className='table'>
                                            <thead style={{ backgroundColor: "#2b4675", color: "#fff" }}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Tenure</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {FormerPrincipalsRECData.map((principal, index) => (
                                                    <tr key={index}>
                                                        <td>{principal.Name}</td>
                                                        <td>{principal.tenure}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                                <div className='formerChairpersons'>
                                    <h4 style={{ fontWeight: "600", color: "#f19800" }} className='mb-3'>Former Directors  National Institute of Technology, Warangal</h4>

                                    <div className="table-responsive">
                                        <table className='table '>
                                            <thead style={{ backgroundColor: "#2b4675", color: "#fff" }}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Tenure</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {FormerDirectorsNITWData.map((director, index) => (
                                                    <tr key={index}>
                                                        <td>{director.Name}</td>
                                                        <td>{director.tenure}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>







                            </div>



                            {/* <div className='col-md-4'>



                                <img src="images/nitw11.jpg" alt="" className='img-fuild mb-2' /> */}


                                {/* <img src="images/nitw12.jpg" alt="" className='img-fuild mb-2' /> */}

                                {/* <img src="images/nitw13.jpg" alt="" className='img-fuild mb-2' />

                                <img src="images/nitw14.jpg" alt="" className='img-fuild mb-2' />



                            </div> */}
                        </div>
                        {/* Former Chairman END*/}




                    </div>

                </div>
















            </div>










        </>
    );
}

export default AboutusPage;
