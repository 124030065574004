export const StudentAccoladesData = [
    {
        // {
    //     isactive: 'yes',
    //     title: ''
    // },
    
        isactive: 'yes',
        title: 'Madasu Pavan Kumar, a research scholar under the supervision of Prof Thyageshwar  in the Department of Biotechnology, has been awarded the prestigious International Travel Support Grant by the Asian Crystallography Association (ACA). This grant will support his participation in the 18th Conference of the Asian Crystallographic Association, to be held in Kuala Lumpur, Malaysia, from December 1-6, 2024.'
    },
    {
        isactive: 'yes',
        title: 'NIT Warangals Team Mechxhausters Wins 1st Place in Cost Presentation at SUPRA SAE INDIA 2024'
    },

    {
        isactive: 'yes',
        title: 'Ms. Gargi Singh, Research Scholar in the Department of  Chemistry under the guidance of Prof. V Rajeshumar, successfully presented her research findings in the international conference "23rd Lecture Conference-ORCHEM 2024," which took place from September 9–September 11, 2024, at the University of Regensburg, Germany.'
    },
    {
        isactive: 'yes',
        title: ' Ms. Nakka Anitha,  Ph. D. Research Scholar in the Physics Dept  under the guidance of  Prof T. Venkatappa Rao got Financial Assistance for participating in  "International Conference on Renewable Energy and Energy Conversion Devices (ICREED-24), USA (21 October, 2024 to 22 October, 2024)" under  Anusandhan National Research Foundation (ANRF) of SERB.'
    },
    {
        isactive: 'yes',
        title: 'Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage'
    },
    {
        isactive: 'yes',
        title: 'Mr. Soumya Ranjan Pradhan, Ph.D. Research Scholar under the supervision of Dr. V. Jayalakshmi, Department of Physics, has been awarded a DBT International Travel Grant to give an oral presentation at the 29th International Liquid Crystal Conference (ILCC2024), July 21-26, 2024, Rio de Janeiro, Brazil.'
    },
    {
        isactive: 'yes',
        title: 'Mr. Jitesh Pani, Ph. D. Inspire Fellow, Department of Physics Received the Best Poster Award  and Cash Prize in the International Forum in Plasma and Thin Film Technologies for Sustainable  Development Goals, Taiwan and Awarded Taiwan Experience Education Program to Pursue Research Work on Energy Storage'
    },
    {
        isactive: 'yes',
        title: 'Mr. Nikhil Vangety, Ph.D. Research Scholar under Prof. Sourabh Roy, Department of Physics received a DST-SERB International Travel Support grant for participating in the SPIE Photonics Europe conference held from 7th April to 11th April 2024 in Strasbourg, France'
    },
    {
        isactive: 'yes',
        title: 'Miss. Nila B., Ph. D. Research Scholar in the Department of Mathematics Received DST-SERB International Travel Support and CSIR Foreign Travel Grant to Participate 33rd European Conference on Operational Research in Copenhagen, Denmar'
    },
    {
        isactive: 'yes',
        title: 'Best Poster Award to Ms. Anjaly Babu (Department of Physics) in the conference on Innovations in Materials Science and workshop on Characterization Techniques at Indian Institute of Technology Roorkee on 24-27th April 2024.'
    },
    {
        isactive: 'yes',
        title: 'Best Paper Award to Mr. Shekher Sheelam in ICAME 2024 conference, SRM University, Chennai on 20-22nd Mar 2024.'
    },
    {
        isactive: 'yes',
        title: 'Best Oral Presentation Award to Ms. A. Mahalakshmi in the ICMSDT 2024, Central University of Jammu, J & K'
    },
    {
        isactive: 'yes',
        title: 'Mr. B. Dinesh, Ph. D. Student under Dr. P. Sampath Kumar has been awarded the Best Research Presentation in TLS 2024 at IIT Delhi.'
    },
    {
        isactive: 'yes',
        title: 'Best Paper Award for PhD Student Nitish Gupta and D. Bhargavi, "An Analytical Study of the Impact of an Inclined Magnetic Field on Couette Flow with Jeffrey Fluid under Local Thermal Non-Equilibrium (LTNE) and Viscous Dissipation", paper presented at the "International Conference on Advancements in Materials, Manufacturing, and Automation (AMMA-2023)", held during April 7-8, 2023, organized by Amrita Vishwa Vidyapeetham, Tamil Nadu, India.'
    },

]