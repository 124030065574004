import { useState } from "react";
import '../css/cseDeptStyle.css';
const PhysicsDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img
              src="https://nitw.ac.in/api/static/files/Physics_banner_2023-9-27-18-43-55.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>

              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/ph-haranath"
                target="_blank"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt="" className="img-fluid" width="190" height="220" />
                <h6>Prof. Haranath Divi</h6>
              </a>
                <p className="justified-text"> Head of the Department <br /> <a href="physics_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>physics_hod@nitw.ac.in</a> <br /> 9490165351 </p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p className="justified-text">
                      The Department of Physics was established in 1959, the very year of inception of the then REC, Warangal.
                      The department offers postgraduate and doctoral programs in addition to catering the physics requirements of engineering programs.
                      The department has a strong team of 14 highly qualified and motivated faculty members with diversified research experience.
                      The department has excellent infrastructure available for pursuing research in cutting edge technologies.  M.Sc. (Tech.)
                      Engineering Physics, has been a flagship program of the department. The program was introduced in the year 1970 under UNESCO‘s sponsorship, with the active association of experts from UK and Russia.
                      Further, M.Sc. (Tech.) is an industry-oriented program and is structured on par with the then PG programs of engineering with one full semester for dissertation work and eight weeks of sandwiched industrial training.
                      The program of M.Sc. (Tech.) enjoys 100% job potential right from its inception with placements in the best of industry and R & D organizations within the country and abroad. Currently two specializations – electronics & instrumentation, photonics are being offered.
                      Recognizing the need for creating a talent pool in physical sciences, in tune with NEP 2020, the department has introduced a 5-year integrated M. Sc program.
                      Further, the department offers, engineering physics, open electives minors for all  B.Tech. courses and electives to different M. Tech programs.
                      The   department   is   actively engaged in research with number of sponsored R&D projects under execution. The   specific areas   of   research   include Materials for energy harvesting,  Super capacitors, Glasses   and   Glass   ceramics, Biomaterials, Luminescent materials, Ferroelectrics, Magnetic   materials, Polymer degradation studies, Photonics, Electronics, Medical Instrumentation, tropospheric radio propagation, Fiber optic Sensors, High temperature measurement, Holographic NDT, Microcontrollers and communication, Nano Ferrites & Nano Composites, Nanophosphors & Bio Glass Ceramics, Optical system design, Organic Light Emitting Diodes, Organic, polymer & Dye-sensitized solar cells, Pressure sensing, Liquid Crystals, Microfluidics, Transparent conducting thin films, Vibration measurement, Theoretical physics ,statistical physics, soft matter , biophysics and other frontier areas .
                      A grant of Rs.2.75 Crores was received by the department under the first phase of FIST program of DST (Govt of India) to establish FE-SEM and XRD facilities.
                      The department has liaison with reputed industries and R&D organizations like BEL, CSIR, DRDO, DST, BARC, ELOIRA, RCI, ARCI, CSIO, LRDE, IRDE, Maharashtra Bamboo Development Board, Simply Fresh, Nutranxt Biotech Pvt Ltd., Kataline Infra Products Pvt Ltd.
                    </p>



                  </div>
                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="justified-text">Be on the forefront of applied research and dissemination of knowledge combining Science and Engineering perspectives.</p>
                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="justified-text">Develop the scientific insight and technical competence of the students for keeping abreast with the advancements in Science and Technology to meet the demands of Industry and Research.</p>

                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardPhysics/Phy" > Notices/Announcements</a>
                    </h4>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
<div className="col-md-12">
  <h3 className="mb-3">PhD</h3>
</div>
</div>*/}
                  <div className="row">








                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Sc.(Tech.) </h3>
                            <p className="justified-text">Since its inception in 1959, the Department of Physics has kept pace with developments in advanced areas in the discipline,
                              the introduction of a three year M.Sc. (Tech.)  in  Photonics and Electronics Technologies in Engineering Physics course with
                              specializations reflecting that mindset. Such courses aim to develop professionals who are versatile and conversant with the needs of the industry.</p>
                            <p className="justified-text">Admissions to the course are made through an All India Entrance Test - Joint Admission Test for  M.Sc.(JAM).</p>
                            <p className="justified-text">Apart from its regular coursework,  the course includes  Industrial  Training of eight weeks and six months
                              of Project Work/Dissertation in various reputed  R&D Organizations and industries.</p>
                            <p className="justified-text">The Department strives to provide a balanced blend of both   Science and Engineering curriculum and hands-on training for students.
                              The course curriculum is upgraded regularly in keeping with the ever-changing needs and demands of the industry.</p>


                            {/*<img src="CSEDeptAssets/images/mtech2.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="https://nitw.ac.in/api/static/files/msc_phy_ep_2024_2024-7-9-12-49-19.pdf"
                            target="_blank">M.Sc.(Tech.) Scheme and Syllabus<i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>







                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>PhD</h3>
                            <p className="justified-text">The Department offers PhD program in the advanced fields of Physics such as Electronic Instruementation, Optics, Photonics, Thin film Technology, Materials Science, Optical and Bio glasses, Glass cermaics, Solar Cells, Sensors, Organic LEDs, Soft condensed matter (Liquid crystals).</p>
                            {/*<img src="CSEDeptAssets/images/mca.jpg" alt="" className="img-fluid"/>*/}
                            <p className="justified-text">The candidates are admitted to the program through notification released by NIT Warangal. Eligibility criteria, mode of admissions and other detials are subjected to the notification and may change time to time.</p>
                            <p className="justified-text">For more information, visit the main website regularly.    </p>
                            {/* <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2021/09/04/phd-rules-and-regulations-2021-22.pdf"
                              target="_blank">Current PhD Regulations <i
                                className="fa fa-angle-right"></i> </a> </p> */}

                          </div>

                        </div>
                      </div>
                    </div>






                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Integrated Master of Science in Physics</h3>
                            <p className="justified-text">This is a five-year integrated M. Sc. program in Physics. The first three years of the programme are common to all science students, where they get a thorough exposure to all the basic branches of science. The students move to the Physics department from the fourth year.</p>

                            <p className="justified-text">Minimum qualification: This program is open to all students who have completed/expect to complete the 10+2 stage with at least three of the four subjects (Physics, Chemistry, Mathematics and Biology) as their optionals with a minimum of 60% at the 10+2 level. </p>

                            {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                            <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/api/static/files/In_M.Sc_PHY_2024_2024-7-8-20-19-39.pdf"
                              target="_blank"> Integrated M. Sc Scheme and Syllabus <i
                                className="fa fa-angle-right"></i> </a> </p>

                          </div>

                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">

                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Integrated B.Sc, B.Ed Physics</h3>
                            <p className="justified-text">The program aims at preparing teachers for Foundational, Preparatory, Middle and Secondary stages as per the new school structure of NEP2020. It will ensure that outstanding students enter the teaching profession. A student undergoing this course will be grounded in Indian values, languages, knowledge, ethos, tribal tradition and also well-versed in latest advances in education and pedagogy. This course caters to the need of 21st Century skills.</p>

                            <p className="justified-text">The Integrated Teacher Education Programme (ITEP) Centre is being established at NIT Warangal with grants from the Ministry of Education, Government of India. This scheme will function in a separate building (formerly known as TLC center) with the state-of-art training facilities which include a studio for video recording of lectures. Many senior and young faculty members across different departments of the Institute are associated with this center as members of the Core-Team.
                            </p>


                            {/* <p className="more"> <a className="more-info-link" href="https://oldweb.nitw.ac.in/media/uploads/2022/07/06/integrated-msc-physics.pdf"
                                                        target="_blank">For detailed program structure, courses and syllabus offered <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}

                          </div>

                        </div>
                      </div>
                    </div>















                  </div>

                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 className="">FIELD EMISSION SCANNING ELECTRON MICROSCOPY (FE-SEM)</h3>
                      </header>
                      <img src="https://nitw.ac.in/api/static/files/JEOL_JSM_IT-800_2023-9-18-17-35-35.jpg" alt="" className="img-fluid mb-1" />
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card item-card card-block">
                            {/*<p className="card-text mb-3">A computer lab is a space where computer services are provided to a defined community. These are typically public libraries and academic institutions.</p>*/}
                            <h4 className="card-title  mb-2">JEOL JSM IT-800 (is) is a versatile high-resolution Scanning Electron Microscope.</h4>

                            <h5 className="card-title  mb-2">Specifications:</h5>
                            {/* <table border="0.5" cellpadding="5"> */}
                            <table className="table table-bordered table-hover table-striped">

                              <thead className="thead-light">
                                <tr>
                                  <th><strong>Source:</strong></th>
                                  <th>In-lens Schottky Plus field emission electron gun (0.01V to 30kV)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><strong>Resolution:</strong></td>
                                  <td>0.6nm at 15 kV<br></br>1.0 nm at 1 kV</td>
                                </tr>
                                <tr>
                                  <td><strong>Magnification:</strong></td>
                                  <td>25X to 2MX</td>
                                </tr>
                                <tr>
                                  <td><strong>Mode:</strong></td>
                                  <td>High Vacuum (for conductors)<br></br>Low Vacuum (for insulators)</td>
                                </tr>
                                <tr>
                                  <td><strong>Accessories available:</strong></td>
                                  <td>EDS<br></br>Detector (standard)<br></br>Secondary electron detector (SED)<br></br>Upper electron detector (UED)<br></br>Upper in lens detector (UID)</td>
                                </tr>
                                <tr>
                                  <td><strong>Objective lens:</strong></td>
                                  <td>Semi in lens</td>
                                </tr>
                                <tr>
                                  <td><strong>Specimen stage:</strong></td>
                                  <td>Full eucentric goniometer stage</td>
                                </tr>
                                <tr>
                                  <td><strong>Stage control:</strong></td>
                                  <td>5-axis motor drive</td>
                                </tr>
                                <tr>
                                  <td><strong>Specimen size:</strong></td>
                                  <td>Type1 (standard)<br></br>Maximum diameter: 170 mm, Maximum height: 45 mm (WD 5 mm)<br></br>Stage movement range (X:70mm Y:50mm Z:1 to 41mm Tilt: -5 to 70° Rotation: 360°)</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>Type2 (option)<br></br>Maximum diameter: 200 mm, Maximum height: 55 mm (WD 5 mm)</td>
                                </tr>
                              </tbody>
                            </table>




                            <div className="row">

                              <h3 className="">Applications:</h3>

                              <div className="col-md-6 col-sm-12">
                                <li>Surface analysis</li>
                                <li>Materials evaluation</li>
                                <li>Failure analysis</li>
                                <li>Particle distribution</li>
                                <li>Structural analysis Measurement of sample microscopic features</li>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <li>Study of surface morphology</li>
                                <li>Characterization of coatings</li>
                                <li>Integrated circuit evaluation</li>
                                <li>Study of microstructures</li>
                                <li>Fracture and structural defects analysis</li>
                              </div>

                            </div>



                          </div>
                        </div>

                      </div>
                    </div>



                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <div className="row">
                        <div>
                          <div className="card item-card card-block">
                            <p></p>
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB1_2023-9-20-13-2-54.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB2_2023-9-20-13-3-50.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB3_2023-9-20-13-4-16.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB4_2023-9-20-13-4-38.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB5_2023-9-20-13-5-7.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/PHYSICS_LAB6_2023-9-20-13-5-28.jpg" alt="" className="img-fluid mb-5" />

                      </div>




                    </div>


                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">


                      <h3 className="">Computer lab</h3>

                      <div >
                        <div>
                          <div className="card item-card card-block">
                            <p>This lab is used for Digital design, Verilog, C++, Matlab, Microprocessor, Microcontroller, signal systems labs.</p>
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Computer_lab_2023-9-18-18-57-12.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Optics lab</h3>

                      <div >
                        <div>
                          <div className="card item-card card-block">
                            <p>This lab is used for Optical system design, Basic Optics.</p>
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Optics_lab_2023-9-18-19-1-50.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Material Science lab</h3>

                      <div >
                        <div>
                          <div className="card item-card card-block">
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Material_Science_lab_2023-9-18-19-2-24.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Mechanics and Wave oscillation lab</h3>

                      <div >
                        <div>
                          <div className="card item-card card-block">
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Mechanics_and_Wave_oscillation_lab_2023-9-18-19-2-46.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Electronics Lab</h3>

                      <div>
                        <div>
                          <div className="card item-card card-block">
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Electronics_Lab_2023-9-18-19-3-10.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Electricity Lab</h3>

                      <div>
                        <div>
                          <div className="card item-card card-block">
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Electricity_Lab_2023-9-18-19-3-32.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6 laboratory-facilities-individual">

                      <h3 className="">Heat and Thermodynamics Lab</h3>

                      <div >
                        <div>
                          <div className="card item-card card-block">
                          </div>
                        </div>
                        <img src="https://nitw.ac.in/api/static/files/Heat_and_Thermodynamics_Lab_2023-9-18-19-3-57.jpg" alt="" className="img-fluid mb-2" />
                      </div>
                    </div>

                  </div>

                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor </a>

                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">

                      <section id="profile-info">

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-abdop"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17032/17032.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-abdop"
                                    target="_blank">Prof. Aalu Boda</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494868704 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:abdop@nitw.ac.in">abdop@nitw.ac.in </a> </p>
                                  <p className="justified-text">Theoretical condensed matter physics, Low dimensional systems and nanostructures (Quantum Dots), Spintronics and Computational materials science.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* 
                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-dinakar"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16364/16364.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-dinakar"
                                    target="_blank">Prof. Dinakar Dantala</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9866948560  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:dinakar@nitw.ac.in  ">dinakar@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Electronics , fiber optic sensors and Material Science.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-haranath"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-haranath"
                                    target="_blank">Prof. Haranath Divi</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9958101115, 7678248149 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:haranath@nitw.ac.in  ">haranath@nitw.ac.in  </a> </p>
                                  <p className="justified-text"> Photonic materials; Nanomaterials; Luminescent Materials and Devices; Mesoporous materials; Aerogels; Fractals; Sol-gel Technology; Energy Harvesting; Piezoelectric Devices
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-paul"
                              target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ph-paul" alt=""
                                className="img-fluid" width="150px" height="180px !important" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-paul"
                                    target="_blank">Prof. D. Paul Joseph</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969475 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text">Broad Area : Condensed Matter Physics - Experimental Specific Topics : Oxide and metallic Thin films, Nano-materials for Energy related applications - Proximity Effect between Manganite and YBCO - Transparent Conducting Thin Films - Magnetic Materials. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-bhitesh"
                              target="_blank" className="img-fluid"> <img
                                src="https://wsdc.nitw.ac.in/facultynew/media/17033/17033.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-bhitesh"
                                    target="_blank">Prof. Hitesh Borkar</a> </h5>
                                  <span>Assistant Professor </span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8447287127 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bhitesh@nitw.ac.in ">bhitesh@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> 2-D materials and polar electroceramics; Opto-Electronic Devices, (Transparent photovoltaics; Solar cells) ;Electronic and magnetic materials for energy harvesting applications; Ferroelectrics; multiferroics;</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-ktr"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-ktr"
                                    target="_blank">Prof. Kuppusamy Thangaraju</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969476 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ktr@nitw.ac.in">ktr@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Organic Semiconductors; Optical and Electrical Properties of Organic Semiconductors; Organic Light Emitting Diodes (OLEDs); Phosporescent OLEDs; Organic Solar Cells (OPVs); Organic/Inorganic Hybrid Nanostructures; Organic/inorganic hybrid based optoelectronic devices; Hybrid UV Photodetectors; Organic/Inorganic Hybrid Energy storage devices. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-kusum"
                              target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ph-kusum" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-kusum"
                                    target="_blank">Prof. Kusum Kumari</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9502052625<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kusum@nitw.ac.in">kusum@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text">Energy Materials (Quantum dots and 2D materials), Perovskite Solar Cells, Hybrid Polymer Solar Cells, Energy Storage Devices </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-kanapuram.udaykumar"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17030/17030.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-kanapuram.udaykumar"
                                    target="_blank">Prof. K Uday Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9940107507
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto: kanapuram.udaykumar@nitw.ac.in"> kanapuram.udaykumar@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Nanogenerators, Thin Films; Electrochromics; Transparent Conducting Oxides; Energy Harvesting Materials and Devices. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-drazeem2002"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-drazeem2002"
                                    target="_blank">Prof. P Abdul Azeem</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969473, 9490108924 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:drazeem2002@nitw.ac.in ">drazeem2002@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Glass science, Photonic crystals, Optical system designing, Optical properties of Glass and glass ceramics, Luminescent materials and Devices, Biomaterials (glasses, glass ceramics and composites ) for tissue engineering , drug delivery and bio imaging applications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-syamprasad"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-syamprasad"
                                    target="_blank">Prof. P. Syam Prasad</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969472
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Glasses and glass-ceramics for electronic, optical and biomedical applications Nanostructured magnetic and semiconductor materials Magnetic and semiconductor thin films and devices</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-rakeshr"
                              target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ph-rakeshr" alt=""
                                className="img-fluid" width="150" height="183" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-rakeshr"
                                    target="_blank">Prof. Rakesh Kumar Rajaboina</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  9676220574<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rakeshr@nitw.ac.in "> rakeshr@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Energy Harvesting; Energy Storage; Nanogenerators; Nanomaterials; Nanowires growth; Sensors; Thin films; Gas sensors, Contact :Room no : 415 Energy Harvesting and Nanomaterials Lab Department of Physics National Institute Of Technology- Warangal</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-sroy"
                              target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ph-sroy" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-sroy"
                                    target="_blank">Prof. Sourabh Roy</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969474 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sroy@nitw.ac.in  ">
                                      sroy@nitw.ac.in   </a> </p>
                                  <p className="justified-text">Optics & Photonics: Integrated Optics; Fiber Optics; Nonlinear Optics; Photonic Crystal Waveguides and Fibers; Photonic Sensor; Optical System Design. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>






                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-skghosh"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17031/17031.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-skghosh"
                                    target="_blank">Prof. Surya K. Ghosh</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9820702782 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:skghosh@nitw.ac.in">skghosh@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Biophysics, Soft Condensed Matter Physics, Computational Physics, Polymer Physics, Active Matter, Anomalous Diffusion, Statistical Mechanics.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-tvraokmm"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-tvraokmm"
                                    target="_blank">Prof. Tumu Venkatappa Rao</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9248667047 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Green Nanomaterials; Polymer Nanocomposites; Activated Carbon; Biosensors; Gas Sensors; Supercapacitors; Radiation effects on Polymers; Fiber Bragg sensors; Glasses and Glass Ceramics; Highly correlated electron systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-jayalakshmiv"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16618/16618.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-jayalakshmiv"
                                    target="_blank">Prof. V Jayalakshmi</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969477 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jayalakshmiv@nitw.ac.in">jayalakshmiv@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Our group works in the area of Experimental Soft Condensed Matter Physics. Research topics include Microfluidics, Biosensors, Anti-counterfeiting, Smart windows, Confinement Effects, Rheology, Geometric Effects, Chromonic liquid crystals, etc., CREATIVE, ENTHUSIASTIC, SELF MOTIVATED AND HARD WORKING CANDIDATES WHO HAVE PASSION TOWARDS SCIENCE ARE ALWAYS WELCOME FOR THE PHD AND SHORT TERM PROJECTS.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/ph-vijay"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16384/16384.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/ph-vijay"
                                    target="_blank">Prof. Vijay Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493330158 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vijay@nitw.ac.in">vijay@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text">Machine Learning, Deep learning, Optical Angular Momentum, Optical communication, Fiber optics, Nano-Optics, Singular Optics, Correlation Optics; Spin-Orbit interactions: Structured Light-matter Interactions.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                      aria-labelledby="nav-AdjunctFaculty-tab">

                      <div className="col-md-12 profile-info-individual">
                        <div className="row">

                          <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="https://nitw.ac.in/api/static/files/Gowrishanakr_2024-8-20-17-39-26.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0">Dr. S. Gowrishanakr </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"> 
                                    <i className="fa fa-phone" aria-hidden="true"></i>9894936033 <br />
                                      <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:">ksgowrish@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="https://nitw.ac.in/api/static/files/Anshu_2024-8-20-17-46-54.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Dr. Anshu </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info"> 
                                    <i className="fa fa-phone" aria-hidden="true"></i>7680967759 <br />
                                      <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:">gauranshu20@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>




                           <div className="col-md-9">
                            <div className="card">
                              <div className="d-flex align-items-center">
                                <div className="image"> <img src="https://nitw.ac.in/api/static/files/Yadagiri_2024-8-20-17-50-25.jpg" alt=""
                                  className="img-fluid" /> </div>
                                <div className="ml-3 w-100">
                                  <h6 className="mb-0 mt-0"> Dr. K. Yadagiri </h6>
                                  <div className=" mt-2">
                                    <p className="contact-info">  <i className="fa fa-phone" aria-hidden="true"></i>9392125199 <br />
                                      <i className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:">kynitw@gmail.com</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
 




                        </div>
                      </div>
                    </div>



                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">
                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr.A.Jai_Singh_2023-9-20-13-18-3.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. A. Jai Singh</h6> <span>Assistant(SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 0870246-2551, 8332969445 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jaisinghajmera@nitw.ac.in">jaisinghajmera@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="CIVDeptAssets/images/st3.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Sri. J Srinivas</h6>
                                <span>Senior Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info">
                                    Office landline: <b>2101</b><br />
                                    <i className="fa fa-phone"
                                      aria-hidden="true"></i> 8332969395
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr.N.Krishna_Swamy_2023-9-20-13-21-11.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. N. Krishna Swamy</h6> <span>Caretaker(SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969604 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:nkrishnaswamy@nitw.ac.in">nkrishnaswamy@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._K._Veeraiah_2023-9-20-16-4-35.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. K. Veeraiah</h6>
                                <span>Senior Caretaker</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969657 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kveeraiah@nitw.ac.in">kveeraiah@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._G._Pratap_2023-9-20-16-7-17.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. G. Pratap</h6>
                                <span>Technician (upgraded)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969683 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prathapg@nitw.ac.in">prathapg@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._Tamal_Roy_2023-9-20-16-9-8.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. Tamal Roy</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9007169226 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:tamalr@nitw.ac.in">tamalr@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._A._Balakrishna_2023-9-20-16-11-38.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. A. Balakrishna</h6> <span>Technical Assistant</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9491473961 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:abalakrishna@nitw.ac.in">abalakrishna@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._B.Srikantha_Chary_2023-9-20-16-13-10.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. B.Srikantha Chary</h6> <span>Technical Assistant

                                </span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9573792623 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bssrikanth16@nitw.ac.in">bssrikanth16@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._K._Siva_Vara_Prasad_2023-9-20-16-14-51.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. K. Siva Vara Prasad</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7386599749 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kothapallis@nitw.ac.in">kothapallis@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mrs._K._Kamala_2023-9-20-16-17-20.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mrs. K. Kamala</h6> <span>Junior Assistant (upgraded)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9704806624<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kkamala@nitw.ac.in">kkamala@nitw.ac.in</a>

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mrs._P._Krishna_Veni_2023-9-20-16-18-53.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mrs. P. Krishna Veni</h6> <span>Junior Assistant (upgraded)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969486<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pkrishnaveni@nitw.ac.in">pkrishnaveni@nitw.ac.in</a>

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}







                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Mr._J._Narsaiah_2023-9-25-17-33-1.jpg" alt=""
                                className="img-fluid" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Mr. J. Narsaiah</h6> <span>Junior Assistant (upgraded)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969689<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:narsaiahj@nitw.ac.in">narsaiahj@nitw.ac.in</a>

                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>









                      </div>

                    </div>





                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">


                      <div className="row phd-std-row">
                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img ">

                              <img src="https://nitw.ac.in/api/static/files/Mr._Ramesh_Banoth_2023-9-20-16-43-57.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : perovskite solar cells and device fabrication<b></b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Ramesh Banoth </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9866195346 <br /> <span> <a href="mailto:rameshb444@student.nitw.ac.in">rameshb444@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._M._Krishnam_Raju_2023-9-20-16-47-54.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>nano phosphors display application </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. M. Krishnam Raju</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  8125761219 <br /> <span> <a href="mailto:krishnamraju@student.nitw.ac.in	">krishnamraju@student.nitw.ac.in	</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._M._Sattaiah_2023-9-20-16-50-30.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>bioactive glassses for tissue engineering applications</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. M. Sattaiah	</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9966893542
                              <br /> <span> <a href="mailto:ms718162@student.nitw.ac.in	" >ms718162@student.nitw.ac.in	</a></span> </p>

                          </div>
                        </div>

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="https://nitw.ac.in/api/static/files/Mr._Siju_Mishra_2023-9-20-16-53-4.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Green energy harvesting</b>
                  </p>       
                </div>
              </div>
            </div>
            <h6 className="std-title">Mr. Siju Mishra</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>   
            <p className="contact-info">  8918355858
     <br/> <span> <a href="mailto:sijum@student.nitw.ac.in" >sijum@student.nitw.ac.in</a> </span></p>      
          </div>
        </div> */}

                        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Vangety_Nikhil_2023-9-20-16-55-40.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fiber specklegram analysis </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Vangety Nikhil</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  8277045009
                              <br />  <span><a href="mailto:-" >-</a></span>  </p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._A._Prasad_2023-9-20-16-57-49.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nano bioglass ceramics for tissue engineering applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. A. Prasad</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info"> 9989167149
                              <br /> <span> <a href="mailto:Prasadbabu23@student.nitw.ac.in" >Prasadbabu23@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Sushil_Patel_2023-9-20-17-13-54.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Bioactive glasses for Tissue Enginnering and Drug Delivery Applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Sushil Patel</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  7974374252
                              <br />  <span><a href="mailto:patelsushil@student.nitw.ac.in" >patelsushil@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Mahalakshmi_(CSIR-SRF)_2023-9-20-17-16-22.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nano bioactive glasses and ceramics </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Mahalakshmi (CSIR-SRF)</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info"> 7416924578
                              <br />  <span> <a href="mailto:maha94@student.nitw.ac.in" >maha94@student.nitw.ac.in</a></span> </p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._T._Lavanya_2023-9-20-17-18-11.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Carbon quantum dots incorporated hybrid thin films for optoelectronic device applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. T. Lavanya</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  7799538420
                              <br /><span>  <a href="mailto:lavi1506@student.nitw.ac.in" >lavi1506@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Supraja_Potu_2024-1-30-11-39-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy harvesting by using Nanogenerators </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. P. Supraja </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  7330993889
                              <br /> <span> <a href="mailto:supraja1210@student.nitw.ac.in" >supraja1210@student.nitw.ac.in</a></span></p>
                          </div>
                        </div> */}

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._S._P._Rama_Devi_(CSIR-SRF)_2023-9-20-17-22-54.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Liquid Crystals for smart window application </b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. S. P. Rama Devi (CSIR-SRF) </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9591259817
                              <br />  <span><a href="mailto:ramaphy@student.nitw.ac.in" >ramaphy@student.nitw.ac.in	 </a></span> </p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._N._Anitha_2023-9-20-17-25-23.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Storage Applications (Supercapacitors) </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. N. Anitha </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9705276621
                              <br /> <span> <a href="mailto:na720107@student.nitw.ac.in" >na720107@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Pooja_Yadav_2023-9-20-17-27-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Recycling Waste-Derived Materials for Energy Storage Applications  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Pooja Yadav</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info"> 7988803020
                              <br /> <span> <a href="mailto:py720108@student.nitw.ac.in" >py720108@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Purnesh_Singh_B._2023-9-20-17-30-46.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Free Space Optical Communication, Speckle-based Communication, Machine Learning, and Deep Learning.  </b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Purnesh Singh B.  </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info"> 9133138573
                              <br /> <span> <a href="mailto:bp721100@student.nitw.ac.in" >bp721100@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Energy harvesting by using Triboelectric Nanogenerator  </b>
                  </p>          
                </div>
              </div>
            </div>
            <h6 className="std-title">Ms. Anjaly Babu </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>   
            <p className="contact-info"> 8712253125 
     <br/> <span> <a href="mailto:ab721101@student.nitw.ac.in	" >ab721101@student.nitw.ac.in	 </a></span> </p>
            
          </div>
        </div>
         */}

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Gouranga_Maharana_2023-9-20-17-35-21.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Photocatalytic Activity of TCOs<b></b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Gouranga Maharana</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />
                            <p className="contact-info"> 9658561017
                              <br /> <span> <a href="mailto:gm721102@student.nitw.ac.in	" >gm721102@student.nitw.ac.in	 </a> </span></p>
                          </div>
                        </div>




                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Naveenkumar_Kurapati_2023-9-20-17-38-10.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : Optical glasses <b></b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Naveenkumar Kurapati</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9494759059
                              <br /> <span> <a href="mailto:nk721103@student.nitw.ac.in" >nk721103@student.nitw.ac.in		 </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Muddamalla_Rakshita_(CSIR-SRF)_2023-9-20-17-40-13.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Luminescent Materials and Devices
                                    </b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Muddamalla Rakshita (CSIR-SRF)   </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info"> 9392065664
                              <br /> <span> <a href="mailto:mr721104@student.nitw.ac.in" >mr721104@student.nitw.ac.in		 </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Anirban_Majee_2023-9-20-17-43-44.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Fiber Optics Sensor  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Anirban Majee</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info">  8768009099
                              <br /> <span> <a href="mailto:am712159@student.nitw.ac.in" >am712159@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Saumya_Ranjan_Pradhan_2023-9-20-17-45-22.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Soft Matter Physics  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Saumya Ranjan Pradhan</h6>
                            <span>Full-Time Research Scholar       </span>
                            <hr />
                            <p className="contact-info"> 8018681754
                              <br /> <span> <a href="mailto:sp712161@student.nitw.ac.in" >sp712161@student.nitw.ac.in</a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms.Kachu_Anusha_2023-9-21-10-11-11.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Theoretical Condensed matter physics  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Kachu Anusha</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info">  9959330841
                              <br /> <span> <a href="mailto:ak712162@student.nitw.ac.in" >ak712162@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Rohit_2023-9-21-10-13-4.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Storage Devices (Supercapacitors)</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Rohit </h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9812933510
                              <br />   <span><a href="mailto:ry712163@student.nitw.ac.in" >ry712163@student.nitw.ac.in</a></span> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Aachal_Anilkumar_Sharma_2023-9-21-10-15-4.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Luminescent materials and devices</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Aachal Anilkumar Sharma (INSPIRE)</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  8275837888
                              <br /> <span> <a href="mailto:as712164@student.nitw.ac.in" >as712164@student.nitw.ac.in</a></span> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Jitesh_Pani_2023-9-21-10-17-11.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy storage by Supercapacitors  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Jitesh Pani (INSPIRE)</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9437578231
                              <br /> <span> <a href="mailto:jp712165@student.nitw.ac.in" >jp712165@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Vrinda_Garg_2023-9-21-10-20-6.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Softmatter and Biophysics   </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Vrinda Garg	</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9650934426
                              <br /> <span> <a href="mailto:vg712166@student.nitw.ac.in" >vg712166@student.nitw.ac.in   </a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Simma_Babu_Rao_2023-9-21-10-22-9.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Molecular Dynamics, Softmatter and Biophysics</b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Simma Babu Rao</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  7013928230
                              <br /> <span> <a href="mailto:sb21phrer01@student.nitw.ac.in" >sb21phrer01@student.nitw.ac.in    </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Navaneeth_M_2023-9-21-10-24-41.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>2D Materials based Nanogenerators for Energy Harvesting </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Navaneeth M</h6>
                            <span>Full-Time Research Scholar    </span>
                            <hr />
                            <p className="contact-info">  9400568999
                              <br /> <span> <a href="mailto:nm21phrer02@student.nitw.ac.in" >nm21phrer02@student.nitw.ac.in   </a> </span></p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Gangadhar_Mahar_2023-9-21-10-27-24.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nanophosphor derived from recycling waste  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Gangadhar Mahar</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9777433495
                              <br />  <span><a href="mailto:gm21phrer04@student.nitw.ac.in" >gm21phrer04@student.nitw.ac.in    </a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Koppula_Naresh_2023-9-21-10-30-4.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Hybrid Nanostructured Materials for Energy Storage Applications</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Koppula Naresh</h6>
                            <span>Full-Time Research Scholar      </span>
                            <hr />
                            <p className="contact-info">  6304902959
                              <br /> <span> <a href="mailto:kn21phrer05@student.nitw.ac.in" >kn21phrer05@student.nitw.ac.in    </a> </span></p>
                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrs._Dharavath_Rajeswari_2023-9-21-10-32-54.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Bioactive glass nanofibers for tissue engineering applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mrs. Dharavath Rajeswari</h6>
                            <span>Full-Time Research Scholar (IDRBT)</span>
                            <hr />
                            <p className="contact-info"> 6301353944
                              <br /> <span> <a href="mailto:dr21phrer07@student.nitw.ac.in" >dr21phrer07@student.nitw.ac.in</a></span> </p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrs._Chandmare_Vaishali_Prabhakar_2023-9-21-10-34-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Experimental condensed matter physics and optoelectronic devices </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mrs. Chandmare Vaishali Prabhakar</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 9881551337
                              <br />  <span> <a href="mailto:cv21phrer08@student.nitw.ac.in" >cv21phrer08@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Payal_Priyadarshini_Pradhan_2023-9-21-10-36-34.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Luminescent materials and devices   </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Payal Priyadarshini Pradhan</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  8637200673
                              <br /> <span> <a href="mailto:pp21phrer09@student.nitw.ac.in" >pp21phrer09@student.nitw.ac.in   </a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Joel_K_Joseph_2023-9-21-10-38-21.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Hybrid Thin Film based Optoelectronic devices</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Joel K Joseph</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  9497175788
                              <br />  <span><a href="mailto:jk22phr1r02@student.nitw.ac.in" >jk22phr1r02@student.nitw.ac.in   </a> </span></p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._P_M_Pooja_2023-9-21-10-48-26.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Structured light   </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. P M Pooja</h6>
                            <span>Full-Time Research Scholar   </span>
                            <hr />
                            <p className="contact-info">  8610647067
                              <br />   <span><a href="mailto:pm22phr1r03@student.nitw.ac.in" >pm22phr1r03@student.nitw.ac.in   </a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._D_Swathi_Rohitha_2023-9-21-10-50-36.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nano bioglass for tissue engineering applications</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. D Swathi Rohitha</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info"> 6302740434
                              <br /> <span> <a href="mailto:ds22phr1r05@student.nitw.ac.in" >ds22phr1r05@student.nitw.ac.in   </a> </span></p>
                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Yuvashree._J_2023-9-21-10-53-30.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nanostructured Materials for Photocatalytic activity</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Yuvashree. J</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />
                            <p className="contact-info"> 9445103559
                              <br /> <span> <a href="mailto:hj22phr1r06@student.nitw.ac.in" >hj22phr1r06@student.nitw.ac.in  </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._KAK_Durga_Prasad_2023-9-21-10-55-16.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Luminescent Materials and Devices</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. KAK Durga Prasad</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9000825965
                              <br /> <span> <a href="mailto:ka22phr1r08@student.nitw.ac.in" >ka22phr1r08@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Velpula_Mahesh_2023-9-21-10-57-10.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Metal Organic Framework Based Triboelectric Nanogenerators</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Velpula Mahesh</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info">  6281055061
                              <br /> <span> <a href="mailto:vm22phr2r01@student.nitw.ac.in" >vm22phr2r01@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Chithari_Krishnakanth_2023-9-21-11-25-6.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>SOFT MATTER PHYSICS </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Chithari Krishnakanth</h6>
                            <span>Full-Time Research Scholar  </span>
                            <hr />
                            <p className="contact-info"> 7095529621
                              <br /> <span> <a href="mailto:ck22phr2r02@student.nitw.ac.in" >ck22phr2r02@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>                     
                </div>
              </div>
            </div>
            <h6 className="std-title">Mr. A. Kishore Kumar</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>          
            <p className="contact-info">  9096620002        
     <br/> <span> <a href="mailto:-" >-</a> </span></p>
            
          </div>
        </div>
         */}

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>            
                </div>
              </div>
            </div>
            <h6 className="std-title">Mrs. Uma Chandi</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9866050122        
     <br/> <span> <a href="mailto:mumachandi@gmail.com" >mumachandi@gmail.com</a> </span></p>
            
          </div>
        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>
             </div>
              </div>
            </div>
            <h6 className="std-title">Mr. K. Rajaiah</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  8884189514         
     <br/> <span> <a href="mailto:krajaiah@student.nitw.ac.in" >krajaiah@student.nitw.ac.in</a></span> </p>
            
          </div>
        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>
               </div>
              </div>
            </div>
            <h6 className="std-title">Mrs. V. P. Madhurima</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 7416840433        
     <br/>  <span><a href="mailto:vpmadhurima@student.nitw.ac.in" >vpmadhurima@student.nitw.ac.in</a> </span></p>
            
          </div>
        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._N._K._P._Chaitanya_2023-9-21-11-40-22.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Stress Under Dynamic Condition </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. N. K. P. Chaitanya</h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info">  8985754020
                              <br />  <span><a href="mailto:praturi.chaianya@gmail.com" >praturi.chaianya@gmail.com</a> </span></p>

                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>- </b>
                  </p>
              </div>
              </div>
            </div>
            <h6 className="std-title">Mrs. K. Radhika</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> -          
     <br/>  <span><a href="mailto:-" >-  </a> </span></p>
            
          </div>
        </div> */}

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>- </b>
                  </p>
              </div>
              </div>
            </div>
            <h6 className="std-title">Mr. A. Pranav Kumar</h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9921971577       
     <br/> <span> <a href="mailto:pranavkumar.avj@gmail.com" >pranavkumar.avj@gmail.com</a> </span></p>
            
          </div>
        </div>
        */}
                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._S_Chand_Rakesh_Roshan_2023-9-21-11-45-10.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Computational Design of materials for thermal energy applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. S Chand Rakesh Roshan</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9908797832
                              <br /> <span> <a href="mailto:sc719109@student.nitw.ac.in" >sc719109@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Reddivari_Muniramaiah_2023-9-21-11-48-21.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Transparent Conducting Electrodes for Optoelectronic Devices </b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Reddivari Muniramaiah</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9533596895
                              <br /> <span> <a href="mailto:muniramphy@student.nitw.ac.in" >muniramphy@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Energy Harvesting by usingTribo electric nano generators</b>
                  </p>
                 
                </div>
              </div>
            </div>
            <h6 className="std-title">Ms. Lakshmi Suneetha Vikram</h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9490370102       
     <br/> <span> <a href="mailto:lv712167@student.nitw.ac.in" >lv712167@student.nitw.ac.in</a> </span></p>
            
          </div>
        </div>
         */}



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrs._S._Suneetha_2023-9-21-11-51-1.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Inorganic/organic hybrid nano structures for optoelectronic applications</b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Mrs. S. Suneetha  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9866087609
                              <br />  <span><a href="mailto:ss720105@student.nitw.ac.in" >ss720105@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Lakshmi_Venkataramana_Puriparthi_2023-9-21-11-52-50.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Radiation Physics</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Mr. Lakshmi Venkataramana Puriparthi </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />

                            <p className="contact-info">  9666274278
                              <br /> <span> <a href="mailto:lp712168@student.nitw.ac.in" >lp712168@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>

                        {/*         
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>                
                </div>
              </div>
            </div>
            <h6 className="std-title"> Mr. Rajani Kumar Kandikonda</h6>
            <span>Part-Time Research Scholar </span>
            <hr/>           
            <p className="contact-info">  8297231507        
     <br/> <span> <a href="mailto:rk21phrep01@student.nitw.ac.in" >rk21phrep01@student.nitw.ac.in </a></span> </p>           
          </div>
        </div> */}

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-  </b>
                  </p>
                </div>
              </div>
            </div>
            <h6 className="std-title"> Ms. K.A Saraswathi  </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9177209598            
     <br/> <span> <a href="mailto: kk21phrep02@student.nitw.ac.in" > kk21phrep02@student.nitw.ac.in</a> </span></p>
            
          </div>
        </div> */}

                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>-</b>
                  </p>
                </div>
              </div>
            </div>
            <h6 className="std-title"> Mr. Sathish Kumar Manda </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 9949905027            
     <br/> <span> <a href="mailto:sk21pyrep03@student.nitw.ac.in" >sk21pyrep03@student.nitw.ac.in </a></span> </p>
            
          </div>
        </div> */}


                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Tyagaiah_Pottabattini_2023-9-21-11-58-26.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Soft Matter Physics</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Mr. Tyagaiah Pottabattini  </h6>
                            <span>Part-Time Research Scholar  </span>
                            <hr />

                            <p className="contact-info"> 9866677836
                              <br /> <span> <a href="mailto:tp21phrep04@student.nitw.ac.in" >tp21phrep04@student.nitw.ac.in </a></span> </p>

                          </div>
                        </div>

                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mr._Rajesh_Kumar_Uppula_2023-9-21-12-0-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Super capacitors </b>
                                  </p>

                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Mr. Rajesh Kumar Uppula   </h6>
                            <span>Part-Time Research Scholar    </span>
                            <hr />

                            <p className="contact-info"> 8096331445
                              <br /> <span> <a href="mailto:rk22phr1p01@student.nitw.ac.in" >rk22phr1p01@student.nitw.ac.in </a> </span></p>

                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>- </b>
                  </p>              
                </div>
              </div>
            </div>
            <h6 className="std-title">Ms. V Swapna </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 9949518018       
     <br/> <span> <a href="mailto:vs22phr1p02@student.nitw.ac.in" >vs22phr1p02@student.nitw.ac.in </a> </span></p>
            
          </div>
        </div> */}


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>- </b>
                  </p>
                </div>
              </div>
            </div>
            <h6 className="std-title"> Mr. Ravikiran Jadi  </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> -          
     <br/><span>  <a href="mailto:-" >-</a></span> </p>
            
          </div>
        </div> */}



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Kora_Sujatha_2023-9-21-12-5-1.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Nano materials for photocatalysis </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title"> Ms. Kora Sujatha  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 8639191245
                              <br /> <span> <a href="mailto:ks22phr2p03@student.nitw.ac.in" >ks22phr2p03@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ms._Mridula_A._2023-9-21-12-6-38.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Harvesting Devices </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Mridula A.  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 8367383716
                              <br /> <span> <a href="mailto:ma22phr2p04@student.nitw.ac.in" >ma22phr2p04@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>








                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrs._K.A.Saraswathi_2023-9-21-12-11-17.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Harvesting Devices </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mrs. K. A. Saraswathi  </h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 9177209598
                              <br /> <span> <a href="mailto:kk21phrep02@student.nitw.ac.in" >kk21phrep02@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>





                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/ANJALY_BABU_2023-12-1-10-47-0.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Research area- Metal-organic framework based Triboelectric nanogenerator </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Ms. Anjaly Babu  </h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> 8712253125
                              <br /> <span> <a href="mailto:ab721101@student.nitw.ac.in" >ab721101@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rajesh_Katru_2023-12-1-10-48-28.png" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Harvesting Storage and Applications </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mr. Rajesh Katru</h6>
                            <span>Full-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> -
                              <br /> <span> <a href="mailto:rk23phr1r05@student.nitw.ac.in" >rk23phr1r05@student.nitw.ac.in</a></span> </p>

                          </div>
                        </div>



                        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div className="phd-std-member">
                            <div className="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/V._Lakshmi_Suneetha_2023-12-1-10-49-23.jpg" className="img-fluid image" alt="" />
                              <div className="overlay">
                                <div className="std-details ">
                                  <p className="justified-text">
                                    Research Area : <b>Energy Harvesting  with waste materials </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <h6 className="std-title">Mrs. V. Lakshmi Suneetha</h6>
                            <span>Part-Time Research Scholar </span>
                            <hr />
                            <p className="contact-info"> -
                              <br /> <span> <a href="mailto:suneetha.vikram@gmail.com" >suneetha.vikram@gmail.com</a></span> </p>

                          </div>
                        </div>


                        {/* <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="-" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>- </b>
                  </p>
                 </div>
              </div>
            </div>
            <h6 className="std-title"> Mrs. Erukulla Kavitha </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9441712790            
     <br/> <span> <a href="mailto:ek22phr2p05@student.nitw.ac.in" >ek22phr2p05@student.nitw.ac.in</a></span> </p>
            
          </div>
        </div> */}

                        {/*          
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="https://nitw.ac.in/api/static/files/Mrs._K.A.Saraswathi_2023-9-21-12-11-17.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Biosensors</b>
                  </p>
                </div>
              </div>
            </div>
            <h6 className="std-title"> Mrs. K.A.Saraswathi  </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9177209598          
     <br/><span>  <a href="mailto:kk21phrep02@student.nitw.ac.in" >kk21phrep02@student.nitw.ac.in</a></span> </p>
            
          </div>
        </div> */}

                      </div>
                    </div>

                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div className="row std-society">

                    {/* <p className="justified-text"> 1. SPIE-Student chapter </p>
                                  <p className="justified-text"> 2. Physics association   </p>

                                  <p className="justified-text"> 3. OSA-Student chapter  </p> */}



                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/ph-ktr"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/ph-ktr"
                          target="_blank">
                          <h6>Prof. Kuppusamy Thangaraju</h6> <span> Faculty Advisor, Physics Society </span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969476 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:ktr@nitw.ac.in"
                              style={{ color: "#808080" }}>ktr@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/ph-dinakar"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16364/16364.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/ph-dinakar"
                          target="_blank">
                          <h6>Prof. Dinakar Dantala</h6> <span> Faculty Advisor, OSA</span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9866948560 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:dinakar@nitw.ac.in "
                              style={{ color: "#808080" }}>dinakar@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div> */}




                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16367"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16367"
                          target="_blank">
                          <h6>Prof. P Abdul Azeem</h6> <span> Faculty Advisor, SPIE Student chapter</span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969473 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:drazeem2002@nitw.ac.in "
                              style={{ color: "#808080" }}>drazeem2002@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://erp.nitw.ac.in/ext/profile/ph-sroy"
                        target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ph-sroy" alt="" className="img-fluid" />
                      </a>
                        <div className="data"> <a
                          href="https://erp.nitw.ac.in/ext/profile/ph-sroy"
                          target="_blank">
                          <h6>Prof. Sourabh Roy</h6> <span> Faculty Advisor, OPTICA Student chapter</span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i>8332969474 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:sroy@nitw.ac.in "
                              style={{ color: "#808080" }}>sroy@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>





                  </div>
                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/Shri.Durga_Prasad_Allada_2023-9-18-16-50-19.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Shri. Durga Prasad Allada</h6>

                          <p className="justified-text"><b>Batch: </b>M.Sc -Tech  (1984) - (Engineering Physics)<br />
                            <b>Present Position: </b>CTO & VP Tech Mahendra, Hyderabad
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/Shri.Amith_Kumar_2023-9-18-16-53-7.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Shri. Amith Kumar</h6>

                          <p className="justified-text"><b>Batch :</b> MSc (Tech) (2001-2004) – Engineering Physics, Electronics Specialization<br />
                            <b>Present Position :</b> Analog Devices as a Staff Engineer – Field applications
                          </p>

                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Subhash_Thota_2023-9-18-16-56-43.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Subhash Thota</h6>


                          <p className="justified-text"><b>Batch :</b> MSc (Tech) (2000-03) – Engineering Physics, Electronics Specialization<br />
                            <b>Present Position :</b> Professor, IIT-Gandhinagar
                          </p>




                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Ram_Mohan_Vasu_2023-9-18-16-58-40.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Ram Mohan Vasu</h6>
                          <p className="justified-text"><b>Batch :</b> MSc (Tech) – Engineering Physics, Electronics Specialization<br />
                            <b>Present Position :</b> Professor, IISc-Bangalore
                          </p>


                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Veerendra_Vasamsetty_2023-9-18-17-0-42.jpg" alt="" className="img-fluid" width="150" height="180" />
                        <div className="data">
                          <h6>Veerendra Vasamsetty</h6>
                          <p className="justified-text"><b>Batch :</b> MSc (Tech) (1997-2000) – Engineering Physics, Electronics Specialization<br />
                            <b>Present Position :</b> Director R&D, Schneider Electric
                          </p>

                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Sridhar Muppala</h6>



                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CEO, Silicon Valley

                                                  </p>

                                             
                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Movva Ramu</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> Microsoft Research Centre, USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>K V Sridhar</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CEO Analytics CA, USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>




                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Jitendra Lakotia</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> Former VP of Cognos. USA
                                                  </p>

                                              </div>
                                          </div>
                                      </div>



                                      <div className="col-md-3 col-6">
                                          <div className="card ">
                                              <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                              <div className="data">
                                                  <h6>Amarnadh Reddy</h6>
                                                   <p className="justified-text">
                                                      <b>Present Position :</b> CTO, CGG, Hyderabad
                                                  </p>
                                              </div>
                                          </div>
                                      </div> */}




                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Advanced Materials
                        </h4>
                      </header>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>  <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16364/16364.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D. Dinakar</h6>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9866948560  <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:dinakar@nitw.ac.in">dinakar@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9248667047 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969472<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D Haranath</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9958101115, 7678248149 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:haranath@nitw.ac.in">haranath@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16618/16618.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. V Jayalakshmi</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969477 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:jayalakshmiv@nitw.ac.in ">jayalakshmiv@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9676220574 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17030/17030.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. K Uday Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9940107507 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kanapuram.udaykumar@nitw.ac.in ">kanapuram.udaykumar@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Energy Harvesting Materials and Devices</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://nitw.ac.in/api/static/files/harinath_2024-7-3-17-41-34.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D Haranath</h6><span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9958101115, 7678248149 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:haranath@nitw.ac.in">haranath@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9248667047 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969475<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969476 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9676220574 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17030/17030.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. K Uday Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9940107507 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kanapuram.udaykumar@nitw.ac.in ">kanapuram.udaykumar@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17033/17033.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Hitesh Borkar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8447287127 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bhitesh@nitw.ac.in">bhitesh@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Photonics and Optoelectronics</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16369/16369.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Sourabh Roy</h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969474<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sroy@nitw.ac.in ">sroy@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969472<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969476 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969475<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16618/16618.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. V Jayalakshmi</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969477 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:jayalakshmiv@nitw.ac.in ">jayalakshmiv@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16384/16384.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Vijay Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>  9493330158 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vijay@nitw.ac.in ">vijay@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>



                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Glass science and technology</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16366/16366.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P. Syam Prasad</h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969472<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: syamprasad@nitw.ac.in"> syamprasad@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16367/16367.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Abdul Azeem</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9490108924, 8332969473 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:drazeem2002@nitw.ac.in">drazeem2002@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16365/16365.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Tumu Venkatappa Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9248667047 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tvraokmm@nitw.ac.in">tvraokmm@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>







                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Solar photovoltaics</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16432/16432.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kuppusamy Thangaraju</h6><span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969476 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: ktr@nitw.ac.in "> ktr@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16431/16431.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. D. Paul Joseph</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969475<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:paul@nitw.ac.in">paul@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Theoretical and Computational Physics</h4>
                      </header>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17031/17031.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Surya K. Ghosh</h6> <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9820702782 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:skghosh@nitw.ac.in ">skghosh@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17032/17032.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Aalu Boda</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9494868704 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:abdop@nitw.ac.in ">abdop@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16924/16924.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Rakesh Kumar Rajaboina</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9676220574 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rakeshr@nitw.ac.in ">rakeshr@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16384/16384.jpg" alt=""
                            className="img-fluid" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Vijay Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>  9493330158 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vijay@nitw.ac.in ">vijay@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>








                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>



                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  <div className="details">

                    {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                              <span>19th to 30th September 2022</span>  */}
                  </div>


                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery1_2023-9-18-17-17-12.jpg" alt="" className="img-fluid mb-5" />

                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery2_2023-9-18-17-25-58.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery3_2023-9-18-17-26-20.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery4_2023-9-18-17-26-50.jpg" alt="" className="img-fluid mb-5" />
                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery5_2023-9-18-17-27-10.jpg" alt="" className="img-fluid mb-5" />


                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery6_2023-9-18-17-27-48.jpg" alt="" className="img-fluid mb-5" />

                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery7_2023-9-18-17-28-18.jpg" alt="" className="img-fluid mb-5" />

                  <img src="https://nitw.ac.in/api/static/files/Photo_Gallery8_2023-9-18-17-28-43.jpg" alt="" className="img-fluid mb-5" />





                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">

                  <div className="listview">

                    <p className="listitem">Faculty members of Department of Physics are involved in several externally funded projected as PI and Co-PI in worth of more than 3.5 cores.</p>
                    <p className="listitem">Last academic year, total 109 research publications came from Depart of Physics in reputed Interaction journals.</p>
                    <p className="listitem">Six PhD Students from Dept. of Physics have revived International travel grants from SERB to present their research papers in international conferences.</p>
                    <p className="listitem">An Indian Patent No. 533731 namely &quot;OZONIT The Multipurpose Disinfection Unit&quot; has been granted on 19-04-2024 to the inventors: D Haranath, Siju Mishra, R Rakesh Kumar and D Dinakar</p>

                    <p className="listitem">Prof D Haranath has been Elected as Board Member for International Association of Advanced Materials (IAAM), Sweden for the year 2024</p>
                    <p className="listitem">Prof D Haranath has been Elected as R&amp;D Advisory Board Member for Gemmological Institute of India (GII), Govt of India Undertaking from 2024-2026.</p>
                    <p className="listitem">Prof D Haranath has been named as Top 0.5% of all Scholars Worldwide in the field of Photoluminescence by ScholarGPS, USA</p>
                    <p className="listitem">Prof. Sourabh Roy research work on “Non-line-of-sight optical communication with structured light” and “Speckle-based recognition of OAM modes” was highlighted in optics and photonics news by Optica publishing group USA, as one of the top 30 breakthroughs in optics in the year 2023 and 2022 respectively.</p>
                    <p className="listitem">Dr. Hitesh Borkar has been awarded the SERB SIRE Award as a Visiting Scientist at ORNL, USA</p>
                    <p className="listitem">Department of Physics organized the International conference IEMDST-2024 during July 4 th and 5 th 2024 with the coordinators Prof. K. Thangaraju, Prof. D. Paul Joseph and Prof. V. Jayalakshmi</p>
                    <p className="listitem">PhD student Mr. Jitesh Panii secured the First Place and a cash prize at the prestigious International Conference PTSDG 2024, Taiwan.</p>
                  </div>
                </div>

{/* 
                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                  aria-labelledby="nav-AdjunctFaculty-tab">
                  <div className="col-md-12 profile-info-individual">
                    <div className="row">

                      <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                        <a href="" target="_blank">

                          <img src="https://nitw.ac.in/api/static/files/Gowrishanakr_2024-8-20-17-39-26.jpg" alt=""
                            className="img-fluid" height="180" style={{ border: "5px solid #edc512" }} />
                        </a>
                      </div>
                      <div className="col-md-9">
                        <div className="card ">
                          <div className="data">
                            <h5> <a href=""
                              target="_blank">Dr. S. Gowrishanakr</a>
                            </h5>
                            <p className="contact-info"> <i className="fa fa-phone"
                              aria-hidden="true"></i>9894936033 <br /> <i
                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                  href="mailto:    "> ksgowrish@gmail.com </a>
                            </p>
                           
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                        <a href="" target="_blank">

                          <img src="https://nitw.ac.in/api/static/files/Anshu_2024-8-20-17-46-54.jpg" alt=""
                            className="img-fluid" height="180" style={{ border: "5px solid #edc512" }} />
                        </a>
                      </div>
                      <div className="col-md-9">
                        <div className="card ">
                          <div className="data">
                            <h5> <a href=""
                              target="_blank">Dr. Anshu</a>
                            </h5>
                            <p className="contact-info"> <i className="fa fa-phone"
                              aria-hidden="true"></i>7680967759 <br /> <i
                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                  href="mailto:    "> gauranshu20@gmail.com </a>
                            </p>
                           
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                        <a href="" target="_blank">

                          <img src="https://nitw.ac.in/api/static/files/Yadagiri_2024-8-20-17-50-25.jpg" alt=""
                            className="img-fluid" height="180" style={{ border: "5px solid #edc512" }} />
                        </a>
                      </div>
                      <div className="col-md-9">
                        <div className="card ">
                          <div className="data">
                            <h5> <a href=""
                              target="_blank">Dr. K. Yadagiri</a>
                            </h5>
                            <p className="contact-info"> <i className="fa fa-phone"
                              aria-hidden="true"></i>9392125199 <br /> <i
                                className="fa fa-envelope" aria-hidden="true"></i> <a
                                  href="mailto:    "> kynitw@gmail.com </a>
                            </p>
                            
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>


                </div> */}








              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                          <div className="copyright">
                               <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
    </>
  );
}
export default PhysicsDeptPage;