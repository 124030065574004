export const CampusActivityData = [
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam_2024-9-20-18-48-19.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },

    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Campaign2_2024-9-20-18-51-30.jpg',
       text: 'Swachhata Campaign',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Campaign1_2024-9-20-18-51-4.jpg',
       text: 'Swachhata Campaign',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam2_2024-9-20-18-50-30.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam1_2024-9-20-18-48-46.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },
    
    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Pledge_2024-9-20-18-47-45.jpg',
       text: 'Swachhata Pledge',
       isactive:'yes'
    },

];