// import { Typography } from "@mui/material";
// import React from "react";
// import { Link } from "react-router-dom";
// import {
//   createTheme,
//   responsiveFontSizes,
//   ThemeProvider,
// } from "@mui/material/styles";
// import "../components/Styles.css";
// import { BaseUrl, MainServerUrl } from "../BasePath";

// let theme = createTheme({
//   typography: {
//     fontFamily: "Merriweather",
//   },
// });
// theme = responsiveFontSizes(theme);

// const quickLinksList = [
//   {
//     title: "NIRF 2022",
//     link: "/nirf/2022",
//   },
//   {
//     title: "Annual Reports",
//     link: "/page/?url=/IAR/2021",
//   },
//   {
//     title: "Annual Accounts",
//     link: "/",
//   },
//   {
//     title: "Annual Budget",
//     link: "/",
//   },
//   {
//     title: "Acts and Statutes",
//     link: "/",
//   },
//   {
//     title: "Quality Policy",
//     link: "/",
//   },
//   {
//     title: "Strategic Plan",
//     link: "/",
//   },
// ];

// const Footer = () => {
//   return (
//     <footer className="page-footer bg-madison">
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-around",
//           flexWrap: "wrap",
//           borderBottom: "10px solid maroon"
//         }}
//       >
//         <ThemeProvider theme={theme}>

//           {/* {quickLinksList.map((item) => (
//             <a href={item.link}>
//               <Typography
//                 variant="h6"
//                 fontWeight="bold"
//                 color="white"
//                 style={{ padding: "1rem 0.5rem" }}
//               >
//                 {item.title}
//               </Typography>
//             </a>
//           ))} */}
//         </ThemeProvider>
//       </div>
//       {/* <div className="footer_list">
//         <ul className="quick-links p-1" style={{background : ' linear-gradient(270deg, rgba(17,38,10,1) 11%, rgba(65,90,91,1) 30%, rgba(17,38,10,1) 85%, rgba(17,38,10,1) 100%)'}}>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Annual Reports
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Annual Accounts
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Annual Budget
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Acts and Statutes
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Quality Policy
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 ISO 9001
//               </a>
//             </li>
//           </div>
//           <div className="quick-link-item">
//             <li>
//               <a href="/" className="text-black">
//                 Strategic Plan
//               </a>
//             </li>
//           </div>
//         </ul>
//       </div> */}
//       {/* <div className="hr bg-gray-light" /> */}
//       <div style={{ backgroundColor: "#212C1E" }} className="container-fluid section-xs block-after-divider">
//         <div className="row row-50 justify-content-xl-between justify-content-sm-center">


//           {/* <div className="col-sm-4 col-lg-4 col-xl-2 text-xl-start">
//             <h6 className="d-flex justify-content-center fw-bold text-black">
//               Programs
//             </h6>
//             <div className="text-subline" />
//             <div className="offset-top-30">
//               <ul className="list-marked list text-black">
//                 <li>
//                   <Link
//                     to={{
//                       pathname: `/page/?url=/academics/heritage`,
//                     }}
//                     className="text-black"
//                     onClick={() =>
//                       (window.location.href = `${BaseUrl}/page/?url=/academics/heritage`)
//                     }
//                   >
//                     Heritage
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to={{
//                       pathname: `/page/?url=/academics/euphrates`,
//                     }}
//                     className="text-black"
//                     onClick={() =>
//                       (window.location.href = `${BaseUrl}/page/?url=/academics/euphrates`)
//                     }
//                   >
//                     Euphrates
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to={{
//                       pathname: `/page/?url=/academics/nmeict`,
//                     }}
//                     className="text-black"
//                     onClick={() =>
//                       (window.location.href = `${BaseUrl}/page/?url=/academics/nmeict`)
//                     }
//                   >
//                     NMEICT PROJECTS
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div> */}

//           <div className="col-sm-6 col-lg-6 col-xl-6 text-xl-start">
//             <h6 className="d-flex justify-content-center fw-bold text-white">
//               Quick Links
//             </h6>
//             <div className="text-subline" />
//             <div className="offset-top-30">
//               <div className="d-flex">
//                 <div className="col">
//                   <ul className="list-marked list text-white">
//                     <li>
//                       <a
//                         href={`${MainServerUrl}/page/?url=/careersnitw/Jobs/`}

//                         className="text-white"
//                       // href=" https://www.nitw.ac.in/page/?url=/careersnitw/Jobs/"
//                       // className="text-white"
//                       >
//                         Careers / Jobs @ NITW
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://wsdc.nitw.ac.in/facultynew/"
//                         className="text-white"
//                       >
//                         Faculty Portal
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://erp.nitw.ac.in/"
//                         className="text-white"
//                       >
//                         SMILE ERP
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href={`https://wsdc.nitw.ac.in/`}
//                         className="text-white"
//                       >
//                         Student Portal
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://oldweb.nitw.ac.in/eict/" className="text-white">
//                         E&amp;ICT Academy
//                       </a>
//                     </li>
//                     <li>
//                       <a href="http://oldweb.nitw.ac.in/" target="_blank" rel="noreferrer" className="text-white">
//                         Old Website
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.nitw.ac.in/page/?url=/AcademicServices/2021"
//                         className="text-white"
//                       >
//                         Academic Services
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://nitw.ac.in/api/static/files/List_of_Closed_Holidays_for_the_year_2023_2023-4-3-11-39-40.pdf" target="_blank" rel="noreferrer" className="text-white">
//                         Holidays
//                       </a>
//                     </li>

//                     <li>
//                       <a href="https://nitw.ac.in/path/?dept=/NITW_FORMS" target="_blank" rel="noreferrer" className="text-white">
//                       Institute Forms
//                       </a>
//                     </li>
//                     <li>
//                       <a href='/team-member' className="text-white">Student Developers Team</a>
//                     </li>
//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/academics/heritage`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/academics/heritage`)
//                         }
//                       >
//                         Heritage
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/academics/euphrates`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/academics/euphrates`)
//                         }
//                       >
//                         Euphrates
//                       </Link>
//                     </li>


//                   </ul>
//                 </div>
//                 <div className="col">
//                   <ul className="list-marked list text-white">

//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/academics/nmeict`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/academics/nmeict`)
//                         }
//                       >
//                         NMEICT PROJECTS
//                       </Link>
//                     </li>
//                     <li>
//                       <a
//                         href='https://nitw.ac.in/api/static/files/ICC_Orders_with_contact_details_2023-5-19-11-6-57.pdf'
//                         className="text-white"
//                       >
//                         Internal Complaints Committee on Sexual Harassment
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href={`${MainServerUrl}/api/static/files/handbook_on_sexual_harassment_of_women_at_workplace_2022-0-19-13-59-21.pdf`}
//                         className="text-white"
//                       >
//                         Handbook on Sexual Harassment of Women at Workplace
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://nitw.irins.org/" className="text-white">
//                         Faculty Information System
//                       </a>
//                     </li>
//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/newsletter`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/newsletter`)
//                         }
//                       >
//                         Newsletters and Academic Reports
//                       </Link>
//                     </li>
//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/covid19/nitw`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/covid19/nitw`)
//                         }
//                       >
//                         COVID-19 News
//                       </Link>
//                     </li>
//                     <li>
//                       <a href="http://intranet.nitw.ac.in/" className="text-white">
//                         Intranet @NITW RO
//                       </a>
//                     </li>
//                     <li>
//                       <Link
//                         to={{
//                           pathname: `/page/?url=/contact-us`,
//                         }}
//                         className="text-white"
//                         onClick={() =>
//                           (window.location.href = `${BaseUrl}/page/?url=/contact-us`)
//                         }
//                       >
//                         Contact Us
//                       </Link>
//                     </li>

//                     <li>
//                       <a href="https://www.nitw.ac.in/page/?url=/EMERGENCY/ECN/" className="text-white">
//                         Emergency Contact Numbers
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/page/?url=/administration/rightToInformation" className="text-white">
//                         RTI Act
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/page/?url=/DeptwiseResearchFunds/DWRF" className="text-white">
//                       Dept wise Research Funds
//                       </a>
//                     </li>

//                   </ul>
//                 </div>
//                 <div className="col">
//                   <ul className="list-marked list text-white">
//                     <li>
//                       <a href="https://nitw.ac.in/path/?dept=/NIRF" className="text-white">
//                         NIRF
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/path/?dept=/DSTVPSCOPE" className="text-white">
//                       DST-VP-SCoPE
//                       </a>
//                     </li>

//                     <li>
//                       <a href="https://www.easytourz.com/BT-EmabedTour/all/b5f17aa2b040a89f" className="text-white">
//                         Virtual Tour
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/raa/" className="text-white">
//                         RAA
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/raa/" className="text-white">
//                         NAD
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://nitw.ac.in/api/static/files/Organization_Chart_2023-4-13-15-57-42.pdf" className="text-white">
//                         Organization Chart
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://nitw.ac.in/page/?url=/NITCouncil/NITWC" className="text-white">
//                         NIT Council
//                       </a>
//                     </li>
//                     <li>
//                       <a href="http://172.20.0.248:9000/" className="text-white">
//                         Telecom Directory& E-Mail ID Registration Forms
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/nirf/2022" className="text-white">
//                         NIRF 2022
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.nitw.ac.in/page/?url=/IAR/2021" className="text-white">
//                         Annual Reports
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-sm-12 col-lg-12 col-xl-5 text-xl-start justify-content-center d-flex">
//             <iframe
//               src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15179.588466037536!2d79.5308386!3d17.983523!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1b2c37fcb8fefce!2sNational%20Institute%20of%20Technology%2C%20Warangal%2C%20Telangana!5e0!3m2!1sen!2sin!4v1632005137818!5m2!1sen!2sin"
//               width={680}
//               height={350}
//               style={{ border: 0 }}
//               allowFullScreen
//               loading="lazy"
//               title="MAP"
//             />
//           </div>
//         </div>
//       </div>
//       <div className="pb-4" style={{ padding: "0 65px", backgroundColor: "#E1D29A", color: "black" }}>
//         <div className="pt-3">
//           <div className="row row-20 justify-content-sm-around offset-top-30 text-lg-start">
//             <div className="col-sm-10 justify-content-center col-lg-3 col-xl-3 text-center">
//               <h6 className="fw-bold text-black">Contact us</h6>
//               <div style={{ background: "black" }} className="text-subline text-black"></div>
//               <div className="offset-top-30">

//               </div>

//               <div className="offset-top-30 flex-row justify-content-center">
//                 <ul stlye={{
//                   display: "flex",
//                   justifyContent: "center"
//                 }} className="list-unstyled contact-info list">

//                   <li>
//                     <div className="unit flex-row justify-content-center  align-items-center unit-spacing-xs">

//                       <div className="unit-body">
//                         <img
//                           className="img-responsive"
//                           src={process.env.PUBLIC_URL + "/images/logo-170x172.png"}
//                           width={120}
//                           height={90}
//                           alt={"nitw footer logo"}
//                         />
//                       </div>
//                     </div>
//                   </li>
//                   <li>
//                     <div className="unit flex-row justify-content-center align-items-center unit-spacing-xs">
//                       <div className="unit-left">
//                         <span className="icon mdi mdi-phone text-middle icon-xs text-black"></span>
//                       </div>
//                       <div className="unit-body">
//                         <a className="text-black" href="tel:#">
//                           +91-870-2459191,
//                         </a>{" "}
//                         <a
//                           className="d-block d-lg-inline-block text-black"
//                           href="tel:#"
//                         >
//                           +91-870-2459547
//                         </a>
//                       </div>
//                     </div>
//                   </li>
//                   <li className="">
//                     <div className="unit flex-row justify-content-center align-items-center unit-spacing-xs">
//                       <div className="unit-left">
//                         <span className="icon mdi mdi-map-marker text-middle icon-xs text-black"></span>
//                       </div>
//                       <div className="unit-body text-start">
//                         <a className="text-black" href="/">
//                           National Institute of Technology Warangal, <br />Hanumkonda, Warangal - 506004,
//                           TS, INDIA
//                         </a>
//                       </div>

//                     </div>
//                   </li>
//                   <li className="">
//                     <div className="unit flex-row justify-content-center align-items-center unit-spacing-xs">
//                       <div className="unit-left">
//                         <span className="icon mdi mdi-email-open text-middle icon-xs text-black"></span>
//                       </div>
//                       <div className="unit-body">
//                         <a className="text-black" href="mailto:registrar@nitw.ac.in">
//                           registrar@nitw.ac.in
//                         </a>
//                       </div>
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//               <div className=" offset-top-20 text-center">
//                 <ul className=" list-inline list-inline-sm list-inline-madison">
//                   <li>
//                     <a
//                       href="/"
//                       className="icon icon-xxs fa fa-facebook icon-circle icon-gray-light-filled"
//                     >
//                       {" "}
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="/"
//                       className="icon icon-xxs fa fa-twitter icon-circle icon-gray-light-filled"
//                     >
//                       {" "}
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="/"
//                       className="icon icon-xxs fa fa-google icon-circle icon-gray-light-filled"
//                     >
//                       {" "}
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="/"
//                       className="icon icon-xxs fa fa-instagram icon-circle icon-gray-light-filled"
//                     >
//                       {" "}
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div>


//             <div className="col-sm-10 col-lg-4 col-xl-4 text-center px-sm-20">
//               <h6 className="fw-bold text-black">National Portals</h6>
//               <div style={{ background: "black" }} className="text-subline "></div>

//               <div className="row">
//               <div className="col"><a href="https://www.education.gov.in/" target="_blank">
//                   <img src=
//                   "https://nitw.ac.in/media/files/moe.png" alt="" />
//                 </a></div>
//                 <div className="col"><a href="https://digitalindia.gov.in/" target="_blank">
//                   <img src="https://nitw.ac.in/media/files/dii.png" alt="" />
//                 </a></div>
//                 <div className="col"><a href="https://www.makeinindia.com/" target="_blank">
//                   <img src={`https://nitw.ac.in/media/files/mii.png`} alt="" />
//                   {/* <img src="https://www.nitrkl.ac.in/assets/images/footer/makeinindia.png" alt="" /> */}
//                 </a></div>

//               </div>
//               <div className="row">

//                 <div className="col"><a href="https://swachhbharat.mygov.in/" target="_blank">
//                   <img src="https://nitw.ac.in/media/files/swach_bharath.png" alt="" />
//                 </a></div>
//                 <div className="col"><a href="https://fitindia.gov.in/" target="_blank">
//                   <img src="https://nitw.ac.in/media/files/fit_india.png" alt="" />
//                 </a></div>
//                 <div className="col">
//                   <a href="https://nad.digilocker.gov.in/students" target="_blank">
//                     <img src="https://nitw.ac.in/media/files/digilocker_nad.png" alt="" />
//                   </a>
//                 </div>
//                 {/* <div className="col"><a href="https://data.gov.in/" target="_blank">
//                   <img src={`https://nitw.ac.in/media/files/data_gov_in.png`} alt="" />
//                 </a></div> */}

//                 {/* <div className="col"><a href="https://nad.digilocker.gov.in/students" target="_blank">
//                   <img src={`https://nitw.ac.in/media/files/nads.png`} alt="" />
//                 </a></div> */}
//               </div>

//             </div>


//             {/* <div className="col-sm-10 col-lg-2 mt-4">
// <ul>
//   <b style={{color: '#f1f1f1'}}>Connect With Us:</b>
//   <li>
//     <a href="/" className="text-black"><FontAwesome name="twitter" /> Twitter </a>
//   </li>
//   <li>
//     <a href="/" className="text-black"><FontAwesome name="facebook" /> Facebook </a>
//   </li>
//   <li>
//     <a href="/" className="text-black"> <FontAwesome name="instagram" /> Instagram</a>
//   </li>
// </ul>
// </div> */}
//           </div>
//           <div className="row text-center">
//             <p className="text-black offset-top-10">
//               <span>Copyright © 2023 | </span>
//               <span>
//               Centre for Digital Infrastructure and Services
//               </span>
//               {/* <span className="copyright-year" />
//   <span>.&nbsp;</span>
//   <span>All Rights Reserved</span> */}
//             </p>

//             {/* <div className="col-sm-10 col-lg-2 mt-4">
//               <ul>
//                 <b style={{color: '#f1f1f1'}}>Connect With Us:</b>
//                 <li>
//                   <a href="/" className="text-black"><FontAwesome name="twitter" /> Twitter </a>
//                 </li>
//                 <li>
//                   <a href="/" className="text-black"><FontAwesome name="facebook" /> Facebook </a>
//                 </li>
//                 <li>
//                   <a href="/" className="text-black"> <FontAwesome name="instagram" /> Instagram</a>
//                 </li>
//               </ul>
//             </div> */}
//           </div>
//         </div>
//       </div>
//     </footer >
//   );
// };

// export default Footer;


import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FaXTwitter } from "react-icons/fa6";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import "../components/Styles.css";
import { BaseUrl, MainServerUrl } from "../BasePath";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

const quickLinksList = [
  {
    title: "NIRF 2022",
    link: "/nirf/2022",
  },
  {
    title: "Annual Reports",
    link: "/page/?url=/IAR/2021",
  },
  {
    title: "Annual Accounts",
    link: "/",
  },
  {
    title: "Annual Budget",
    link: "/",
  },
  {
    title: "Acts and Statutes",
    link: "/",
  },
  {
    title: "Quality Policy",
    link: "/",
  },
  {
    title: "Strategic Plan",
    link: "/",
  },
];

const Footer = () => {
  return (
    <footer className="page-footer bg-madison">
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          borderBottom: "7px solid #f16127"
        }}
      >
        <ThemeProvider theme={theme}>

          {/* {quickLinksList.map((item) => (
            <a href={item.link}>
              <Typography
                variant="h6"
                fontWeight="bold"
                color="white"
                style={{ padding: "1rem 0.5rem" }}
              >
                {item.title}
              </Typography>
            </a>
          ))} */}
        </ThemeProvider>
      </div>
      {/* <div className="footer_list">
        <ul className="quick-links p-1" style={{background : ' linear-gradient(270deg, rgba(17,38,10,1) 11%, rgba(65,90,91,1) 30%, rgba(17,38,10,1) 85%, rgba(17,38,10,1) 100%)'}}>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Annual Reports
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Annual Accounts
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Annual Budget
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Acts and Statutes
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Quality Policy
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                ISO 9001
              </a>
            </li>
          </div>
          <div className="quick-link-item">
            <li>
              <a href="/" className="text-black">
                Strategic Plan
              </a>
            </li>
          </div>
        </ul>
      </div> */}
      {/* <div className="hr bg-gray-light" /> */}

      <div className="footer-section" style={{ backgroundColor: "#212C1E" }}>
        <div className="container-fluid section-xs block-after-divider">
          <div className="row row-50 justify-content-xl-between justify-content-sm-center">


            {/* <div className="col-sm-4 col-lg-4 col-xl-2 text-xl-start">
            <h6 className="d-flex justify-content-center fw-bold text-black">
              Programs
            </h6>
            <div className="text-subline" />
            <div className="offset-top-30">
              <ul className="list-marked list text-black">
                <li>
                  <Link
                    to={{
                      pathname: `/page/?url=/academics/heritage`,
                    }}
                    className="text-black"
                    onClick={() =>
                      (window.location.href = `${BaseUrl}/page/?url=/academics/heritage`)
                    }
                  >
                    Heritage
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/page/?url=/academics/euphrates`,
                    }}
                    className="text-black"
                    onClick={() =>
                      (window.location.href = `${BaseUrl}/page/?url=/academics/euphrates`)
                    }
                  >
                    Euphrates
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/page/?url=/academics/nmeict`,
                    }}
                    className="text-black"
                    onClick={() =>
                      (window.location.href = `${BaseUrl}/page/?url=/academics/nmeict`)
                    }
                  >
                    NMEICT PROJECTS
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}

            <div className="col-sm-6 col-lg-6 col-xl-7 text-xl-start">
              <h6 className="d-flex  fw-bold text-white">
                Quick Links
              </h6>
              <div className="text-subline" />
              <div className="offset-top-30">
                <div className="row">

                  {/* Column 0 */}
                  <div className="col">
                    <ul className="list-marked list text-white">


                      {/* <li>
                      <a
                        href="https://www.nitw.ac.in/page/?url=/AcademicServices/2021"

                        className="text-white"
                      // href=" https://www.nitw.ac.in/page/?url=/careersnitw/Jobs/"
                      // className="text-white"
                      >
                        Academic Services
                      </a>
                    </li>
                     */}

                      {/* <li>
                      <a href="https://www.nitw.ac.in/page/?url=/IAR/2021" className="text-white">
                        Annual Reports
                      </a>
                    </li> */}
                      {/* <li>
                        <a href="https://www.nitw.ac.in/page/?url=/AcademicTeam/2021" className="text-white">
                          Academic Cell activities
                        </a>
                      </li> */}

                      {/* <li>
                      <a href="https://www.nitw.ac.in/page/?url=/academicReport/2021" className="text-white">
                      Academic Report
                      </a>
                    </li>

                    <li>
                      <a href="https://www.nitw.ac.in/page/?url=/NITWCTL/tabs" className="text-white">
                      Centre for Training & Learning (CTL)
                      </a>
                    </li> */}

                      <li>
                        <Link
                          to={{
                            pathname: `/page/?url=/contact-us`,
                          }}
                          className="text-white"
                          onClick={() =>
                            (window.location.href = `${BaseUrl}/page/?url=/contact-us`)
                          }
                        >
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        {/* <a href="https://nitw.ac.in/api/static/files/Communication_Handbook_2023-8-1-13-9-38.pdf" className="text-white">
                      Communication Handbook
                      </a> */}
                        {/* <a href="https://nitw.ac.in/api/static/files/Communication_Handbook_2023-10-16-10-15-8.pdf" className="text-white">
                          Communication Handbook
                        </a> */}
                          {/* <a href="https://nitw.ac.in/api/static/files/Communication_Handbook_2024-4-18-12-9-54.pdf" className="text-white"> */}
                          <a href="https://nitw.ac.in/api/static/files/newcommunicationbook_2024-8-3-9-33-15.pdf" className="text-white">
                          Communication Handbook
                        </a>
                      </li>


                      <li>
                        <Link
                          to={{
                            pathname: `/page/?url=/covid19/nitw`,
                          }}
                          className="text-white"
                          onClick={() =>
                            (window.location.href = `${BaseUrl}/page/?url=/covid19/nitw`)
                          }
                        >
                          COVID-19 News
                        </Link>
                      </li>
                      {/* <li>	
                      <a href="https://www.nitw.ac.in/page/?url=/DeptwiseResearchFunds/DWRF" className="text-white">	
                      Dept wise Research Funds	
                      </a>	
                    </li> */}
                      {/* <li>
                        <a href="http://ir.library.nitw.ac.in:8080/jspui/" className="text-white">
                          Dspace@NITW
                        </a>
                      </li> */}


                      <li>
                        <a href="https://www.nitw.ac.in/path/?dept=/DSTVPSCOPE" className="text-white">
                          DST-VP-SCoPE
                        </a>
                      </li>


                      <li>
                        <a href="https://www.nitw.ac.in/page/?url=/academics/euphrates" className="text-white">
                          Euphrates
                        </a>
                      </li>




                      {/* <li>
                      <a href="https://oldweb.nitw.ac.in/eict/" className="text-white">
                        E&amp;ICT Academy
                      </a>
                    </li> 
                    <li>
                      <a href="http://172.20.0.248:9000/" className="text-white">
                      E-Mail ID Registration Forms
                      </a>
                    </li>*/}




                      <li>
                        <a href="https://www.nitw.ac.in/page/?url=/EMERGENCY/ECN/" className="text-white">
                          Emergency Contact Numbers
                        </a>
                      </li>

                      {/*                     
                    <li>
                      <a
                        href="https://www.nitw.ac.in/page/?url=/AcademicServices/2021"
                        className="text-white"
                      >
                        Academic Services
                      </a>
                    </li> */}







                      {/* <li>
                      <Link
                        to={{
                          pathname: `/page/?url=/academics/euphrates`,
                        }}
                        className="text-white"
                        onClick={() =>
                          (window.location.href = `${BaseUrl}/page/?url=/academics/euphrates`)
                        }
                      >
                        Euphrates
                      </Link>
                    </li> */}




                      {/* <li>
                    <a href="https://nitw.ac.in/page/?url=/facilities/transport" target="_blank" rel="noreferrer" className="text-white">
                    Motor Transport
                      </a>
                    </li> */}
                   
                      {/* <li>
                        <a
                          href="https://wsdc.nitw.ac.in/facultynew/"
                          className="text-white"
                        >
                          Faculty Portal
                        </a>
                      </li> */}




                    </ul>
                  </div>

                  {/* Column 1 */}

                  <div className="col">
                    <ul className="list-marked list text-white">
                    <li>
                        <a href="https://nitw.irins.org/" className="text-white">
                          Faculty Information System
                        </a>
                      </li>

                      {/* <li>
                      <Link
                        to={{
                          pathname: `/page/?url=/academics/heritage`,
                        }}
                        className="text-white"
                        onClick={() =>
                          (window.location.href = `${BaseUrl}/page/?url=/academics/heritage`)
                        }
                      >
                        Heritage
                      </Link>
                    </li> */}
                      {/* <li>
                      <a href="https://nitw.ac.in/api/static/files/List_of_Closed_Holidays_for_the_year_2023_2023-4-3-11-39-40.pdf" target="_blank" rel="noreferrer" className="text-white">
                        Holidays
                      </a>
                    </li> */}
                      <li>
                        <a href="https://nitw.ac.in/api/static/files/Holidays_list_2024-1-1-18-22-22.pdf" target="_blank" rel="noreferrer" className="text-white">
                          Holidays
                        </a>
                      </li>
                      <li>
                        <a href="http://intranet.nitw.ac.in/" className="text-white">
                          Intranet @NITW Notices
                        </a>
                      </li>

                      {/* <li>
                        <a href="https://nitw.ac.in/itep/" className="text-white">
                          Integrated Teacher Education Programme (ITEP)
                        </a>
                      </li> */}

                      <li>
                        <a
                          // href="https://nitw.ac.in/api/static/files/ICC_members_2024-5-12-11-50-45.pdf"
                          href="https://nitw.ac.in/icc"
                          className="text-white"
                        >
                          Internal Complaints Committee
                        </a>
                        {/* <a
                          href="https://nitw.ac.in/page/?url=/ICC/page"
                          className="text-white"
                        >
                          Internal Complaints Committee
                        </a> */}
                      </li>


                      <li>
                        <a href="https://nitw.ac.in/path/?dept=/NITW_FORMS" target="_blank" rel="noreferrer" className="text-white">
                          Institute Forms (Intranet)
                        </a>
                      </li>
                      <li>
                        <a href="https://nitw.ac.in/path/?dept=/NIRF" className="text-white">
                          NIRF
                        </a>
                      </li>
                      {/* <li>
                      <a href="https://www.nitw.ac.in/nirf/2022" className="text-white">
                        NIRF 2022
                      </a>
                    </li> */}
                      {/* <li>
                      <a href="https://nitw.ac.in/page/?url=/NITCouncil/NITWC" className="text-white">
                        NIT Council
                      </a>
                    </li> */}



                     

                      {/* <li>
                      <a
                        href={`${MainServerUrl}/api/static/files/handbook_on_sexual_harassment_of_women_at_workplace_2022-0-19-13-59-21.pdf`}
                        className="text-white"
                      >
                        Handbook on Sexual Harassment of Women at Workplace
                      </a>
                    </li> */}

                      {/* <li>
                      <Link
                        to={{
                          pathname: `/page/?url=/newsletter`,
                        }}
                        className="text-white"
                        onClick={() =>
                          (window.location.href = `${BaseUrl}/page/?url=/newsletter`)
                        }
                      >
                        Newsletters and Academic Reports
                      </Link>
                    </li> */}







                      {/* <li>	
                      <a href="https://nitw.ac.in/page/?url=/emu/2021" className="text-white">	
                      Engineering and Maintenance Unit (E & MU)	
                      </a>	
                    </li> */}
                      {/* <li>	
                      <a href="https://nitw.ac.in/cii/" className="text-white">	
                      Center for Innovation and Incubation (CII)	
                      </a>	
                    </li> */}
                      {/* <li>
                      <a href="http://oldweb.nitw.ac.in/" target="_blank" rel="noreferrer" className="text-white">
                        Old Website
                      </a>
                    </li> */}
                     
                      {/* <li>
                        <a
                          href="https://www.nitw.ac.in/page/?url=/Announcements/2021"
                          className="text-white"
                        >
                          Old Announcements (Academic)
                        </a>
                      </li> */}


                    </ul>
                  </div>

                  {/* column 2 */}


                  <div className="col">
                    <ul className="list-marked list text-white">
                      {/* <li>
                        <a
                          href="https://www.nitw.ac.in/page/?url=/FeeStructure/FS"
                          className="text-white"
                        >
                          Old Fee structure
                        </a>
                      </li> */}
                       <li>
                        <Link
                          to={{
                            pathname: `/page/?url=/academics/nmeict`,
                          }}
                          className="text-white"
                          onClick={() =>
                            (window.location.href = `${BaseUrl}/page/?url=/academics/nmeict`)
                          }
                        >
                          NMEICT PROJECTS
                        </Link>
                      </li>
                       <li>
                        <a href="https://nitw.ac.in/page/?url=/NAD/2021" className="text-white">
                          NAD
                        </a>
                      </li>
                      <li>
                        <a href="https://www.nitw.ac.in/oldpandd" className="text-white">
                         Other Portals and Documents
                        </a>
                      </li>




                      {/* <li>
                        <a
          
                          href={`${MainServerUrl}/Careers/`}

                          className="text-white"
                       
                        >
                          Opportunities (Careers / Jobs)
                        </a>
                      </li> */}
                      {/* <li>
                    <a href="https://nitw.ac.in/page/?url=/Patents/patents2022" target="_blank" rel="noreferrer" className="text-white">
                    Patents
                      </a>
                    </li> */}

{/* 
                      <li>
                        <a href="https://nitw.ac.in/path/?dept=/Portals" target="_blank" rel="noreferrer" className="text-white">
                          Purchase SEC
                        </a>
                      </li> */}


                      {/* <li>
                      <a href="https://www.nitw.ac.in/raa/" className="text-white">
                        RAA
                      </a>
                    </li> */}



                      <li>
                        <a href="https://www.nitw.ac.in/page/?url=/administration/rightToInformation" className="text-white">
                          RTI Act
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://nitw.ac.in/page/?url=/Women_cell/wcnitw"
                          className="text-white"
                        >
                         Women cell
                        </a>
                      </li>


                      {/* <li>
                        <a
                          href="https://erp.nitw.ac.in/"
                          className="text-white"
                        >
                          SMILE ERP
                        </a>
                      </li> */}

                      {/* <li>
                      <a href='/team-member' className="text-white">Student Developers Team</a>
                    </li> */}

                      {/* <li>
                        <a
                          href={`https://wsdc.nitw.ac.in/`}
                          className="text-white"
                        >
                          Student Portal
                        </a>
                      </li> */}







                      {/* <li>
                      <a href="https://nitw.ac.in/api/static/files/Organization_Chart_2023-4-13-15-57-42.pdf" className="text-white">
                        Organization Chart
                      </a>
                    </li> */}


                      {/* <li>
                        <a href="https://nitw.ac.in/teqip/" className="text-white">
                          TEQIP-III
                        </a>
                      </li> */}
                       {/* <a href="https://www.nitw.ac.in/page/?url=/UBA/Unnat_Bharat_Abhiyaan" className="text-white">	
                      Unnat Bharat Abhiyaan	
                      </a>	 */}

                      {/* <li>
                       
                        <a href="https://www.nitw.ac.in/uba" className="text-white">
                          Unnat Bharat Abhiyaan
                        </a>
                      </li>
                      <li>
                        <a href="https://www.easytourz.com/BT-EmabedTour/all/b5f17aa2b040a89f" className="text-white">
                          Virtual Tour
                        </a>
                      </li> */}
                      {/* <li>
                      <a href="https://oldweb.nitw.ac.in/media/uploads/2022/06/24/statutory_policies.pdf" className="text-white">
                      Statutory Policies
                      </a>
                    </li> */}

                      {/* <li>
                      <a href="https://www.nitw.ac.in/tlc/" className="text-white">
                      Teaching Learning Centre (TLC)
                      </a>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-lg-12 col-xl-5 text-xl-start justify-content-center d-flex">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15179.588466037536!2d79.5308386!3d17.983523!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1b2c37fcb8fefce!2sNational%20Institute%20of%20Technology%2C%20Warangal%2C%20Telangana!5e0!3m2!1sen!2sin!4v1632005137818!5m2!1sen!2sin"
                width={680}
                height={350}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                title="MAP"
              />
            </div>
          </div>
        </div>
      </div>



      {/* <div className=" pb-4" style={{ backgroundColor: "#E1D29A", color: "black" }}> */}
      <div style={{
        // backgroundColor: "rgb(245, 247, 250)",
        backgroundColor: "#c5f2e0",
        // backgroundImage: "url('https://nitw.ac.in/api/static/files/ribbon_light_strip_brown_2023-6-15-11-41-47.jpg')",
        // backgroundImage: "url('https://nitw.ac.in/api/static/files/green_bg_2024-2-29-18-13-29.jpg')",
        backgroundImage: "url('https://nitw.ac.in/api/static/files/BG4_2024-5-18-17-52-22.jpg')",
        backgroundSize: "cover",
        borderTop: "7px solid #f16127",
        borderBottom: "7px solid #f16127",
        padding: "0 65px",
        color: "white"
      }}>
        <div className="container pt-3">
          <div className="row row-20 justify-content-sm-around offset-top-30 text-lg-start">




            <div className="col-sm-10 justify-content-center col-lg-3 col-xl-4 ">


              <h6 className="fw-bold" style={{color:"#010080"}}>Contact us</h6>
              <div style={{ background: "black" }} className="text-subline text-black"></div>
              <div className="offset-top-30">

              </div>

              <div className="flex-row justify-content-center">
                <ul stlye={{
                  display: "flex",
                  justifyContent: "center"
                }} className="list-unstyled contact-info list">

                  <li>
                    <div className="unit flex-row ">

                      <div className="unit-body">
                        <img
                          className="img-responsive"
                          src={process.env.PUBLIC_URL + "/images/logo-170x172.png"}

                          alt={"nitw footer logo"}
                          width={"58%"}
                        />
                      </div>
                    </div>
                  </li>



                  <li>
                    <div className="unit flex-row  unit-spacing-xs">
                      <div className="unit-left">
                        <span className="icon mdi mdi-phone text-middle icon-xs" style={{color:"#f16127"}}></span>
                      </div>
                      <div className="unit-body">
                        <a className="" style={{color:"#010080"}} href="tel:#">
                          +91-870-2459191,
                        </a>{" "}
                        <a
                          className="d-block d-lg-inline-block" style={{color:"#010080"}}
                          href="tel:#"
                        >
                          +91-870-2459547
                        </a>
                      </div>
                    </div>
                  </li>



                  <li className="">
                    <div className="unit flex-row unit-spacing-xs">
                      <div className="unit-left">
                        <span className="icon mdi mdi-map-marker text-middle icon-xs" style={{color:"#f16127"}}></span>
                      </div>
                      <div className="unit-body text-start">
                        <a style={{color:"#010080"}} href="/">
                          National Institute of Technology, <br />Warangal - 506004,
                          TS, INDIA
                        </a>
                      </div>
                    </div>
                  </li>



                  <li className="">
                    <div className="unit flex-row unit-spacing-xs">
                      <div className="unit-left">
                        <span className="icon mdi mdi-email-open text-middle icon-xs" style={{color:"#f16127"}}></span>
                      </div>
                      <div className="unit-body">
                        <a style={{color:"#010080"}} href="mailto:registrar@nitw.ac.in">
                          registrar@nitw.ac.in
                        </a>
                      </div>
                    </div>
                  </li>






                </ul>






                <div className=" offset-top-20 ">
                  <ul className=" list-inline list-inline-sm list-inline-madison">
                    <li>
                    <a
                              href="https://www.facebook.com/NITWarangalOfficial/"
                              target="_blank"
                              style={{opacity: 80}}
                              className="icon icon-xxs fa fa-facebook icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                    </li>
                    <li>
                    {/* <FaXTwitter/> */}
                    <a
                              style={{ opacity: 80 }}
                              href="https://x.com/warangal_nit"
                              className="icon icon-xxs fa fa-twitter icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                    </li>
                    <li>
                    <a
                              style={{opacity: 80,color:"red"}}
                              target="_blank"
                              href="https://www.youtube.com/@wsdcnitwarangal1954"
                              className="icon icon-xxs fa fa-youtube-play icon-circle icon-gray-light-filled"
                            >
                              {" "}
                            </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/school/national-institute-of-technology-warangal/posts/?feedView=all"
                        className="icon icon-xxs fa fa-linkedin icon-circle icon-gray-light-filled"
                      >
                        {" "}
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="/"
                        className="icon icon-xxs fa fa-google icon-circle icon-gray-light-filled"
                      >
                        {" "}
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://www.instagram.com/nitwarangalofficial/"
                        target="_blank"
                        className="icon icon-xxs fa fa-instagram icon-circle icon-gray-light-filled"
                      >
                        {" "}
                      </a>
                    </li>
                  </ul>
                </div>







              </div>

            </div>






            <div className="col-sm-10 col-lg-4 col-xl-8  px-sm-20">
              <h6 className="fw-bold" style={{color:"#010080"}}>National Portals</h6>
              <div style={{ background: "black" }} className="text-subline "></div>

              <div className="row">


                <div className="col-lg-4 col-6 thumb" ><a href="https://www.education.gov.in/" target="_blank">
                  <img src=
                    "https://nitw.ac.in/media/files/moe.png" alt="" />
                </a></div>


                <div className="col-lg-4 col-6 thumb" ><a href="https://digitalindia.gov.in/" target="_blank">
                  <img src="https://nitw.ac.in/media/files/dii.png" alt="" />
                </a></div>



                <div className="col-lg-4 col-6 thumb"><a href="https://www.makeinindia.com/" target="_blank">
                  <img src={`https://nitw.ac.in/media/files/mii.png`} alt="" />
                  {/* <img src="https://www.nitrkl.ac.in/assets/images/footer/makeinindia.png" alt="" /> */}
                </a></div>



                <div className="col-lg-4 col-6 thumb"><a href="https://swachhbharat.mygov.in/" target="_blank">
                  <img src="https://nitw.ac.in/media/files/swach_bharath.png" alt="" />
                </a></div>



                <div className="col-lg-4 col-6 thumb"><a href="https://fitindia.gov.in/" target="_blank">
                  <img src="https://nitw.ac.in/media/files/fit_india.png" alt="" />
                </a></div>


                <div className="col-lg-4 col-6 thumb">
                  <a href="https://nad.digilocker.gov.in/students" target="_blank">
                    <img src="https://nitw.ac.in/media/files/digilocker_nad.png" alt="" />
                  </a>
                </div>


                {/* <div className="col"><a href="https://data.gov.in/" target="_blank">
                  <img src={`https://nitw.ac.in/media/files/data_gov_in.png`} alt="" />
                </a></div> */}

                {/* <div className="col"><a href="https://nad.digilocker.gov.in/students" target="_blank">
                  <img src={`https://nitw.ac.in/media/files/nads.png`} alt="" />
                </a></div> */}
              </div>

            </div>


            {/* <div className="col-sm-10 col-lg-2 mt-4">
<ul>
  <b style={{color: '#f1f1f1'}}>Connect With Us:</b>
  <li>
    <a href="/" className="text-black"><FontAwesome name="twitter" /> Twitter </a>
  </li>
  <li>
    <a href="/" className="text-black"><FontAwesome name="facebook" /> Facebook </a>
  </li>
  <li>
    <a href="/" className="text-black"> <FontAwesome name="instagram" /> Instagram</a>
  </li>
</ul>
</div> */}
          </div>


          <div className="row text-center">
            <p className="offset-top-10" style={{color:"#010080"}}>
              <span>Copyright © 2024 | </span>
              <span>
               <a href="https://nitw.ac.in/cdis/" target="_self" style={{color:"#010080"}}> Centre for Digital Infrastructure and Services</a>
              </span>
              {/* <span className="copyright-year" />
  <span>.&nbsp;</span>
  <span>All Rights Reserved</span> */}
            </p>

            {/* <div className="col-sm-10 col-lg-2 mt-4">
              <ul>
                <b style={{color: '#f1f1f1'}}>Connect With Us:</b>
                <li>
                  <a href="/" className="text-black"><FontAwesome name="twitter" /> Twitter </a>
                </li>
                <li>
                  <a href="/" className="text-black"><FontAwesome name="facebook" /> Facebook </a>
                </li>
                <li>
                  <a href="/" className="text-black"> <FontAwesome name="instagram" /> Instagram</a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>






    </footer >
  );
};

export default Footer;






