import { useState } from "react";
import '../css/cseDeptStyle.css';
import { CommitteData } from '../constants/CommitteData';


const CommittePage = () => {
  const [activeTab, setActiveTab] = useState("Convocation");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId);
  };

  const renderTable = (content) => (
    <table className="table table-bordered table-responsive">
      <thead>
        <tr>
          <th>Role</th>
          <th>Name</th>
          <th>Designation/Department</th>
        </tr>
      </thead>
      <tbody>
        {content.map((person, index) => (
          <tr key={index}>
            <td>{person.role}</td>
            <td>{person.name}</td>
            <td>{person.department}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderContent = (tab) => {
    if (tab.multiple === "yes") {
      // Render subcommittees
      return tab.content.map((subcommittee, index) => (
        <div key={index}>
          <h4>{subcommittee.subcommittename}</h4>
          {renderTable(subcommittee.subcommittecontent)}
        </div>
      ));
    } else {
      // Render a single committee
      return renderTable(tab.content);
    }
  };

  return (
    <>
      <div className="dept-wrapper">
        <div className="container">
          <section className="py-4 header">
            <header>
             <center><h2 style={{ textDecoration: "underline" }}>Institute Committees</h2></center>
            </header>
            <div className="row">
              <div className="col-md-3">
                <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  {CommitteData.map((tab) => (
                    <a
                      key={tab.id}
                      className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                      id={`${tab.id}-tab`}
                      onClick={() => handleTabClick(tab.id)}
                      role="tab"
                      aria-selected={activeTab === tab.id ? "true" : "false"}
                      style={{
                        backgroundColor: activeTab === tab.id ? "#c20315" : "#00FFFF",
                      }}
                    >
                      <span>{tab.title}</span>
                    </a>
                  ))}
                </div>
              </div>

              <div className="col-md-9">
                <div className="tab-content" id="v-pills-tabContent">
                  {CommitteData.map((tab) => (
                    <div
                      key={tab.id}
                      className={`tab-pane ${activeTab === tab.id ? "show active" : "fade"}`}
                      id={tab.id}
                      role="tabpanel"
                    >
                      {renderContent(tab)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CommittePage;
