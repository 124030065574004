import React from "react";
import { Grid, Typography } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

import "../components/Styles.css";

let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);

export default function ProgressBar() {
  return (
    <ThemeProvider theme={theme}>
      <Grid style={{ backgroundColor: "rgb(168,237,209,0.15)", padding: "2vw" }}>
        <div style={{ width: "80vw", margin: "auto" }}>
          <Typography
            variant="h3"
            fontWeight="bold"
            style={{ textAlign: "center", color: "#b80924" }}
          >
            Our Numbers
          </Typography>
          <hr className="divider bg-madison" />

          <Grid container direction="row" md={12} marginTop="3vh">
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-newspaper"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  9488
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Publications
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-certificate"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  125
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Patents Filed
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-certificate"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  50
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Patent Granted
              </Typography>
            </Grid>


            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-format-quote"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                 71288
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Citations
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-account-switch"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  89
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                MOUs
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-library"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  1000
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                PhD Produced
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-account-multiple-plus"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  310
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Faculties
              </Typography>
            </Grid>


            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-account-multiple"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  314
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Staffs
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-account"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  7000
                </span>
                <span className="fw-bold text-primary ">+</span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Students
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-home"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  13
                </span>
                <span className="fw-bold text-primary "></span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Departments
              </Typography>
            </Grid>
{/* additional data */}
            {/* <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-home"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  29
                </span>
                <span className="fw-bold text-primary "></span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                SPARC Projects Sanctioned
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-home"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  10
                </span>
                <span className="fw-bold text-primary "></span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                SPARC Projects Completed
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-home"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  14
                </span>
                <span className="fw-bold text-primary "></span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                GIAN Courses Sanctioned
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="column"
              md={2.4}
              xs={6}
              alignContent="center"
            >
              <span
                style={{ margin: "2vh auto 0 auto" }}
                className="icon icon-lg icon-outlined text-madison mdi mdi-home"
              />
              <h3 style={{ textAlign: "center" }}>
                <span
                  className="counter fw-bold text-primary "
                  data-speed={1300}
                >
                  290
                </span>
                <span className="fw-bold text-primary "></span>
              </h3>
              <Typography
                variant="h6"
                fontWeight="bold"
                style={{ textAlign: "center" }}
              >
                Sponsored Projects Sanctioned
              </Typography>
            </Grid> */}


          </Grid>
        </div>
      </Grid>
    </ThemeProvider>
  );
}
