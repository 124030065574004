import { useState } from "react";
import '../css/cseDeptStyle.css';
const PEDeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img
                            src="https://nitw.ac.in/api/static/files/csa_2024-7-4-18-39-46.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                {/* <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')}  role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a> */}
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>


                                {/* <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                     role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a> */}
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Activities </span>
                                </a>
                                {/* <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                     role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a> */}
                                {/* <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a> */}




                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span> Infrastructure and Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>

                            </div>
                            <div className="head_of_dept"> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404"
                                target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg" alt="" className="img-fluid" width="190" height="220" />
                                <h6>Prof. P. Ravi Kumar</h6>
                            </a>
                                <p className="justified-text"> Head of the Sports Activity <br /> <a href="mailto:ped_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>ped_hod@nitw.ac.in</a> <br /> 9491085287 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        {/* <p className="justified-text">Welcome to the Department of Physical Education </p> */}

                                        <p className="justified-text">The centre for sports activities of NIT Warangal has a dedicated team of people working hard to increase the overall enthusiasm for sports in the institute and the smooth conduction of the tournaments that occur year round. This system covers every individual in the campus, and ensures that good talent is always spotted.</p>

                                        <p className="justified-text">The centre for sports activities will organize sports activities in NITW in recognized events, to encourage sportsmanship generally, and to promote friendly relations among the students of the NITs and to foster a close association among them. </p>

                                        <p className="justified-text">It is hoped that these activities would result in greater harmony, health-consciousness, brotherhood, goodwill, work sincerity; sporting spirit, open mindedness, etc. among NIT students and staff. </p>

                                        <p className="justified-text">The Sports activities will help the Institute to identify talented and dedicated sports persons from the students.</p>

                                        <p className="justified-text">Apart from playing regularly at the courts, students at NITW are given opportunity to show their flair and class in various tournaments. Talented players are given opportunity to qualify for entering the NITW ´s Inter NITW teams.</p>
                                        <p className="justified-text">For the freshmen at NITW, a Freshmen Kabaddi, Ball Badminton, Tennis and Volleyball open is held to give them opportunity to show their worth, and year after year, fresh young talented players are identified who are groomed to eventually represent NITW at the Annual Inter NIT Sports Meet.</p>

                                        <p className="justified-text">The Inter-NIT Sports Meet, the biggest stage for sportsmen from NIT, is held annually during first and second semesters. it is a three-day extravaganza where students get to keep alive the spirit of Citius, Altius, Fortius. The NIT performing best in the Sports Meet  wins the General Championship. With the birth of several new NITs, the talent pool has increased dramatically, resulting in a very closely fought General Championship. </p>


                                        <p className="justified-text">We will strive to raise the standard of sports in the NITs and to work for the development of character and values through sports amongst the staff of the NITs</p>

                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>
                                            <p className="  mb-2">To promote Fitness, health and wellness of the Campus Community.</p>

                                            <p className="  mb-2">To improve the quality of life as a whole.</p>
                                            <p className="  mb-2">To increase participation amongst young boys and girls.</p>
                                            <p className="  mb-2">To develop Sportsmanship and fair competition.</p>


                                            {/* <img src="CSEDeptAssets/images/cse.jpg" alt=""
                                                    className="img-fluid" /> */}
                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">To provide ample opportunities in diversified areas such as physical activity, exercise, sports and competitions.</p>
                                            <p className="  mb-2">Commitment to encourage, develop and enable opportunities for participation in Sports activities for the campus community.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D</h3>
                                                        <p className="justified-text">
                                                            The department offers full time and part time Ph.D programmes in Mechanical Engineering. The admission to Ph.D programme takes place in both odd and even semesters of an academic year. The notification for the Ph.D admissions is advertised in the institute website as well as in leading national news papers.  The minimum qualification and other details for the aspiring students  can be obtained from the advertisement .  The candidates are selected through a written exam and/or interview. All the regular Ph.D students are paid stipend by the Institute. The department has been recognized for Ph.D programme under QIP.
                                                        </p>

                                                       
                                                    </div>
                                                    {/* <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>




                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    <div className="row laboratory-facilities">
                                        <h3 className="">INFRA STRUCTURE INDOOR FACILITIES</h3>

                                        <p className="justified-text">



                                        </p>

                                        <h5 className="">IN THE STADIUM</h5>




                                        <p className="justified-text">

                                            Multipurpose Ground Facility with flood lights which accommodates standard 400 Mts. Track with Flood lights <br>
                                            </br>

                                            Kho-Kho Ground, Football, Cricket fields and with 2 artificial turf net practising cricket pitches with Flood lights

                                            <br>
                                            </br>

                                            Galleries of sufficient seating capacity in the stadium and also a walking track.   <br>
                                            </br>

                                            2 Synthetic Top surface Basketball Courts with flood lights and sitting galleries. <br>
                                            </br>

                                            2 Volleyball Courts 5 Tennis Courts (2 Synthetic, 1 Concrete and 2 Clay Courts), <br>
                                            </br>
                                            2 Ball Badminton Courts with Flood lights



                                        </p>


                                        <h3 className="">INFRA STRUCTURE FACILITIES IN AND AROUND HOSTEL BLOCKS </h3>

                                        <p className="justified-text">

                                            2 Shuttle courts and 1 Tennikoit court inside the Ultra Mega hostel.
                                            <br>
                                            </br>
                                            At Ultra Mega hostel 2 clay Volleyball Courts and Basketball court near the Ultra Mega Hostel.    <br>
                                            </br>

                                            Multipurpose Ground with flood lights to play any sort of sports and games (rear side of the ultra-mega hostel).
                                            <br>
                                            </br>


                                            Gym facilities 2 common halls in the mega hostels and 2 rooms in the ultra-mega block 1 hall in the DASA hostel.
                                            <br>
                                            </br>     15 Table Tennis tables were placed in different hostels as detailed below: <br>
                                            </br>
                                            3 in the mega hostel
                                            <br>
                                            </br>   2 T.T Tables in the DASA Hostel, 14th Block, 13th Block, 12th Block 11th Block, 2nd block and 1st block. <br>
                                            </br>
                                            5 Badminton courts inside Chemical & Metallurgical Building and 3 Badminton Courts in Science Block

                                        </p>



                                        <h3 className="">INFRA STRUCTURE FACILITIES IN GIRLS HOSTELS  </h3>

                                        <p className="justified-text">

                                            1 Concrete Basketball Court proposed to upgrade with Synthetic top and flood light facility. <br></br>

                                            1 Volleyball Court <br></br>

                                            2 Gym rooms in the lady’s hostels <br></br>

                                            1 Tennikoit Court <br></br>


                                            2 Table Tennis Tables <br></br>



                                        </p>


                                        <h3 className="">SPORTS FACILITY AND OTHER FACILITY FOR FACULTY, STAFF AND THEIR FAMILY MEMBERS  </h3>


                                        <p className="justified-text">


                                            Multi Gym facility in Faculty club, Billiards, Table Tennis, Chess, Carrom. <br></br>

                                            Separate Indoor Badminton court in Faculty club. <br></br>

                                            Separate Volleyball court in Faculty club. <br></br>

                                            Children's skating area and park.<br></br>

                                        </p>
                                        <h3 className="">Fitness Hall</h3>

                                        <img src="https://nitw.ac.in/api/static/files/PED_Gym_center_2023-10-13-17-18-17.png" alt="" className="img-fluid mb-5" />


                                        <h3 className="">Volley Ball Court</h3>
                                        <img src="https://nitw.ac.in/api/static/files/Volley_Ball_Court_2023-10-13-17-26-23.png" alt="" className="img-fluid mb-5" />

                                        <h3 className="">Badminton Court</h3>
                                        <img src="https://nitw.ac.in/api/static/files/Badminton_Court_2023-10-13-17-31-15.png" alt="" className="img-fluid mb-5" />

                                        <h3 className="">Tennis Court</h3>
                                        <img src=" https://nitw.ac.in/api/static/files/Tennis_Court_2023-10-13-17-33-41.png" alt="" className="img-fluid mb-5" />




                                        <h3 className="">Basketball Court</h3>
                                        <img src="https://nitw.ac.in/api/static/files/Basketball_Court_2023-10-13-18-13-38.png" alt="" className="img-fluid mb-5" />

                                        <h3 className="">Ground</h3>
                                        <img src="https://nitw.ac.in/api/static/files/Ground_2023-10-13-18-16-57.png" alt="" className="img-fluid mb-5" />


                                    </div>
                                </div>






                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>




                                        {/* <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab" 
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty</a>  */}
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>SAS Officer</a>

                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>





                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            <section id="profile-info">

                                                {/* <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16810"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16810/16810.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16810"
                                                                        target="_blank">Prof. Dhyanithi R</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969528 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rdaya@nitw.ac.in ">rdaya@nitw.ac.in </a> </p>
                                                                    <p className="justified-text">-</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}



{/* 
                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16405"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16405/16405.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16405"
                                                                        target="_blank">Prof. Madhusudhan Reddy P</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9440027928, 8332969526 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:madhup@nitw.ac.in ">madhup@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">volleyball</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}




                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16404/16404.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16404"
                                                                        target="_blank">Prof. Ravi Kumar P</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969518 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:prk@nitw.ac.in ">prk@nitw.ac.in  </a> </p>
                                                                    <p className="justified-text">volleyball</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </section>




                                        </div>



                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">
                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_A._Jagadeesh_Kumar_2023-10-6-12-52-11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri A. Jagadeesh Kumar</h6> <span>Senior Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969564 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jagadeesh@nitw.ac.in">jagadeesh@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Bharat_Singh_2023-10-6-12-55-3.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Shri Bharat Singh</h6>
                                                                <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8004444037 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bharats@nitw.ac.in">bharats@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                      
 */}

                                            </div>

                                        </div>



                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab" >
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}
                     <section id="profile-info">

                                            <div className="row phd-std-row">

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17085"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17085/17085.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17085"
                                                                        target="_blank">Dr. Prasanth P</a> </h5>
                                                                    <span>Senior Students Activity and Sports Officer</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9881096695<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ssaso@nitw.ac.in  ">ssaso@nitw.ac.in   </a> </p>
                                                                    <p className="justified-text">Exercise Physiology, Sports Management, Measurement and Evaluations, Sports Sociology, Athletics, Football and Badminton</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>














                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17086"
                                                            target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17086/17086.jpg" alt=""
                                                                className="img-fluid" width="150" height="180" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17086"
                                                                        target="_blank">Dr. Devasoth Hari</a> </h5>
                                                                    <span>Students Activity and Sports Officer</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9848251293<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:hd_saso@nitw.ac.in   ">hd_saso@nitw.ac.in    </a> </p>
                                                                    <p className="justified-text">Physical Fitness and Wellness, Scientific Principle of Sports Training, Test and Measurement in Evaluation in Physical education, Athletics & Kho-Kho</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>






                                            </div>
                                            </section>



                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    <div className="row std-society">
                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16906"
                                                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16906"
                                                    target="_blank">
                                                  <h6>Prof. K. S. Rajmohan</h6> <span> Professor In-Charge<br></br> Chemical Engineering Students’ Society (ChESS) and IIChE Student Chapter </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 9994646610 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:rajmohan@nitw.ac.in "
                                                            style={{ color: "#808080" }}>rajmohan@nitw.ac.in </a> </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj"
                                                    target="_blank">
                                                    <h6>Prof. Raghu Raja Pandiyan K</h6> <span> Treasurer <br></br> Chemical Engineering Students’ Society (ChESS) and IIChE Student Chapter </span>
                                                    <hr />
                                                </a>
                                                     <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8002486897 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:raghuraj@nitw.ac.in"
                                                            style={{ color: "#808080" }}>raghuraj@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div> */}



                                        <h3 className="">ACADEMIC ACTIVITY</h3>

                                        <p className="justified-text">

                                            The Department conducts Extra Academic Activity (EAA) programme as a mandate course for the first year students.

                                        </p>

{/* 
                                        <h3 className="">Ph.D PROGRAMME </h3>

                                        <p className="justified-text">

                                            Prof. R. Dhayanithi and Prof. P. Ramesh Reddy got their Ph.D’S in Physical Education from the Department of Physical Education, NITW during the year 1999-2003 under the supervision of Dr.P.Ravi Kumar.
                                        </p> */}


                                        <h3 className="">ROUTINE PROGRAMME OF CENTRE FOR SPORTS ACTIVITIES </h3>

                                        <p className="justified-text">

                                            Facilitating the student and staff community for the utilization of the available infrastructure facility in the institute to be physically fit and wellbeing.
                                            <br></br>
                                            Conducts International Yoga Day 21 June every year in a grand scale.
                                            <br></br>
                                            Organises Independence Day and Republic Day in a befitting manner with number of Physical Education activities.
                                            <br></br>
                                            To prepare institute teams towards participation in the Extramural Competitions like Inter NIT or Inter Universities and other open tournaments in various sports and games.
                                            <br></br>
                                            Conducting Annual Sports Day.




                                        </p>


                                        <h3 className="">ORGANISATION OF TOURNAMENTS  </h3>

                                        <p className="justified-text">

                                            Conducting of Intramural competitions for student’s community
                                            <br></br>

                                            Conducting of Intramural competitions for student’s community
                                            <br></br>
                                            Organising Extramural competitions like Inter NIT or Inter Universities and other local tournaments.
                                            <br></br>

                                            Friendly matches with other institutions for the students.
                                            <br></br>
                                            Conducting fitness activities for the staff like Yoga, Meditation, Etc.
                                            <br></br>
                                            Helping to conduct sports activities of various Departments & Associations.

                                        </p>















                                    </div>
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


                                    <div className="row notable-alumni">
                                        {/* <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na1.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Dr. Pushmeet Kohli</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Principal Scientist and Team Lead, DeepMind,
                                                        London, UK
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card "> <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Pavithra Prabhakar</h6>

                                                     <p className="justified-text"><b>Batch :</b> B.Tech (CSE) (2004)<br />
                                                        <b>Present Position :</b> Professor, Kansas State University, Manhattan,
                                                        United States
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/vrajanna.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>V Rajanna</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> GM & VP, TCS- Hyderabad


                                                    </p>

                                           
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/aarthi.jpg  " alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Aarthi Subramanyan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Group Chief Digital Officer, Tata Sons Private
                                                        Limited, Mumbai
                                                    </p>

                                              
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/murali.jpg" alt="" className="img-fluid" />
                                                <div className="data">
                                                    <h6>Prof. Murali Annavaram</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Professor at University of Southern California

                                                    </p>

                                                
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Sridhar Muppala</h6>



                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO, Silicon Valley

                                                    </p>

                                               
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Movva Ramu</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Microsoft Research Centre, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>K V Sridhar</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CEO Analytics CA, USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>




                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Jitendra Lakotia</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Former VP of Cognos. USA
                                                    </p>

                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Amarnadh Reddy</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> CTO, CGG, Hyderabad
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}


                                        {/* <div className="col-md-3 col-6">
                                            <div className="card ">
                                                <img src="CSEDeptAssets/images/na2.jpg" alt="" className="img-fluid"/>
                                                <div className="data">
                                                    <h6>Feroz khan</h6>
                                                     <p className="justified-text">
                                                        <b>Present Position :</b> Dy. Director, SAP Labs

                                                    </p>

                                                </div>
                                            </div>
                                        </div> */}


                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">
                                    <div className="row academic_group">
                                        {/* <div className="col-md-12">
                                            <header>
                                                <h4>Sustainable Energy Technologies
                                                </h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Surananai Srinath</h6>
                                                        <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Venu Vinod</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462621 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:avv@nitw.ac.in">avv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Sarath Babu </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462610 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br/> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16329/16329.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. V. Suresh</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462628 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pvsuresh@nitw.ac.in">pvsuresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16465/16465.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Ramsagar</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462632 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramsagar@nitw.ac.in">ramsagar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Manohar Kakunuri</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462627 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17014/17014.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Anjana P A </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462611 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:anjanapa@nitw.ac.in">anjanapa@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17015/17015.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Naresh Thota</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462647 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:naresh@nitw.ac.in">naresh@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Ramya </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462620 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramyaaraga@nitw.ac.in">ramyaaraga@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                    </div>
                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Separations and Process Intensification</h4>
                                            </header>
                                        </div>
                                  

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16328/16328.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Shirish H Sonawane</h6> <span>Coordinator</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462626 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:shirish@nitw.ac.in">shirish@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16322/16322.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Venu Vinod</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462621 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:avv@nitw.ac.in">avv@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16321/16321.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Sarath Babu </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462610 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sarat@nitw.ac.in">sarat@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16324/16324.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. Anand Kishore</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462623 <br/> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:kola@nitw.ac.in">kola@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16325/16325.jpg" alt="" className="img-fluid"  width="150" height="180"/>
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Surananai Srinath</h6>
                                                      
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462624 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:srinath@nitw.ac.in">srinath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16461/16461.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Vidyasagar Shilapuram</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462634 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:vidyasagars@nitw.ac.in">vidyasagars@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16429/16429.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. S. Murali Mohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462630 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:murali@nitw.ac.in">murali@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16422/16422.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Uday Bhaskar Babu</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462629 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:udaybhaskar@nitw.ac.in">udaybhaskar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyan_K_2023-10-9-17-23-49.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Raghu Raja Pandiyan K</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462603 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:raghuraj@nitw.ac.in">raghuraj@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. K. S. Rajmohan</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462631 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rajmohan@nitw.ac.in">rajmohan@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16930/16930.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Manohar Kakunuri</h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462627 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:manohar@nitw.ac.in">manohar@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17069/17069.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. A. Ramya </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462620 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ramyaaraga@nitw.ac.in">ramyaaraga@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17012/17012.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P. Sampath Kumar </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2462622 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:pskr@nitw.ac.in">pskr@nitw.ac.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}














                                    </div>










                                </div>



                                <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                    aria-labelledby="nav-AdjunctFaculty-tab">

                                    <div className="col-md-12 profile-info-individual">
                                        <div className="row">

                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-9-16-55-33.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:director@arci.res.in">director@arci.res.in, tata@arci.res.in</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-9-16-56-57.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sxp17@psu.edu">sxp17@psu.edu</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-9">
                                                <div className="card">
                                                    <div className="d-flex align-items-center">
                                                        <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-9-16-57-53.jpg" alt=""
                                                            className="img-fluid" width="150" height="180" /> </div>
                                                        <div className="ml-3 w-100">
                                                            <h6 className="mb-0 mt-0"> Prof. Ajay K Dalai </h6>
                                                            <div className=" mt-2">
                                                                <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                </div>



                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">
                                </div>

                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">

                                    <div className="details">

                                        {/* <h4>Gym center</h4> */}

                                    </div>




                                    <img src="https://nitw.ac.in/api/static/files/PED_2_2023-10-13-18-37-48.jpg" alt="" className="img-fluid mb-5" />
                                    <img src="https://nitw.ac.in/api/static/files/PED_1_2023-10-13-18-37-28.jpg" alt="" className="img-fluid mb-5" />
                                    <img src="https://nitw.ac.in/api/static/files/PED_3_2023-10-13-18-38-10.jpg" alt="" className="img-fluid mb-5" />
                                    <img src="https://nitw.ac.in/api/static/files/PED_4_2023-10-13-18-38-31.jpg" alt="" className="img-fluid mb-5" />






                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default PEDeptPage;