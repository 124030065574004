import { Grid, Typography } from "@mui/material";
import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { CampusActivityData } from "../constants/CampusActivityData";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Styles.css";

// const bgurl =
// `${MainServerUrl}/static/files/NITW_2021-10-13-22-53-31.jpg`;
let theme = createTheme({
  typography: {
    fontFamily: "Merriweather",
  },
});
theme = responsiveFontSizes(theme);


const CampusActivity = (props) => {
  // console.log(props.items);
  // const x = props.items;
  // console.log(IMG_URL);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <React.Fragment>
      <Grid
        style={{
          backgroundColor: "#F5F7FA",
          // backgroundImage: `url("${bgurl}")`,
          // backgroundSize: "cover",
        }}
      >
        <Grid height="2vh"></Grid>
        <div style={{ width: "90vw", margin: "auto" }}>
          <ThemeProvider theme={theme}>
            <Typography
              variant="h3"
              fontWeight="bold"
              margin="auto"
              textAlign="center"
              color="#b80924"
              paddingTop="3vh"
            >
              Campus Activity
            </Typography>
            <hr className="divider bg-red" />
          </ThemeProvider>
        </div>
        <br />
        <br />
        <section className="section">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={props.autoPlay}
            keyBoardControl={true}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-20px"
            customTransition={"transform 1000ms ease 0s"}
            centerMode={false}
            focusOnSelect={false}
          >
        {/* <section className="section">
          <div style={{ marginBottom: "60px" }} className="owl-carousel flickr owl-carousel-fullheight" data-items={3} data-sm-items={3} data-autoplay="true" data-md-items={8} data-xxl-items={10} data-nav="false" data-dots="false" data-mouse-drag="true" data-lightgallery="group" data-stage-padding={0} data-xl-stage-padding={0}>
            {CampusActivityData.map((img) => {
              return (
                <a style={{
                  margin: "5px 10px",
                  padding: "10px",

                }} className="recruiter-logo thumbnail-default" data-lightgallery="item" href={img.url}><img width={340} height={340} data-title="alt" src={img.url} alt="" /><p className="icon text-center" style={{ fontSize: '20px', borderRadius: "15px" }} fontFamily="Merriweather">{img.text}</p></a>
              )
            })}
          </div>
        </section> */}

            { CampusActivityData.map((item)=> {
              return (
                  <a
                    type="checkbox"
                    className="thumbnail-default"
                    data-lightgallery="item"
                    href={item.url}
                  >
                      <img
                        width={320}
                        height={320}
                        data-title="alt"
                        src={item.url}
                        alt=""
                        className={item.url}
                        id="image-classes"
                      />
                      <p
                        className="icon text-center"
                        style={{ fontSize: "20px" }}
                        fontFamily="Merriweather"
                      >
                        {item.text}
                      </p>
                      </a>
              );
            })}
          </Carousel>
        </section>
      </Grid>
    </React.Fragment>
  );
};

export default CampusActivity;
