export const StaticAcademicCalendarData = [

  {
    heading: 'Academic Calendar 2024-2025 -Autumn Semester (Odd Semester)',
    data: [
      {
        name: 'I Semester students of B.Tech, M.Tech and MSc., Int. MSc. (1st and 7th Semesters)',
        image: 'https://nitw.ac.in/api/static/files/firstsem2024jpg_2024-8-5-15-37-9.jpg',
        // link: 'https://nitw.ac.in/api/static/files/Academic_Calendar_2024-25_-Autumn_Semester._2024-7-2-17-24-1.pdf',
        link: 'https://nitw.ac.in/api/static/files/Autumn-First-Semester-Students_2024-8-5-15-26-18.pdf',
      },
      {
        name: 'UG (including Int. MSc.) II, III, IV, BSc. BED II and PG II, III Years',
        image: 'https://nitw.ac.in/api/static/files/dd24-25_v1_2024-7-2-18-28-20.jpg',
        // link: 'https://nitw.ac.in/api/static/files/Academic_Calendar_2024-25_-Autumn_Semester._2024-7-2-17-24-1.pdf',
        link: 'https://nitw.ac.in/api/static/files/AutumnSemester2024_2024-8-5-15-28-1.pdf',
      },
      {
        name: 'Academic Calendar - Autumn Semester - MBA',
        image: 'https://nitw.ac.in/api/static/files/mba_first_2024-8-28-19-36-11.jpg',
        // link: 'https://nitw.ac.in/api/static/files/Academic_Calendar_2024-25_-Autumn_Semester._2024-7-2-17-24-1.pdf',
        link: 'https://nitw.ac.in/api/static/files/mba_acdamic_2024-8-28-19-37-21.pdf',
      },
      {
        name: 'Academic Calendar-I Semester students of BSc. BEd.',
        image: 'https://nitw.ac.in/api/static/files/bsc_bed_2024-9-19-18-23-57.jpg',
        // link: 'https://nitw.ac.in/api/static/files/Academic_Calendar_2024-25_-Autumn_Semester._2024-7-2-17-24-1.pdf',
        link: 'https://nitw.ac.in/api/static/files/bsc_bed_ac_2024-9-19-18-27-40.pdf',
      },
      
      
    ],
  },
    {      
      heading: 'Even Semester 2023-2024',
      data: [
        {
          name: 'Summer Courses',
          image: 'https://nitw.ac.in/api/static/files/Summer_Courses_2024-3-19-17-31-9.jpg',
          link: 'https://nitw.ac.in/api/static/files/AC_2023-24_Summer_Courses_2024-3-19-17-31-42.pdf',
        },
        {
          name: 'Integrated BSc-BEd (Under ITEP)',
          image: 'https://nitw.ac.in/api/static/files/BSc._BEd._2024_2024-1-22-19-29-3.png',
          link: 'https://nitw.ac.in/api/static/files/BSc._BEd._2024_2024-1-22-19-28-22.pdf',
        },
        {
          name: 'UG I, II, III, IV and PG I, II, III Years (Except ITEP)',
          image: 'https://nitw.ac.in/api/static/files/up_and_pg_2023-12-15-10-58-58.png',
          link: 'https://nitw.ac.in/api/static/files/Academic_Calendar_ES_2023-12-15-10-4-9.pdf',
        },
        {
          name: 'MBA III & IV Quarters',
          image: 'https://nitw.ac.in/api/static/files/MBA_III_&_IV_Quarters_2023-12-20-12-46-57.png',
          link: 'https://nitw.ac.in/api/static/files/MBA_III_&_IV_Quarters_2023-12-20-12-39-47.pdf',
        },
        
        {
          name: 'MBA VII & VIII Quarters',
          image: 'https://nitw.ac.in/api/static/files/MBA_VII_&_VIII_Quarters_2023-12-20-12-45-43.png',
          link: 'https://nitw.ac.in/api/static/files/MBA_VII_&_VIII_Quarters_2023-12-20-12-40-11.pdf',
        },
        
      ],
    },
    {
      heading: 'Odd Semester 2023-2024',
      data: [
        {
          name: 'I-M.Tech., I-M.Sc. and I-MCA',
          image: 'https://nitw.ac.in/api/static/files/MCA_2023-12-5-13-6-18.png',
          link: 'https://nitw.ac.in/api/static/files/Academic_Calendar:_I_-_M.Tech.,_I-M.Sc._and_I-MCA_2023-8-8-16-24-51.pdf',
        },
        {
          name: 'I B.Tech. and I MSc (Int.)',
          image: 'https://nitw.ac.in/api/static/files/B.Tech_2023-12-5-13-7-53.png',
          link: 'https://nitw.ac.in/api/static/files/Academic_Calendar:_I_B.Tech._and_I_MSc_(Int.),_I_-_M.Tech.,_I-M.Sc._and_I-MCA_2023-7-31-11-49-12.pdf',
        },
        {
          name: 'II, III, IV B.Tech; II M.Tech; II, III MCA and II, III MSc Programs',
          image: 'https://nitw.ac.in/api/static/files/MSc_Programs_2023-12-5-13-11-31.png',
          link: 'https://nitw.ac.in/api/static/files/2023-24_II_III_IV_B.Tech_II_M.Tech_II_III_MCA_and_II_III_MSc_Programs_2023-6-28-11-3-51.pdf',
        },
        {
          name: 'MBA I & II Quarters',
          image: 'https://nitw.ac.in/api/static/files/MBA_I_&_II_Quarters_2023-12-5-13-12-37.png',
          link: 'https://nitw.ac.in/api/static/files/MBA_I_&_II_Quarters_2023-6-28-11-2-8.pdf',
        },
        {
          name: 'MBA V & VI Quarters',
          image: 'https://nitw.ac.in/api/static/files/MBA_V_&_VI_Quarters_2023-12-5-13-13-25.png',
          link: 'https://nitw.ac.in/api/static/files/MBA_V_&_VI_Quarters_2023-6-28-11-2-37.pdf',
        },
        {
          name: 'Old Academic Calendars',
          image: 'https://nitw.ac.in/api/static/files/Old_Academic_Calendars_2023-12-5-13-14-30.png',
          link: 'https://nitw.ac.in/page/?url=/oldAC2019/AC2021',
        },
      ],
    },
  ];