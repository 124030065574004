import React from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name
import { Padding, StarOutlineSharp } from "@mui/icons-material";
import { useState } from "react";

const Startup = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>

            <div class="container">

                <div class="row">
                    <div className="col-md-12">
                        <header>
                            <h1 style={{ color: 'blue' }}><center>Startup</center></h1>
                        </header>
                    </div>
                </div>




                <div class="row">





                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>


                                    <img src="https://nitw.ac.in/api/static/files/Prof._Venkata_Reddy_Keesara_2024-4-16-18-5-59.jpg" alt="" class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Venkata Reddy Keesara  </span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-001/ Geo-AI Analytics Platform  </h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    {/* <div class="row">
                    <div class="col-md-4">
                        <img src="https://nitw.ac.in/api/static/files/Prof._Venkata_Reddy_Keesara_2024-4-16-18-5-59.jpg" alt=""
                            class="img-fluid" />
                        <a href="https://erp.nitw.ac.in/ext/profile/ce-kvreddy">
                            <h5 style={{ color: '#b80924', fontSize: '1rem'}}>Prof. Venkata Reddy Keesara</h5>
                        </a>
                    </div>
                
                </div> */}

                                </div>
                            </div>
                        </div>
                    </div>








                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof_.R._Satish_Babu_2024-4-16-18-5-0.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. R. Satish Babu </span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-002/ Biofertilzer </h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Prof._P_Venkateswara_Rao_2024-4-17-12-29-31.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="https://erp.nitw.ac.in/ext/profile/ce-pvenku">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Prof. P. V. Rao   </h5></a>
                                        </div>
                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Prof._Pilli_Sridhar_2024-4-17-12-30-34.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="https://erp.nitw.ac.in/ext/profile/ce-srenitw">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Prof. P. Sridhar    </h5>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>







                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof._Ashish_A_Prabhu_2024-4-16-18-3-50.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/bt-ashishp">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Ashish A Prabhu </span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-003/ Biochemicals manufacturing </h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Prof._Surajbhan_Sevda_2024-4-17-12-45-16.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Prof. Surajbhan Sevda    </h5></a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>









                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof._Anjaneyulu_2024-4-16-18-2-41.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/ec-anjan">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Anjaneyulu </span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-004/ Verification System</h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Vivek_Merugu_2024-4-17-17-0-30.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Sri. Vivek Merugu       </h5></a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof._Raghu_Raja_Pandiyen_2024-4-16-18-0-34.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/ch-raghuraj">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Raghu Raja Pandiyen</span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-005/ Intensified Tech for nanoproduction</h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Dr._Vikas_Hakke_2024-4-18-10-35-33.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Dr. Vikas Hakke   </h5></a>
                                        </div>
                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/Sri._Dilip_Kumar_2024-4-18-10-36-39.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Sri. Dilip Kumar    </h5></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>





                    <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof._B._Lakshmi_2024-4-16-17-59-25.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/ec-lakshmi">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. B. Lakshmi</span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-006/  Water Monitoring System</h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">


                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/profile_dummy_2023-6-15-12-50-8.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>Sri. Kuncha Madhu    </h5></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>






                    {/* <div className="col-md-12 mb-5">
                        <div class="seminar-card" style={{ padding: '2%', backgroundColor: '#e6e8e9' }}>
                            <div className="row">

                                <div class="col-md-3 p-4">

                                    <div class="title_arrow mb-3">
                                        <p class="title">Lead Incubator</p>
                                    </div>

                                    <img src="https://nitw.ac.in/api/static/files/Prof._Praveen_Kumar_Bommineni_2024-4-16-17-58-25.jpg" alt=""
                                        class="img-responsive img-circle" />

                                    <p style={{ textAlign: 'justify' }}>
                                        <a href="https://erp.nitw.ac.in/ext/profile/ch-praveen">
                                            <span style={{ color: '#b80924', fontWeight: 'bold' }}>Prof. Praveen Kumar Bommineni</span>
                                        </a>
                                    </p>
                                </div>

                                <div class="col-md-3 p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Start-up ID/ Name</p>
                                    </div>

                                    <header class="mt-3">
                                        <h4 style={{ color: '#b80924' }}>SSG-007/ Human Safety </h4>
                                    </header>
                                </div>

                                <div class="col-md-5 details p-4" style={{ borderLeft: '1px solid gray' }}>

                                    <div class="title_arrow mb-3">
                                        <p class="title">Other Team Members</p>
                                    </div>

                                    <div class="row">


                                        <div class="col-md-4">
                                            <img src="https://nitw.ac.in/api/static/files/K.Venkata_Manoj_Yadav_2024-4-17-16-39-48.jpg" alt=""
                                                class="img-fluid" />
                                            <a href="">
                                                <h5 style={{ color: '#b80924', fontSize: '1rem' }}>K Venkatas Manoj Yadav   </h5></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}


                    {/* <div className="col-md-6 mb-4">
                       <a href='https://nitw.ac.in/api/static/files/Startup_policy_2024-6-5-16-31-14.pdf' target='_blank'>
                       <div class="title_arrow mb-3">
                                        <p class="title">
                                    
                         Startup policy- Click here
                        </p>
                         </div>
                         </a> 
                    </div> */}







                </div>

            </div>

        </>
    );
}

export default Startup;