import { useState } from "react";


const BIODeptPage = () => {

    const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
    const [peopleTab, setPeopleTab] = useState("Faculty");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log(tabId)
    }

    const handlePeopleTabClick = (tabId) => {
        setPeopleTab(tabId);
        console.log(tabId)
    }
    return (
        <>
        <div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        {/* */}
                        {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
                        <img src="BIODeptAssets/images/BIObanner.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-3">
                            {/* Tabs nav */}
                            <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                                    role="tab" aria-controls="v-pills-visionandmission"
                                    aria-selected="false">
                                    <span>About</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                                    id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                                    aria-controls="v-pills-academics" aria-selected="false">
                                    <span>Academic Programmes</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                                    role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                                    <span>People </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                                    role="tab" aria-controls="v-pills-NotableAlumni"
                                    aria-selected="false">
                                    <span>Notable Alumni </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                                    id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                                    aria-controls="v-pills-Students" aria-selected="false">
                                    <span>Students Society </span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                                    role="tab"
                                    aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                                        Group</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                                    role="tab" aria-controls="v-pills-Awards"
                                    aria-selected="false"> <span>Awards and Honours</span> </a>
                                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                                    aria-controls="v-pills-labs" aria-selected="false">
                                    <span>Laboratory & Facilities</span>
                                </a>
                                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                                    role="tab" aria-controls="v-pills-PhotoGallery"
                                    aria-selected="false"> <span>Photo Gallery</span> </a>

                            </div>
                            <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                <h6>Prof. B Rama Raju</h6>
                            </a>
                                <p> Head of the Department <br /> <a href="mailto:biotech_hod@nitw.ac.in"
                                    style={{ color: "#808080" }}>biotech_hod@nitw.ac.in</a> <br /> 9490165348 </p>
                            </div>
                        </div>
                        <div className="col-md-9">
                            {/* Tabs content */}
                            <div className="tab-content" id="v-pills-tabContent">
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                                    role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                                    <div className="intro-section">
                                        {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                                        <p>Biotechnology engineering is a branch of engineering where technology is combined with biology for research & development. There are various applications of biotechnology in fields such as animal husbandry, growth of vaccines and medicines, agriculture, pollution control, energy production and conservation, healing of prolonged disease and ecological conservation. It also helps in development of insecticides, fertilizers and quality of seeds. A candidate who specializes in this field is known as a biotechnologist or Bioprocess Engineer. There are lots of career opportunities in this field for young aspirants both in India as well as in aboard.</p>


                                        <p className="more"> <a className="more-info-link" href="https://www.inventiva.co.in/trends/biotechnology-companies/" target="_blank"> Best 10 Biotechnology Companies In India In 2021 <i className="fa fa-angle-right"></i> </a> </p>

					<div class="head-bx"><h3>Message from the Head of the Department</h3>
                                        <p>Welcome to the Department of Biotechnology at NIT Warangal. We are proud to offer B.Tech, M.Tech, and Ph.D. programs that provide comprehensive education and research opportunities in biotechnology. Our department is at the cutting edge of this multidisciplinary field, which integrates biology with technology to address global challenges.</p>
                                        <p>Biotechnology is a rapidly growing domain with immense potential to revolutionize industries. From healthcare and pharmaceuticals—where breakthroughs in drug discovery, gene therapy, and personalized medicine are shaping the future of medicine—to agriculture—where genetic engineering, biofertilizers, and biopesticides are promoting sustainable farming practices—biotechnology is essential for progress. Our programs also emphasize key areas such as industrial biotechnology, with applications in biofuels, bioproducts, and sustainable manufacturing, and environmental biotechnology, which focuses on waste management, bioremediation, and conservation efforts.</p>
                                        <p>India’s BIO E3 policy, centered on Economy, Environment, and Energy, aims to drive a sustainable bio-economy by fostering innovation in biotechnology. Our department is aligned with this vision, advancing research and education in emerging fields like synthetic biology, bioinformatics, bioprocessing, and biomaterials. We also focus on biorefinery approaches that convert waste into valuable products, contributing to circular economy goals.</p>
                                        <p>We invite students and researchers to join us in shaping the future of biotechnology. Together, we can contribute to a sustainable, bio-based economy and make meaningful advancements in science and society.</p>
                                        <div className="row">
                                            <div className="col-md-6"><img src="BIODeptAssets/images/bioe3.jpg" alt="" className="img-fuild" /></div>
                                            <div className="col-md-6">
                                                <img src="BIODeptAssets/images/bioeconomy.jpg" alt="" className="img-fuild" />
                                        	</div>
						<div className="col-md-12 mt-3">
                                               <h3 style={{color:"#eb6a42"}}><i>Towards Green, Clean and Prosperous India</i></h3>
						<p><b>Source : </b> <a href="https://dbtindia.gov.in/sites/default/files/BioE3%20Policy%20Brohcure_0.pdf" target="_blank">https://dbtindia.gov.in/sites/default/files/BioE3%20Policy%20Brohcure_0.pdf</a></p>
                                                </div>
                                        </div>
                                        </div>



					    
                                    </div>
                                    <div className="row vision-mission-section">
                                        <div className="col-md-6 vision-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                                                alt="" /> Vision</h4>

                                            <p>Through its faculty, Department of Bio-Technology is a driving force.</p>
                                            <p className="mb-4">To apply engineering knowledge and create novel technologies that improve the human condition by optimizing opportunities for further training and a successful transition to a career in the biotechnology and life sciences.</p>
                                            <p className="mb-4">To initiate a level of attitude in research that will provide solutions to environmental, industrial, agricultural and health based problems.</p>
                                            <p className="mb-4">To graduate students who can compete, with advantage, within the relevant academic and commercial markets in the world. </p>


                                        </div>
                                        <div className="col-md-6 mission-section">
                                            <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                                                alt="" /> Mission</h4>
                                            <p className="  mb-2">To offer a comprehensive exploration of basic science, applied science, and lab science with an industry focus and to act collectively like a enzyme in enhancing, improving and supporting interdisciplinary research and training.</p>
                                            <p className="  mb-2">To provide a quality education in pursuit of knowledge, that establishes a strong foundation for understanding developments in the rapidly advancing field of biotechnology with the development of new biochemical technologies and therapies through research and education.</p>
                                            <p className="  mb-2">To provide the nation with highly trained professional who are able to implement the scientific principles to the continuous improvement of the safe, quality and value biotechnological services and products.</p>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <h4 className="mt-2 mb-2 notice-board1">
                                            <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardBio/Biotechnology" > Notices/Announcements</a>
                                        </h4>
                                    </div>
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                                    aria-labelledby="v-pills-academics-tab">
                                    {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                                    {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                                    <div className="row">


                                        <div className="col-md-12 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>B.Tech</h3>
                                                        <p>The study of Biotechnology is a blend of subjects related Biology and subjects like Mathematics, Physics, Chemistry and Engineering. A simple definition of Biotechnology is that it uses the properties of living organisms to produce desired products. The Department was started in the year 2006 with an under-graduate programme in B.Tech with an intake of 60 students. Presently the intake is 92 students. Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country. Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carry out an independent project in the IV year.</p>

                                                        <p>Curriculum of B.Tech. degree programme in Biotechnology is at par with that of the best Institutions in the country.Industrial visits are included. Departmental core subjects, departmental electives, basic science core subjects and electives, basic engineering core subjects and electives humanities and social sciences core subjects and electives are included in the curriculum. It is a flexible curriculum and choice based credit system. Every student has to carryout an independent project in the IV year.</p>


                                                        {/*<img src="CSEDeptAssets/images/btech.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>

                                                    {/* <p className="more"> <a className="more-info-link"
                                                        href="BIODeptAssets/docs/biotech-btech-tt.pdf" target="_blank"> Even Semister Academic Year (2022 -2023) <i className="fa fa-angle-right"></i> </a> </p> */}

                                                    <p className="more"> <a className="more-info-link"
                                                        href="BIODeptAssets/docs/syllabus-btech-bio.pdf" target="_blank"> B.Tech
                                                        Shceme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>M.Tech</h3>
                                                        {/* <p>M. Tech (Computer Science and Engineering) program was started in
                                                            1987 with an intake of 18 and subsequently increased to 20 in 2008.
                                                            Currently the strength is 30.</p> */}
                                                        {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/biotech-mtech-tt.pdf"
                                                        target="_blank"> M.Tech Sheme and Syllabus
                                                        {/* 2022-2023   */} <i
                                                            className="fa fa-angle-right"></i>
                                                    </a> </p>

                                                    {/* <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/mtech-biotech-syllabus-1892020_8SdWS3m.pdf"
                                                        target="_blank"> M.Tech syllabus 2020-2021  <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                                                </div>
                                            </div>
                                        </div>






                                        <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Ph.D.</h3>
                                                        {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>




                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Academic Calendar</h3>
                                                      
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/i-btech-int-msc-ii-sem.pdf"
                                                        target="_blank"> Even Semester  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/i-btech-int-msc-i-sem.pdf"
                                                        target="_blank"> Odd Semester  <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/acad_calendar-summer-quarter.pdf"
                                                        target="_blank"> Summer Quarter <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>
                                        </div> */}





                                        {/* <div className="col-md-6 col-sm-12">
                                            <div className="card-4">
                                                <div className="card-detail">
                                                    <div className="head-bx">
                                                        <h3>Time-Table</h3>
                                                       
                                                    </div>
                                                    <p className="more"> <a className=" more-info-link"
                                                        href="BIOeptAssets/docs/biotech-btech-tt.pdf"
                                                        target="_blank"> B.Tech (Even Semester)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>

                                                        <p className="more"> <a className=" more-info-link"
                                                        href="BIODeptAssets/docs/biotech-mtech-tt.pdf"
                                                        target="_blank"> M.Tech (Even Semester)  <i
                                                            className="fa fa-angle-right"></i> </a> </p>


                                                </div>
                                            </div>
                                        </div> */}









                                    </div>

                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                                    aria-labelledby="v-pills-labs-tab">
                                    
					<div className="row laboratory-facilities">
                                        <div className="col-md-12 col-sm-6 laboratory-facilities-individual">
                                            
                                            <div className="row"> 
                                            
                                                <h3 className="">Biomaterials & Tissue Engineering Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab1_2024-9-30-12-19-23.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                            
                                                <h3 className="">Bioseparation & Immunology Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab2_2024-9-30-12-19-59.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                            
                                                <h3 className="">Computational Facility</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab3_2024-9-30-12-20-25.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                            
                                                <h3 className="">Microbiology Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab4_2024-9-30-12-20-57.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                           
                                                <h3 className="">Transport Phenomena in Bioprocess Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab5_2024-9-30-12-21-24.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                            
                                                <h3 className="">Plant cell & Tissue Labarotary</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab6_2024-9-30-12-21-52.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                           
                                                <h3 className="">Genetic Engineering & Molecular Biology Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab7_2024-9-30-12-22-18.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                            
                                                <h3 className="">BPE Lab</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab8_2024-9-30-12-22-46.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                           
                                                <h3 className="">Bioprocess Instrumetational Laborotory</h3>
                                           
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab9_2024-9-30-12-23-18.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                           
                                                <h3 className="">Biochemistry Lab</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab10_2024-9-30-12-23-45.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>


                                            <div className="row"> 
                                           
                                                <h3 className="">Animal call culture & Tissue Engineering Laboratory</h3>
                                            
                                                <div className="col-md-12"> <img src="https://nitw.ac.in/api/static/files/btlab11_2024-9-30-12-24-45.jpeg"
                                                    alt="Photo of sunset" className="img-fluid" /> </div> 
                                            </div>

                                        </div>
                                    
                                    </div>    
					    
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                                    aria-labelledby="v-pills-Phd-tab">
                                    <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                                        Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>
                                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                                    aria-labelledby="v-pills-Faculty-tab">
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-faculty"
                                            aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>

                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                                            id="nav-NonTeachingStaff-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                                            Staff</a>


                                        <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                                    </div>
                                    <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                                        style={{ marginTop: "5%", marginLeft: "1%" }}>
                                        <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                                            aria-labelledby="nav-faculty-tab">
                                            {/*<h4 className=" mb-4">Faculty</h4>*/}
                                            {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                                            {/* <section id="profile-info">



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16440"
                                                            target="_blank"> <img src="BIODeptAssets/images/6.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16440"
                                                                        target="_blank">Prof. Amitava Bandhu</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969446 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bandhuam@nitw.ac.in">bandhuam@nitw.ac.in</a> </p>
                                                                    <p>Studies on gene regulation in Mycobacteria</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                            target="_blank"> <img src="BIODeptAssets/images/14.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                                        target="_blank">Prof. Anbumathi P</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 08702462703 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:anbu@nitw.ac.in">anbu[@]nitw[dot]ac[dot]in</a>
                                                                    </p>
                                                                    <p>Systems Biology, Cancer, Cell Cycle Regualtion, Mathematical Modeling and Analysis</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17075"
                                                            target="_blank"> <img src="BIODeptAssets/images/21.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17075"
                                                                        target="_blank">Prof. Ashish A Prabhu</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9582700174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishp@nitw.ac.in">ashishp@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering; Bioprocess Engineering; Biomass and Biorefinery; Therapeutic proteins/enzymes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                            target="_blank"> <img src="BIODeptAssets/images/10.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                                        target="_blank">Prof. Asim Bikas Das</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969440 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asimbikas@nitw.ac.in">asimbikas@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Network system biology ; Cancer genomics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17026"
                                                            target="_blank"> <img src="BIODeptAssets/images/20.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17026"
                                                                        target="_blank">Prof. Chockalingam S</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9502205174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:chocks@nitw.ac.in">chocks@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cancer biology; Cancer cell signaling; Cancer stem cell biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16351"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16351"
                                                                        target="_blank">Prof. Korrapati Narasimhulu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9985470286 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:simha_bt@nitw.ac.in">simha_bt@nitw.ac.in</a>
                                                                        
                                                                    </p>
                                                                    <p>Environmental Biotechnology,Modelling & Simulation of Bioprocesses,Biofuels & Bioenergy,Systems Biology.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16895"
                                                            target="_blank"> <img src="BIODeptAssets/images/13.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16895"
                                                                        target="_blank">Prof. M. Jerold</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9486252374 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jerold@nitw.ac.in">jerold@nitw.ac.in
                                                                        </a> </p>
                                                                    <p>Biofuels & Bioenergy; Bioprocessing; Biosorption; Wastewater Treatment; Bioseparation; Algal Biotechnology ; Biopolymer; Biorefineries; Biomass Valorization; Biogenic Nanoparticles</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                            target="_blank"> <img src="BIODeptAssets/images/5.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                                        target="_blank">Prof. Onkara Perumal. P</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:popomal@nitw.ac.in">popomal@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Bioinformatics; Computational Biology; Functional Genomics; Metabolic Disorders.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16894"
                                                            target="_blank"> <img src="BIODeptAssets/images/12.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16894"
                                                                        target="_blank">Prof. Perugu Shyam</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"><i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9948561761 <br /> <i className="fa fa-envelope"
                                                                            aria-hidden="true"></i> <a
                                                                                href="mailto:shyamperugu@nitw.ac.in">shyamperugu@nitw.ac.in</a> </p>
                                                                    <p>Bioinformatics, Systems biology, Cancer biology, Molecular Dynamics & Simulations, Drug Design and Discovery, Machine Learning, Biological Bigdata analysis.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16425"
                                                            target="_blank"> <img src="BIODeptAssets/images/1.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16425"
                                                                        target="_blank">Prof. Prakash Saudagar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2452898 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ps@nitw.ac.in">ps@nitw.ac.in</a> </p>
                                                                    <p>Protein Biochemistry, Molecular Approach to Infectious Diseases, Molecular & Biochemical Parasitology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17024"
                                                            target="_blank"> <img src="BIODeptAssets/images/18.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17024"
                                                                        target="_blank">Prof. Priya</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:priyap@nitw.ac.in"> priyap@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cell and molecular biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                            target="_blank"> <img src="BIODeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                                        target="_blank">Prof. R. Satish Babu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969439 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satishbabu@nitw.ac.in">satishbabu@nitw.ac.in</a>
                                                                        
                                                                    </p>
                                                                    <p>Bioprocess EngineeringEnvironmental BiotechnologyModeling and Simulation of BioprocessesMetabolic EngineeringBioinformatics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                            target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                                        target="_blank">Prof. Rama Raju. B</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969462 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rrb@nitw.ac.in">rrb@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering, Biofuels and Biomaterials</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                            target="_blank"> <img src="BIODeptAssets/images/15.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                                        target="_blank">Prof. Rathnaprabha D</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494668822 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rathnaprabhad@nitw.ac.in"> rathnaprabhad@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Plant Biotechnology, Plant tissue culture Plant Molecular biology, Phytochemicals analysis from medicinal plantsts</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17023"
                                                            target="_blank"> <img src="BIODeptAssets/images/16.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17023"
                                                                        target="_blank">Prof. Soumya Lipsa Rath</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-24524205 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:slrath@nitw.ac.in">
                                                                            slrath[@]nitw[dot]ac[dot]in </a> </p>
                                                                    <p>Molecular Modelling ; Structure Based Drug Designing;Study of Protein structure and function using Molecular Dynamics Simulations</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                            target="_blank"> <img src="BIODeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                                        target="_blank">Prof. Sreenivasa Rao Parcha</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"><i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:parcha@nitw.ac.in">parcha@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Stem Cell Engineering, Downstream Processing and Enzyme Engineering</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                            target="_blank"> <img src="BIODeptAssets/images/17.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                                        target="_blank">Prof. Surajbhan Sevda</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9929565697
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:sevdasuraj@nitw.ac.in"> sevdasuraj@nitw.ac.in</a> </p>
                                                                    <p>My current area of Research: (a) Applications of protein nanowires in electronic sensing and power-generating devices.(b) Biosensing for environmental monitoring and healthcare diagnostics;(c) Direct interspecies electron transfer; (d) Synthetic electromicrobiology; (e) Novel bioenergy strategies;</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                            target="_blank"> <img src="BIODeptAssets/images/19.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                                        target="_blank">Prof. Thyageshwar Chandran</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7348975012
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:thyagesh@nitw.ac.in"> thyagesh@nitw.ac.in</a>
                                                                       
                                                                    </p>
                                                                    <p>Structural Proteomics (X-ray Crystallography and Cryo-EM); Molecular Dynamics of Macromolecules; Molecular Biophysics; Lectins; Ribosome Inactivating Proteins; Ribosomes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                            target="_blank"> <img src="BIODeptAssets/images/11.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                                        target="_blank">Prof. Urmila Saxena</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969442 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:urmila@nitw.ac.in">urmila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p> Statistical Analysis of Biological Data for Biomarker Identification; Medical Biosensors; Nanobiotechnology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                            target="_blank"> <img src="BIODeptAssets/images/8.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                                        target="_blank">Prof. V. Kohila</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849432598 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:v.kohila@nitw.ac.in">v.kohila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Protein Engineering, Immunology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>







                                            </section> */}
                                            <section id="profile-info">



                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-bandhuam"
                                                            target="_blank"> <img src="BIODeptAssets/images/6.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-bandhuam"
                                                                        target="_blank">Prof. Amitava Bandhu</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969446 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:bandhuam@nitw.ac.in">bandhuam@nitw.ac.in</a> </p>
                                                                    <p>Studies on gene regulation in Mycobacteria</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                            target="_blank"> <img src="BIODeptAssets/images/14.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-anbu"
                                                                        target="_blank">Prof. Anbumathi P</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 08702462703 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:anbu@nitw.ac.in">anbu[@]nitw[dot]ac[dot]in</a>
                                                                    </p>
                                                                    <p>Systems Biology, Cancer, Cell Cycle Regualtion, Mathematical Modeling and Analysis</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                            target="_blank"> <img src="BIODeptAssets/images/21.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                                        target="_blank">Prof. Ashish A Prabhu</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9582700174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ashishp@nitw.ac.in">ashishp@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering; Bioprocess Engineering; Biomass and Biorefinery; Therapeutic proteins/enzymes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                            target="_blank"> <img src="BIODeptAssets/images/10.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-asimbikas"
                                                                        target="_blank">Prof. Asim Bikas Das</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969440 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:asimbikas@nitw.ac.in">asimbikas@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Network system biology ; Cancer genomics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-chocks"
                                                            target="_blank"> <img src="BIODeptAssets/images/20.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-chocks"
                                                                        target="_blank">Prof. Chockalingam S</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9502205174 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:chocks@nitw.ac.in">chocks@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cancer biology; Cancer cell signaling; Cancer stem cell biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                            target="_blank" className="img-fluid"> <img
                                                                src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" /> </a>
                                                        </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                                        target="_blank">Prof. Korrapati Narasimhulu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9985470286 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:simha_bt@nitw.ac.in">simha_bt@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:simha7762006@gmail.com">simha7762006@gmail.com</a> */}
                                                                    </p>
                                                                    <p>Environmental Biotechnology,Modelling & Simulation of Bioprocesses,Biofuels & Bioenergy,Systems Biology.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-jerold"
                                                            target="_blank"> <img src="BIODeptAssets/images/13.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-jerold"
                                                                        target="_blank">Prof. M. Jerold</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9486252374 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:jerold@nitw.ac.in">jerold@nitw.ac.in
                                                                        </a> </p>
                                                                    <p>Biofuels & Bioenergy; Bioprocessing; Biosorption; Wastewater Treatment; Bioseparation; Algal Biotechnology ; Biopolymer; Biorefineries; Biomass Valorization; Biogenic Nanoparticles</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                            target="_blank"> <img src="BIODeptAssets/images/5.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-popomal"
                                                                        target="_blank">Prof. Onkara Perumal. P</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:popomal@nitw.ac.in">popomal@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Bioinformatics; Computational Biology; Functional Genomics; Metabolic Disorders.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-shyamperugu"
                                                            target="_blank"> <img src="BIODeptAssets/images/12.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-shyamperugu"
                                                                        target="_blank">Prof. Perugu Shyam</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"><i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9948561761 <br /> <i className="fa fa-envelope"
                                                                            aria-hidden="true"></i> <a
                                                                                href="mailto:shyamperugu@nitw.ac.in">shyamperugu@nitw.ac.in</a> </p>
                                                                    <p>Bioinformatics, Systems biology, Cancer biology, Molecular Dynamics & Simulations, Drug Design and Discovery, Machine Learning, Biological Bigdata analysis.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-ps"
                                                            target="_blank"> <img src="BIODeptAssets/images/1.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-ps"
                                                                        target="_blank">Prof. Prakash Saudagar</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-2452898 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ps@nitw.ac.in">ps@nitw.ac.in</a> </p>
                                                                    <p>Protein Biochemistry, Molecular Approach to Infectious Diseases, Molecular & Biochemical Parasitology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-priyap"
                                                            target="_blank"> <img src="BIODeptAssets/images/18.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-priyap"
                                                                        target="_blank">Prof. Priya</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:priyap@nitw.ac.in"> priyap@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Cell and molecular biology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                            target="_blank"> <img src="BIODeptAssets/images/3.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-satishbabu"
                                                                        target="_blank">Prof. R. Satish Babu</a> </h5>
                                                                    <span>Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969439 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:satishbabu@nitw.ac.in">satishbabu@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:rsatishbabur@gmail.com">rsatishbabur@gmail.com</a> */}
                                                                    </p>
                                                                    <p>Bioprocess EngineeringEnvironmental BiotechnologyModeling and Simulation of BioprocessesMetabolic EngineeringBioinformatics</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                            target="_blank"> <img src="BIODeptAssets/images/7.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rrb"
                                                                        target="_blank">Prof. Rama Raju. B</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969462 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rrb@nitw.ac.in">rrb@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Metabolic Engineering, Biofuels and Biomaterials</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                            target="_blank"> <img src="BIODeptAssets/images/15.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-rathnaprabhad"
                                                                        target="_blank">Prof. Rathnaprabha D</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494668822 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:rathnaprabhad@nitw.ac.in"> rathnaprabhad@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Plant Biotechnology, Plant tissue culture Plant Molecular biology, Phytochemicals analysis from medicinal plantsts</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-slrath"
                                                            target="_blank"> <img src="BIODeptAssets/images/16.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-slrath"
                                                                        target="_blank">Prof. Soumya Lipsa Rath</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 0870-24524205 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:slrath@nitw.ac.in">
                                                                            slrath[@]nitw[dot]ac[dot]in </a> </p>
                                                                    <p>Molecular Modelling ; Structure Based Drug Designing;Study of Protein structure and function using Molecular Dynamics Simulations</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                            target="_blank"> <img src="BIODeptAssets/images/2.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-parcha"
                                                                        target="_blank">Prof. Sreenivasa Rao Parcha</a>
                                                                    </h5> <span>Professor</span>
                                                                    <p className="contact-info"><i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:parcha@nitw.ac.in">parcha@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Stem Cell Engineering, Downstream Processing and Enzyme Engineering</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                            target="_blank"> <img src="BIODeptAssets/images/17.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj"
                                                                        target="_blank">Prof. Surajbhan Sevda</a>
                                                                    </h5> <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9929565697
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:sevdasuraj@nitw.ac.in"> sevdasuraj@nitw.ac.in</a> </p>
                                                                    <p>My current area of Research: (a) Applications of protein nanowires in electronic sensing and power-generating devices.(b) Biosensing for environmental monitoring and healthcare diagnostics;(c) Direct interspecies electron transfer; (d) Synthetic electromicrobiology; (e) Novel bioenergy strategies;</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                            target="_blank"> <img src="BIODeptAssets/images/19.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-thyagesh"
                                                                        target="_blank">Prof. Thyageshwar Chandran</a> </h5>
                                                                    <span>Assistant Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 7348975012
                                                                        <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                                                        <a href="mailto:thyagesh@nitw.ac.in"> thyagesh@nitw.ac.in</a>
                                                                        {/* , <a
                                href="mailto:cthyageshwar@alum.iisc.ac.in">cthyageshwar@alum.iisc.ac.in</a> */}
                                                                    </p>
                                                                    <p>Structural Proteomics (X-ray Crystallography and Cryo-EM); Molecular Dynamics of Macromolecules; Molecular Biophysics; Lectins; Ribosome Inactivating Proteins; Ribosomes</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                            target="_blank"> <img src="BIODeptAssets/images/11.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-urmila"
                                                                        target="_blank">Prof. Urmila Saxena</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969442 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:urmila@nitw.ac.in">urmila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p> Statistical Analysis of Biological Data for Biomarker Identification; Medical Biosensors; Nanobiotechnology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                        <div className="col-md-3"> <a
                                                            href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                            target="_blank"> <img src="BIODeptAssets/images/8.jpg" alt=""
                                                                className="img-fluid" /> </a> </div>
                                                        <div className="col-md-9">
                                                            <div className="card ">
                                                                <div className="data">
                                                                    <h5> <a href="https://erp.nitw.ac.in/ext/profile/bt-v.kohila"
                                                                        target="_blank">Prof. V. Kohila</a> </h5>
                                                                    <span>Associate Professor</span>
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9849432598 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:v.kohila@nitw.ac.in">v.kohila@nitw.ac.in</a>
                                                                    </p>
                                                                    <p>Protein Engineering, Immunology</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>







                                            </section>
                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                                            aria-labelledby="nav-NonTeachingStaff-tab">
                                            <div className="row academic_group">

                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st1.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">V Suresh</h6> <span>Assistant(SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969539 <br />
                                                                        
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st2.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Chaitanya Subhash Gajbhiye</h6> <span>Technical Assistant</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8087832150 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:gajbhiyec@nitw.ac.in">gajbhiyec@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st3.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">G Shankaraiah</h6>
                                                                <span>Technician(SG-II)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969441 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:shankaraiah647@nitw.ac.in">shankaraiah647@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st4.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">Dr A Ramesh</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9494464295 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:ramesh1989@nitw.ac.in">ramesh1989@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st5.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">P Kumar</h6> <span>Senior Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969645 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:pkumar406@nitw.ac.in">pkumar406@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st6.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K Kumaraswamy</h6> <span>Senior Caretaker</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8332969644
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st7.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">K Ravikumar</h6> <span>Sr.Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 8374663160 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:kakatir@nitw.ac.in">kakatir@nitw.ac.in</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st8.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">A Karthik</h6>
                                                                <span>Technician</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9160648038 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:althik@nitw.ac.in">althik@nitw.ac.in</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st9.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">T Sammaiah</h6> <span>Technician (Dailywage)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9949698590 <br /> <i
                                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                            href="mailto:thottesammaiah@gmail.com">thottesammaiah@gmail.com</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st10.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">B Naresh</h6> <span>Outsourcing (Skilled)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9666298023 <br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:nareshnannu2011@gmail.com">nareshnannu2011@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="col-md-6">
                                                    <div className="card">
                                                        <div className="d-flex align-items-center">
                                                            <div className="image"> <img src="BIODeptAssets/images/st11.jpg" alt=""
                                                                className="img-fluid" /> </div>
                                                            <div className="ml-3 w-100">
                                                                <h6 className="mb-0 mt-0">V Praveen</h6> <span>Outsourcing (Skilled)</span>
                                                                <div className=" mt-2">
                                                                    <p className="contact-info"> <i className="fa fa-phone"
                                                                        aria-hidden="true"></i> 9908384578<br /> <i
                                                                            className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                                href="mailto:praveenvem@1979@gmail.com">praveenvem@1979@gmail.com</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}



                                            </div>

                                        </div>
                                        <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                                            aria-labelledby="nav-ResearchScholars-tab">
                                            {/*<h4 className="">Ph.D. Awardee</h4>
                    <p>
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}



                                            <div className="row phd-std-row">
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd1_2024-9-30-12-32-58.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cellular & Molecular biology of infectious diseases </b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Chethi Reddy Vihadhar Reddy</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:cr22btr1r03@student.nitw.ac.in" >cr22btr1r03@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd2_2024-9-30-12-33-28.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Protein Biochemistry of Infectious diseases.</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Gollapalli Seshaveena</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:gs23btr1r02@student.nitw.ac.in" >gs23btr1r02@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd3_2024-9-30-12-34-10.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Synthesis of Novel Biomass based green Catalyst for Biodiesel Production</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Akshay Prakash</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ap720096@student.nitw.ac.in">ap720096@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd4_2024-9-30-12-34-39.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cancer Systems Biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Samala Nikhilteja Goud</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sn23btr2r03@student.nitw.ac.in">sn23btr2r03@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd5_2024-9-30-12-35-9.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biovalorization of waste via microbial cell factory</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Satwika Das</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sd23btr1r11@student.nitw.ac.in">sd23btr1r11@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd6_2024-9-30-12-35-38.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocess and Biorefinery using metabolic engineering for utilization of hemicellulose units of Lignocellulosic biomass.</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sheetal Milind Meshram</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ms23btr1r05@student.nitw.ac.in">ms23btr1r05@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd7_2024-9-30-12-36-0.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocess Technology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Ramavath Lavanya</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:rl22btr1r06@student.nitw.ac.in">rl22btr1r06@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd8_2024-9-30-12-36-35.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioelectroremediation of Wastewater by Microbial Fuel Cells</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sudipa Bhadra</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sb721094@student.nitw.ac.in">sb721094@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd9_2024-9-30-12-37-8.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cancer Biology and Cell signaling of ALK receptor</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Kajal</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:kk721095@student.nitw.ac.in">kk721095@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd10_2024-9-30-12-37-40.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cloning, purification and expression analysis of Mycobacterium tuberculosis</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Dheeraj Singh</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ds721092@student.nitw.ac.in">ds721092@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="CHEDeptAssets/images/noimage.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Application of plant biotechnology for the production of pharmaceutically important compounds</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Srividya A</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:srividya_jrf@nitw.ac.in">srividya_jrf@nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd11_2024-9-30-12-38-15.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cancer Genomic</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Yerra UshaKiran</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:yu22btr1r05@student.nitw.ac.in">yu22btr1r05@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd12_2024-9-30-12-38-44.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biosurfactants</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Rapelli Divya</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:dr22btr1s02@student.nitw.ac.in">dr22btr1s02@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd13_2024-9-30-12-39-16.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biomaterials </b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Poshala Preethi Goud</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:pp24btr1r08@student.nitw.ac.in">pp24btr1r08@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd14_2024-9-30-12-39-51.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Gene editing</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Nupur Pathak</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:np23btr1r03@student.nitw.ac.in">np23btr1r03@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd15_2024-9-30-12-40-16.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biomaterials</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Valluri Sowjanya</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:vs24btr1r10@student.nitw.ac.in">vs24btr1r10@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd16_2024-9-30-12-40-43.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Protein Biochemistry, Structural Biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">E B Rohitth Raj</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:rr24btr1r02@student.nitw.ac.in">rr24btr1r02@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd17_2024-9-30-12-42-45.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Molecular cell biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sandhya Dubey</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sd24btr1r05@student.nitw.ac.in">sd24btr1r05@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd18_2024-9-30-12-43-12.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Stem Cells and Tissue Engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Narahari Suchithra Reddy</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ns24btr1r13@student.nitw.ac.in">ns24btr1r13@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd19_2024-9-30-12-43-43.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocess Engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Embari Rashmitha</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:er24btr1r07@student.nitw.ac.in">er24btr1r07@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd20_2024-9-30-12-44-12.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Network medicine and computational Genomics</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sayan Sengupta</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ss24btr1r01@student.nitw.ac.in">ss24btr1r01@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd21_2024-9-30-12-44-41.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Plant tissue culture</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">K Naresh Kumar</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:kn24btr1r04@student.nitw.ac.in">kn24btr1r04@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd22_2024-9-30-12-45-13.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biorefinery, Bioprocess technology, Metabolic engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Chandukishore.T</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ct712143@student.nitw.ac.in">ct712143@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd23_2024-9-30-12-45-57.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Computational Biophysics, Predictive modeling using ML</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Pratibha Singh</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ps23btr1r04@student.nitw.ac.in">ps23btr1r04@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd24_2024-9-30-12-46-22.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Resource recovery and bioelectricity generation</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Soubhagya Nayak </h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sn23btr1r10@student.nitw.ac.in">sn23btr1r10@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd25_2024-9-30-12-46-50.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Tissue Engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sivasankar MV</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:m.sivasankar75@gmail.com">m.sivasankar75@gmail.com</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd26_2024-9-30-12-47-14.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioinformatics,Cancer biology and Drug discovery</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Jakkula Bharath Kumar</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:jb23btr2r02@student.nitw.ac.in">jb23btr2r02@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd27_2024-9-30-12-47-39.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocess engineering, Metabolic engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Shibani Padhan</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sp24btr1r09@student.nitw.ac.in">sp24btr1r09@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd28_2024-9-30-12-48-15.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Genomic Data Analysis, Biosensors</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Diptirekha Mohanta</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:pp23btr1r06@student.nitw.ac.in">pp23btr1r06@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd29_2024-9-30-12-48-46.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Structural biology </b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Devaki Sethi</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ds24btr1r11@student.nitw.ac.in">ds24btr1r11@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd30_2024-9-30-12-49-12.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Biomechanics</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Pallabi Banerjee</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:pb24btr1r06@student.nitw.ac.in">pb24btr1r06@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd31_2024-9-30-12-49-39.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Fungal genetics</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Tippana Meena</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:tm21btrer01@student.nitw.ac.in">tm21btrer01@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd32_2024-9-30-12-50-4.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocess and Environmental Engineering, Bioplastics, Mathematical modeling of Process and Design</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Bhanu Pratap SIngh</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:bs712144@student.nitw.ac.in">bs712144@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd33_2024-9-30-12-50-28.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Tissue Engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Madhavi Latha Chinta</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:madhuchinta@student.nitw.ac.in">madhuchinta@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd34_2024-9-30-12-50-51.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cancer Biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Kothuru Rajyalaxmi</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:kr23btr2r01@student.nitw.ac.in">kr23btr2r01@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd35_2024-9-30-12-51-37.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cancer Systems Biology, Mathematical Modelling and Analysis of Biological Systems</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Sai Bhavani Gottumukkala</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:gsaib@student.nitw.ac.in">gsaib@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd36_2024-9-30-12-52-2.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Bioprocessing  engineering and biorefinery, metabolic engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Padala Shilpa</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ps24btr1r14@student.nitw.ac.in">ps24btr1r14@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd37_2024-9-30-12-52-31.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Stem cell and Tissue Engineering</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Raja Mallah</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:rm23btr1r09@student.nitw.ac.in">rm23btr1r09@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd38_2024-9-30-12-52-59.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Genetic Engineering, Cell & Molecular Biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Pisal Ketali</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:pk24btr1r12@student.nitw.ac.in">pk24btr1r12@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="CHEDeptAssets/images/noimage.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Vaccine Development</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Narayana Prasad Maladi</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ni712147@student.nitw.ac.in">ni712147@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd40_2024-9-30-12-53-30.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Cell & Molecular Biology, Gene Expression</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Pesaru Sai Laya</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:ps24btr1r03@student.nitw.ac.in">ps24btr1r03@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd41_2024-9-30-12-53-55.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Eukaryotic Translation and Regulation, Lectin-Glycan (host-virus) Interactions, and Bioinformatics</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Madasu Pavan Kumar</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:mp721093@student.nitw.ac.in">mp721093@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd42_2024-9-30-12-54-19.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Structural Biology & RNA Therapeutics</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Mallikarjunachari Uppuladinne</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:mu721091@student.nitw.ac.in">mu721091@student.nitw.ac.in</a></span> </p>
</div>
</div>
<div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
<div className="phd-std-member">
<div className="phd-std-img">
<img src="https://nitw.ac.in/api/static/files/phd43_2024-9-30-12-54-51.jpg" className="img-fluid image" alt="" />
<div className="overlay">
<div className="std-details ">
<p>
Research Area : <b>Vaccines, Therapeutics, Process Development, and Molecular Biology</b>
</p>
</div>
</div>
</div>
<h6 className="std-title">Siva Prasad Kannuri</h6>
{/* <span>Full-Time Research Scholar </span> */}
<hr />
<p className="contact-info">  
{/* 8978567535 <br />   */}
<span><a href="mailto:sp720097@student.nitw.ac.in">sp720097@student.nitw.ac.in</a></span> </p>
</div>
</div>

</div>


                                        </div>
                                    </div>
                                </div>
                                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                                    aria-labelledby="v-pills-Students-tab">
                                    
					<div className="row std-society">
                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                target="_blank"> <img src="BIODeptAssets/images/21.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/bt-ashishp"
                                                    target="_blank">
                                                    <h6>Prof. Ashish A Prabhu</h6> <span> Treasurer </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9582700174 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:ashishp@nitw.ac.in"
                                                            style={{ color: "#808080" }}>ashishp@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3 col-6">
                                            <div className="card "> <a
                                                href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                target="_blank"> <img src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" />
                                            </a>
                                                <div className="data"> <a
                                                    href="https://erp.nitw.ac.in/ext/profile/bt-simha_bt"
                                                    target="_blank">
                                                    <h6>Prof. K Narasimhulu</h6> <span> Faculty Advisor </span>
                                                    <hr />
                                                </a>
                                                    <p> <i className="fa fa-phone" aria-hidden="true"></i> 9985470286 <br /> <i
                                                        className="fa fa-envelope" aria-hidden="true"></i> <a
                                                            href="mailto:simha_bt@nitw.ac.in"
                                                            style={{ color: "#808080" }}>simha_bt@nitw.ac.in</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
					    
                                </div>
                                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                                    aria-labelledby="v-pills-NotableAlumni-tab">


					    <div className="row notable-alumni">
          
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na1_2024-9-30-12-25-19.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Krishna Das</h6>
                          <p><b>Batch:</b> B.Tech (2006-2010)<br />
                            <b>Present Position:</b> Principal Scientist
                                ViraTherapeutics GmbH (Boehringer Ingelheim since Sep 2018) Innsbruck, Tyrol, Austria
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na2_2024-9-30-12-25-46.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Ch. Sathvika</h6>
                          <p><b>Batch:</b> B.Tech (2006-2010)<br />
                            <b>Present Position:</b> Vice President Chaitanya Deemed to be University, Kishanpura, Hanamkonda, Warangal - 506 001, TS, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na3_2024-9-30-12-26-16.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Govind Lohia</h6>
                          <p><b>Batch:</b> B.Tech (2006-2010)<br />
                            <b>Present Position:</b> Co-Founder & CEO Leptyn Chennai, Tamil Nadu, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na4_2024-9-30-12-26-46.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sumona Chakraborty</h6>
                          <p><b>Batch:</b> B.Tech (2006-2010)<br />
                            <b>Present Position:</b> Vice President OrbiMed Maharastra, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na5_2024-9-30-12-27-15.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Chanukya Patnaik</h6>
                          <p><b>Batch:</b> B.Tech (2010-2014)<br />
                            <b>Present Position:</b> Founder & CEO AI Planet
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na6_2024-9-30-12-27-40.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sameep Jain</h6>
                          <p><b>Batch:</b> B.Tech (2006-2010)<br />
                            <b>Present Position:</b> CEO & MD Black Brix Bengaluru, Karnataka, India
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na7_2024-9-30-12-28-7.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Harsha Bajaj</h6>
                          <p><b>Batch:</b> B.Tech (2007-2011)<br />
                            <b>Present Position:</b> Scientist
CSIR - National Institute for Interdisciplinary Science and Technology (NIIST) Council of Scientific and Industrial Research ( CSIR ) Ministry of Science and Technology, Government of India Industrial Estate PO, Thiruvananthapuram, India

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na8_2024-9-30-12-28-33.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Isha Chowdhary</h6>
                          <p><b>Batch:</b> B.Tech (2007-2011)<br />
                            <b>Present Position:</b> Senior Global Product Manager - Clinical Diagnostics
Waters Corporation, Manchester Area, United Kingdom


                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na9_2024-9-30-12-28-55.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Smruthik rajanala</h6>
                          <p><b>Batch:</b> B.Tech<br />
                            <b>Present Position:</b> Indian Police Service at Government of India, Raipur, Chhattisgarh, India


                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na10_2024-9-30-12-29-35.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Sairaam Ganesh</h6>
                          <p><b>Batch:</b> B.Tech (2009-2013)<br />
                            <b>Present Position:</b> Associate Director, US Strategy and Planning Merck
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na11_2024-9-30-12-30-5.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Anant Kumar</h6>
                          <p><b>Batch:</b> B.Tech <br />
                            <b>Present Position:</b> Founder
Bhoomi AI Solutions Private Limited,
Samastipur, Bihar, India

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na12_2024-9-30-12-30-32.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Avula Sai Krishna IAS</h6>
                          <p><b>Batch:</b> B.Tech <br />
                            <b>Present Position:</b> Indian Administrative Service, Government of India

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/na13_2024-9-30-12-32-16.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Anamika Pandey</h6>
                          <p><b>Batch:</b> B.Tech <br />
                            <b>Present Position:</b> Founder
Naario, A Community-first Consumer brand.


                          </p>
                        </div>
                      </div>
                    </div>
                
                </div>
                                    
                                </div>

                                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                                    aria-labelledby="v-pills-AcademicResearchGroup-tab">

<div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Cell & Molecular Biology</h4>
                                            </header>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/2.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. P Sreenivasa Rao</h6>
                                                        <span>Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9703599944 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:parcha@nitw.ac.in">parcha@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/6.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Amitava Bandhu</h6>
                                                        <span>Associate Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969446 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:bandhuam@nitw.ac.in">bandhuam@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/8.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. V. Kohila</h6>
                                                        <span>Associate Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9849432598 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:v.kohila@nitw.ac.in">v.kohila@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/11.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Urmila Saxena</h6>
                                                        <span>Associate Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 8332969442 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:urmila@nitw.ac.in">urmila@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/1.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Prakash Saudagar</h6>
                                                        <span>Associate Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 0870-2452898 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ps@nitw.ac.in">ps@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/15.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rathnaprabha D</h6>
                                                        <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9494668822 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:rathnaprabhad@nitw.ac.in">rathnaprabhad@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/18.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Priya</h6>
                                                        <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9167474235 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:priyap@nitw.ac.in">priyap@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/20.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Chockalingam S</h6>
                                                        <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone"
                                                                aria-hidden="true"></i> 9502205174 <br /> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:chocks@nitw.ac.in">chocks@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Computational Biology and Bioinformatics</h4>
                                            </header>
                                        </div>
                                        
                                        
                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/5.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Onkara Perumal. P</h6> <span>Associate Professor </span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9642508262 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:popomal@nitw.ac.in">popomal@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/10.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Asim Bikas Das</h6> <span>Associate Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969440 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:asimbikas@nitw.ac.in">asimbikas@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/12.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Perugu Shyam</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9948561761 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:shyamperugu@nitw.ac.in">shyamperugu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/14.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Anbumathi P</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 08702462703 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:anbu@nitw.ac.in">anbu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/16.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Soumya Lipsa Rath</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 0870-24524205 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:slrath@nitw.ac.in">slrath@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/19.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Thyageshwar Chandran</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 7348975012 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:thyagesh@nitw.ac.in">thyagesh@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>

                                    <div className="row academic_group">
                                        <div className="col-md-12">
                                            <header>
                                                <h4>Bioprocess Engineering and Integrated Biorefineries</h4>
                                            </header>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/4.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Korrapati Narasimhulu</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9985470286 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:simha_bt@nitw.ac.in">simha_bt@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/3.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. R. Satish Babu</h6> <span>Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969439 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:satishbabu@nitw.ac.in">satishbabu@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/7.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Rama Raju. B</h6> <span>Associate Professor/HOD</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969462 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:rrb@nitw.ac.in">rrb@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/13.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. M. Jerold</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9486252374 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:jerold@nitw.ac.in">jerold@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/17.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Surajbhan Sevda</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9929565697 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:sevdasuraj@nitw.ac.in">sevdasuraj@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-6">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="BIODeptAssets/images/21.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0">Prof. Ashish A Prabhu</h6> <span>Assistant Professor</span>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i className="fa fa-phone" aria-hidden="true"></i> 9582700174 <br /> <i className="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:ashishp@nitw.ac.in">ashishp@nitw.ac.in</a> </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        



                                    </div>

					    

                                </div>


                                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                                    aria-labelledby="v-pills-ResearchScholars-tab">
                                    <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                                        target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                                            className="fa fa-link " aria-hidden="true"></i> </a>
                                    <h4 className="">Ph.D. On Roll</h4> <a
                                        href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                                            className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                                                className="fa fa-link " aria-hidden="true"></i> </a>
                                </div>


                                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                                    aria-labelledby="v-pills-PhotoGallery-tab">


<div className="row">

                                    <div className="col-md-6">

                                    <img src="https://nitw.ac.in/api/static/files/1_2024-10-3-15-41-19.jpg" alt="" className="img-fluid mb-3"/> 
                                <img src="https://nitw.ac.in/api/static/files/2_2024-10-3-15-42-4.jpg" alt="" className="img-fluid mb-3"/> 
                                <img src="https://nitw.ac.in/api/static/files/3_2024-10-3-15-42-33.jpg" alt="" className="img-fluid mb-3"/>

                                <img src="https://nitw.ac.in/api/static/files/4_2024-10-3-15-42-59.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/5_2024-10-3-15-43-20.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/6_2024-10-3-15-43-38.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/7_2024-10-3-15-43-58.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/8_2024-10-3-15-44-22.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/9_2024-10-3-15-44-41.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/10_2024-10-3-15-44-58.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/11_2024-10-3-15-45-17.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/12_2024-10-3-15-45-36.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/13_2024-10-3-15-45-59.jpg" alt="" className="img-fluid mb-3"/>

                                <img src="https://nitw.ac.in/api/static/files/14_2024-10-3-15-46-18.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/15_2024-10-3-15-46-36.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/16_2024-10-3-15-46-57.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/17_2024-10-3-15-47-15.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/18_2024-10-3-15-47-32.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/19_2024-10-3-15-47-52.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/20_2024-10-3-15-48-10.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/21_2024-10-3-15-48-31.jpg" alt="" className="img-fluid mb-3"/>

                                    </div>
                                    <div className="col-md-6">

                                    
                                <img src="https://nitw.ac.in/api/static/files/22_2024-10-3-15-48-51.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/23_2024-10-3-15-49-12.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/24_2024-10-3-15-49-30.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/25_2024-10-3-15-49-59.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/26_2024-10-3-15-50-18.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/27_2024-10-3-15-50-36.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/28_2024-10-3-15-50-54.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/29_2024-10-3-15-51-16.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/30_2024-10-3-15-51-34.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/31_2024-10-3-15-51-54.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/32_2024-10-3-15-52-16.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/33_2024-10-3-15-52-40.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/34_2024-10-3-15-53-6.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/35_2024-10-3-15-53-26.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/36_2024-10-3-15-53-44.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/37_2024-10-3-15-54-2.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/38_2024-10-3-15-54-21.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/39_2024-10-3-15-54-38.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/40_2024-10-3-15-55-2.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/41_2024-10-3-15-55-21.jpg" alt="" className="img-fluid mb-3"/>
                                <img src="https://nitw.ac.in/api/static/files/42_2024-10-3-15-55-39.jpg" alt="" className="img-fluid mb-3"/>

                                    </div>
                                    </div>  



                                    <div className="row">
                                        <div className="col-md-6">
                                        <img src="https://nitw.ac.in/api/static/files/1_2024-10-3-17-3-37.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/2_2024-10-3-17-4-1.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/3_2024-10-3-17-4-23.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/4_2024-10-3-17-4-41.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/5_2024-10-3-17-4-59.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/6_2024-10-3-17-5-15.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/7_2024-10-3-17-5-31.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/8_2024-10-3-17-5-55.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/9_2024-10-3-17-6-11.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/10_2024-10-3-17-7-6.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/11_2024-10-3-17-7-25.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/12_2024-10-3-17-7-48.jpg" alt="" className="img-fluid mb-3"/>


                                        </div>
                                        <div className="col-md-6">

                                        <img src="https://nitw.ac.in/api/static/files/13_2024-10-3-17-8-4.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/14_2024-10-3-17-8-25.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/15_2024-10-3-17-8-42.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/16_2024-10-3-17-9-4.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/17_2024-10-3-17-9-23.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/18_2024-10-3-17-9-42.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/19_2024-10-3-17-10-1.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/20_2024-10-3-17-10-23.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/21_2024-10-3-17-10-44.jpg" alt="" className="img-fluid mb-3"/>
                                        <img src="https://nitw.ac.in/api/static/files/22_2024-10-3-17-11-1.jpg" alt="" className="img-fluid mb-3"/>
                                        
                                        </div>
                                    </div>

					    

                                </div>
                                {/* <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  bg-white`} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">

                                </div> */}


                                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  bg-white`} id="v-pills-Awards" role="tabpanel"
                                    aria-labelledby="v-pills-Awards-tab">



					<div className="row">
					    
                                        <div className="col-md-6 mb-5">
                                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Anam Upadhyay,  M.Tech. 1st-year student in the Department of Biotechnology, has been selected for the Khorana Program for Scholars. During the summer of 2024, she will do her internship at Purdue University, USA, under Dr. Andrea Kasinski</h6>
                                        </div>
                                        <div className="col-md-6 mb-5">
                                            <img src="https://nitw.ac.in/api/static/files/aw1_2024-9-30-12-16-4.jpeg" alt="" className="img-fluid"/>
                                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Pro. Jerold recievd  Institutes of Engineers Award</h6>
                                        </div>
                                        <div className="col-md-6 mb-5">
                                            <img src="https://nitw.ac.in/api/static/files/aw2_2024-9-30-12-16-39.jpeg" alt="" className="img-fluid"/>
                                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. P.Sreenivasa Rao Elected as Fellow-2023 of Telanagana Academy of Scieneces</h6>
                                        </div>
                                        <div className="col-md-6 mb-5">
                                            <img src="https://nitw.ac.in/api/static/files/aw3_2024-9-30-14-44-17.jpeg" alt="" className="img-fluid"/>
                                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Rama Raju B Selected as  Associate Fellow 2015 from Tealangana Academy of Sciences</h6>
                                        </div>

                                        <div className="col-md-6 mb-5">
                                            <img src="https://nitw.ac.in/api/static/files/aw4_2024-9-30-14-45-5.jpeg" alt="" className="img-fluid"/>
                                            <h6 className="mb-0 mt-0" style={{ color: '#c20315', fontWeight: 600 }}>Prof. Rama Raju recievd Young Scientist Award 2016 from Tealangana Academy of Sciences</h6>
                                        </div>

                                    </div>


					    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
            {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                <p>Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default BIODeptPage;
